// @file School permissions settings store
import window from '@@/bits/global'
import { useSettingsSchoolPermissionsStudentsStore } from '@@/pinia/settings_school_permissions_students'
import { useSettingsSchoolPermissionsTeachersStore } from '@@/pinia/settings_school_permissions_teachers'
import type { Library } from '@@/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'

enum SchoolPermissionsSettingsTab {
  Students = 'students',
  Teachers = 'teachers',
}

const schoolPermissionsSettingsTabToHash = {
  [SchoolPermissionsSettingsTab.Students]: '#students',
  [SchoolPermissionsSettingsTab.Teachers]: '#teachers',
}
const hashToSchoolPermissionsSettingsTab = Object.fromEntries(
  Object.entries(schoolPermissionsSettingsTabToHash).map(([k, v]) => [v, k as SchoolPermissionsSettingsTab]),
)

const getSchoolPermissionsSettingsTabFromHash = (): SchoolPermissionsSettingsTab => {
  return hashToSchoolPermissionsSettingsTab[window.location.hash] ?? SchoolPermissionsSettingsTab.Students
}

export const useSettingsSchoolPermissionsStore = defineStore('settingsSchoolPermissions', () => {
  const settingsSchoolPermissionsStudentsStore = useSettingsSchoolPermissionsStudentsStore()
  const settingsSchoolPermissionsTeachersStore = useSettingsSchoolPermissionsTeachersStore()

  // State
  const currentTab = ref<SchoolPermissionsSettingsTab>(getSchoolPermissionsSettingsTabFromHash())
  const visitedTabs = ref<Record<SchoolPermissionsSettingsTab, boolean>>({
    [SchoolPermissionsSettingsTab.Students]: false,
    [SchoolPermissionsSettingsTab.Teachers]: false,
  })
  const library = ref<Library>()

  // Getters

  // Actions
  function initialize(libraryObject?: Library): void {
    visitedTabs.value = {
      [SchoolPermissionsSettingsTab.Students]: false,
      [SchoolPermissionsSettingsTab.Teachers]: false,
    }
    library.value = libraryObject
    initializeTab()
  }

  function initializeTab(): void {
    visitedTabs.value[currentTab.value] = true
    switch (currentTab.value) {
      case SchoolPermissionsSettingsTab.Students:
        void settingsSchoolPermissionsStudentsStore.initialize(library.value)
        break
      default:
        void settingsSchoolPermissionsTeachersStore.initialize(library.value)
    }
  }

  function setSchoolPermissionsSettingsTabFromHash(): void {
    currentTab.value = getSchoolPermissionsSettingsTabFromHash()
    const isCurrentTabInitialized = !visitedTabs.value[currentTab.value]

    if (isCurrentTabInitialized) {
      initializeTab()
    }
  }

  return {
    // State
    currentTab,
    visitedTabs,

    // Actions
    initialize,
    setSchoolPermissionsSettingsTabFromHash,
  }
})

export { SchoolPermissionsSettingsTab, schoolPermissionsSettingsTabToHash }
