// @file Dashboard settings store
// It wraps the dashboard settings Vuex store into a Pinia store.
import {
  isPaidBilling,
  isPaidLibraryBilling,
  normalizedOppositePaymentSchedule,
  normalizedPaymentSchedule,
} from '@@/bits/billing_helper'
import { translatePrice } from '@@/bits/intl'
import { getVuexStore } from '@@/bits/pinia'
import { LibraryMembershipTier, LibrarySubscriptionStatus, LibraryType } from '@@/enums'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { useLibraryPlansStore } from '@@/pinia/library_plans'
import { usePersonalPlansStore } from '@@/pinia/personal_plans_store'
import { useSettingsNavigationStore } from '@@/pinia/settings_navigation'
import type {
  Billing,
  EstimateCancellationRefundResult,
  EstimateSwitchingCostResult,
  EstimateTeamNeonDowngradeRefund,
  Library,
  LibraryBilling,
  LibraryId,
  LibraryInvoice,
  LibraryUserQuotaInfo,
  Plan,
  Tenant,
  User,
  UserOptions,
} from '@@/types'
import type { DashboardSettingsState } from '@@/vuexstore/dashboard_settings'
import type { Invoice } from '@padlet/arvo'
import { sortBy } from 'es-toolkit'
import { defineStore } from 'pinia'
import type { ComputedRef } from 'vue'
import { computed, ref } from 'vue'

export enum ScheduledChange {
  SwitchToAnnual = 'SwitchToAnnual',
  SwitchToMonthly = 'SwitchToMonthly',
  DowngradeToNeon = 'DowngradeToNeon',
  DowngradeToSilver = 'DowngradeToSilver',
  DowngradeToGold = 'DowngradeToGold',
}

export enum LibraryScheduledChange {
  SwitchToAnnual = 'SwitchToAnnual',
  SwitchToMonthly = 'SwitchToMonthly',
  DowngradeToTeamNeon = 'DowngradeToTeamNeon',
  ChangePlanQuantity = 'ChangePlanQuantity',
}

export enum GenericStep {
  AboveQuota = 'AboveQuota',
  ChooseDate = 'ChooseDate',
  Now = 'Now',
  NowDifferentCurrency = 'NowDifferentCurrency',
  ConfirmNow = 'ConfirmNow',
  Later = 'Later',
  ConfirmLater = 'ConfirmLater',
  // Gold
  ChooseDateGold = 'ChooseDateGold',
  NowGold = 'NowGold',
  NowGoldDifferentCurrency = 'NowGoldDifferentCurrency',
  LaterGold = 'LaterGold',
  ConfirmNowGold = 'ConfirmNowGold',
  ConfirmNowGoldDifferentCurrency = 'ConfirmNowGoldDifferentCurrency',
  ConfirmLaterGold = 'ConfirmLaterGold',
  // Silver
  ChooseDateSilver = 'ChooseDateSilver',
  NowSilver = 'NowSilver',
  NowSilverDifferentCurrency = 'NowSilverDifferentCurrency',
  LaterSilver = 'LaterSilver',
  ConfirmNowSilver = 'ConfirmNowSilver',
  ConfirmNowSilverDifferentCurrency = 'ConfirmNowSilverDifferentCurrency',
  ConfirmLaterSilver = 'ConfirmLaterSilver',
}

export type DowngradeStep = GenericStep

export type CrossgradeStep = Exclude<GenericStep, typeof GenericStep.AboveQuota>

export enum LibraryGenericStep {
  ChooseDate = 'ChooseDate',
  Now = 'Now',
  ConfirmNow = 'ConfirmNow',
  Later = 'Later',
  ConfirmLater = 'ConfirmLater',
}

export type LibraryDowngradeStep = LibraryGenericStep

export const useDashboardSettingsStore = defineStore('dashboardSettings', () => {
  const settingsNavigationStore = useSettingsNavigationStore()

  // State
  const isOnline = ref<boolean>(true)

  // Getters
  const isBriefcase = computed<boolean>(() => tenant.value.type === 'business')
  const isSchool = computed<boolean>(() => tenant.value.type === 'school')
  const user = computed((): User => getVuexStore<DashboardSettingsState>()?.getters.user ?? { id: null })
  const userId = computed((): number => user.value.id)
  const userOptions = computed((): UserOptions => getVuexStore<DashboardSettingsState>()?.state.userOptions ?? {})
  const tenant = computed((): Tenant => getVuexStore<DashboardSettingsState>()?.getters.tenant ?? { id: null })

  // Actions
  function changeConnectivity(value: boolean): void {
    isOnline.value = value
  }

  function goBack(): void {
    settingsNavigationStore.goBackToReferrerOrDashboard()
  }

  function startOffboarding(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('startOffboarding', { root: true })
  }

  function toggleDarkMode(isDarkMode: boolean): void {
    useDarkModeStore().toggleDarkMode(isDarkMode)
  }

  function updateCurrentTenant(payload: Partial<Tenant>): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('updateCurrentTenant', payload, { root: true })
  }

  function updateUserOptions(payload: Partial<UserOptions>): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('updateUserOptions', payload, { root: true })
  }

  function verifyIdentity(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('verifyIdentity', { root: true })
  }

  // Personal Billing
  const { billing, invoices, isBillingInitialized, isCancellingSoon, isNeonTier } = usePersonalBilling()

  // Library billing
  const {
    // State

    // Getters
    currentLibraryScheduledChange,
    estimatedTeamNeonDowngradeRefundAmount,
    hasLibraryScheduledChanges,
    hasPendingMakerCountUpdate,
    isLibraryClassroomGoldTier,
    isLibraryOnV2ClassroomPlan,
    isLibraryPaying,
    isLibraryTeamGoldTier,
    isLibraryTeamNeonTier,
    isLibraryWebPlan,
    isLoadingLibraryBillingInformation,
    isLoadingLibraryChangeCardUrl,
    isLoadingLibraryInvoices,
    isLoadingTeamNeonDowngradeInformation,
    isOfferedTeamCurrencyEqualToCurrentCurrency,
    isProcessingLibraryDowngrade,
    libraryBilling,
    libraryBillingError,
    libraryDowngradeError,
    libraryDowngradeStep,
    libraryInvoices,
    libraryInvoicesError,
    librarySubscriptionStatus,
    libraryUsersBilledCount,
    xClassroomLibraryDowngradeModal,
    xLibraryDowngradeModal,
    isLibraryGoldLegacyTier,
    currentOfferedLibraryPlan,
    classroomPlanCurrentTierPrice,

    // Actions
    changeStepLibraryDowngradeModal,
    closeClassroomLibraryDowngradeModal,
    closeLibraryDowngradeModal,
    downgradeLibraryToTeamNeonImmediately,
    loadAndRedirectToLibraryChangeCardUrl,
    scheduleLibraryDowngradeToTeamNeon,
    showLibraryDowngradeToTeamNeonModalCheckingQuota,
    showUndoLibraryScheduledChangeModal,
  } = useLibraryBilling()

  // Library
  const {
    // State

    // Getters
    canInviteMembers,
    canInviteSchoolAdmins,
    canInviteSchoolStudents,
    canInviteSchoolTeachers,
    canManageMembers,
    canUpdateLibraryInfo,
    canSetupLti,
    currentLibrary,
    isClassroomLibrary,
    isCurrentUserAtLeastLibraryTeacher,
    isCurrentUserLibraryAdmin,
    isCurrentUserLibraryOwner,
    isSchoolLibrary,
    isTeamLibrary,
    libraries,
    libraryId,
    libraryMakersCount,
    libraryName,
    librarySlug,
    libraryUserQuota,
    libraryWallsUsed,
    ownedLibraries,
    trialDaysLeft,

    // Actions
    fetchViewableLibraries,
  } = useLibrary()

  const {
    // State
    crossgradeError,
    crossgradeStep,
    currentScheduledChange,
    currentScheduleGoldPlan,
    currentScheduleSilverPlan,
    displayedEstimatedCrossgradeCost,
    displayedEstimatedGoldDowngradeCost,
    displayedEstimatedSilverDowngradeCost,
    downgradeError,
    downgradeStep,
    estimatedCancellationRefundAmount,
    estimatedCrossgradeCost,
    estimatedGoldDowngradeCost,
    oppositeSchedulePlan,
    hasScheduledChanges,
    isGoldLegacyTier,
    isLoadingCancellationRefundInformation,
    isLoadingCrossgradeInformation,
    isLoadingGoldDowngradeInformation,
    isLoadingSilverDowngradeInformation,
    isOfferedPlanCurrencyEqualToUserPlanCurrency,
    isPlatinumLegacyTier,
    isProcessingCrossgrade,
    isProcessingDowngrade,
    isWebPlan,
    xCrossgradeModal,
    xDowngradeModal,
    // Actions
    cancelImmediately,
    closePlanCrossgradeModal,
    closePlanDowngradeModal,
    doNotRenew,
    downgradePlatinumToGoldDifferentCurrencyImmediately,
    downgradePlatinumToGoldImmediately,
    schedulePlatinumToGoldDowngrade,
    downgradeToSilverDifferentCurrencyImmediately,
    downgradeToSilverImmediately,
    scheduleToSilverDowngrade,
    scheduleSwitch,
    showPlanCrossgradeModal,
    showPlanCrossgradeModalCheckingCurrency,
    showPlanDowngradeModal,
    showPlanDowngradeToGoldModalCheckingQuota,
    showPlanDowngradeToSilverModalCheckingQuota,
    showPlanDowngradeToNeonModalCheckingQuota,
    showScheduledChangeModal,
    switchDifferentCurrencyImmediately,
    switchImmediately,
  } = usePersonalPlans(billing)

  return {
    // State

    // Getters
    billing,
    canInviteMembers,
    canInviteSchoolAdmins,
    canInviteSchoolStudents,
    canInviteSchoolTeachers,
    canManageMembers,
    canUpdateLibraryInfo,
    canSetupLti,
    crossgradeError,
    crossgradeStep,
    currentLibrary,
    currentLibraryScheduledChange,
    currentScheduledChange,
    currentScheduleGoldPlan,
    currentScheduleSilverPlan,
    displayedEstimatedCrossgradeCost,
    displayedEstimatedGoldDowngradeCost,
    displayedEstimatedSilverDowngradeCost,
    downgradeError,
    downgradeStep,
    estimatedCancellationRefundAmount,
    estimatedCrossgradeCost,
    estimatedGoldDowngradeCost,
    estimatedTeamNeonDowngradeRefundAmount,
    hasLibraryScheduledChanges,
    hasPendingMakerCountUpdate,
    hasScheduledChanges,
    invoices,
    isBillingInitialized,
    isBriefcase,
    isCancellingSoon,
    isClassroomLibrary,
    isCurrentUserAtLeastLibraryTeacher,
    isCurrentUserLibraryAdmin,
    isCurrentUserLibraryOwner,
    isGoldLegacyTier,
    isLibraryClassroomGoldTier,
    isLibraryOnV2ClassroomPlan,
    isLibraryPaying,
    isLibraryTeamGoldTier,
    isLibraryTeamNeonTier,
    isLibraryWebPlan,
    isLoadingCancellationRefundInformation,
    isLoadingCrossgradeInformation,
    isLoadingLibraryBillingInformation,
    isLoadingLibraryChangeCardUrl,
    isLoadingLibraryInvoices,
    isLoadingTeamNeonDowngradeInformation,
    isLoadingGoldDowngradeInformation,
    isLoadingSilverDowngradeInformation,
    isNeonTier,
    isOfferedPlanCurrencyEqualToUserPlanCurrency,
    isOfferedTeamCurrencyEqualToCurrentCurrency,
    isOnline,
    isPlatinumLegacyTier,
    isProcessingCrossgrade,
    isProcessingDowngrade,
    isProcessingLibraryDowngrade,
    isSchool,
    isSchoolLibrary,
    isTeamLibrary,
    isWebPlan,
    libraries,
    libraryBilling,
    libraryBillingError,
    libraryDowngradeError,
    libraryDowngradeStep,
    libraryId,
    libraryInvoices,
    libraryInvoicesError,
    libraryMakersCount,
    libraryName,
    librarySlug,
    librarySubscriptionStatus,
    libraryUserQuota,
    libraryUsersBilledCount,
    libraryWallsUsed,
    oppositeSchedulePlan,
    ownedLibraries,
    tenant,
    trialDaysLeft,
    user,
    userId,
    userOptions,
    xClassroomLibraryDowngradeModal,
    xCrossgradeModal,
    xDowngradeModal,
    xLibraryDowngradeModal,
    isLibraryGoldLegacyTier,
    currentOfferedLibraryPlan,
    classroomPlanCurrentTierPrice,

    // Actions
    cancelImmediately,
    changeConnectivity,
    changeStepLibraryDowngradeModal,
    closeClassroomLibraryDowngradeModal,
    closeLibraryDowngradeModal,
    closePlanCrossgradeModal,
    closePlanDowngradeModal,
    doNotRenew,
    downgradeLibraryToTeamNeonImmediately,
    downgradePlatinumToGoldDifferentCurrencyImmediately,
    downgradePlatinumToGoldImmediately,
    downgradeToSilverDifferentCurrencyImmediately,
    downgradeToSilverImmediately,
    fetchViewableLibraries,
    goBack,
    loadAndRedirectToLibraryChangeCardUrl,
    scheduleLibraryDowngradeToTeamNeon,
    startOffboarding,
    toggleDarkMode,
    updateCurrentTenant,
    updateUserOptions,
    verifyIdentity,
    schedulePlatinumToGoldDowngrade,
    scheduleToSilverDowngrade,
    scheduleSwitch,
    showLibraryDowngradeToTeamNeonModalCheckingQuota,
    showPlanCrossgradeModal,
    showPlanCrossgradeModalCheckingCurrency,
    showPlanDowngradeModal,
    showPlanDowngradeToGoldModalCheckingQuota,
    showPlanDowngradeToSilverModalCheckingQuota,
    showPlanDowngradeToNeonModalCheckingQuota,
    showScheduledChangeModal,
    showUndoLibraryScheduledChangeModal,
    switchDifferentCurrencyImmediately,
    switchImmediately,
  }
})

interface LibraryComposable {
  canInviteMembers: ComputedRef<boolean>
  canInviteSchoolAdmins: ComputedRef<boolean>
  canInviteSchoolStudents: ComputedRef<boolean>
  canInviteSchoolTeachers: ComputedRef<boolean>
  canManageMembers: ComputedRef<boolean>
  canUpdateLibraryInfo: ComputedRef<boolean>
  canSetupLti: ComputedRef<boolean>
  currentLibrary: ComputedRef<Library | null>
  isClassroomLibrary: ComputedRef<boolean>
  isCurrentUserAtLeastLibraryTeacher: ComputedRef<boolean>
  isCurrentUserLibraryAdmin: ComputedRef<boolean>
  isCurrentUserLibraryOwner: ComputedRef<boolean>
  isSchoolLibrary: ComputedRef<boolean>
  isTeamLibrary: ComputedRef<boolean>
  libraries: ComputedRef<Library[]>
  libraryId: ComputedRef<LibraryId | null>
  libraryMakersCount: ComputedRef<number>
  libraryName: ComputedRef<string>
  librarySlug: ComputedRef<string>
  libraryUserQuota: ComputedRef<LibraryUserQuotaInfo | undefined>
  libraryWallsUsed: ComputedRef<number>
  ownedLibraries: ComputedRef<Library[]>
  trialDaysLeft: ComputedRef<number>

  fetchViewableLibraries: () => void
}

const useLibrary = (): LibraryComposable => {
  // State

  // Getters
  const canInviteMembers = computed<boolean>(() => {
    // School libraries created via Skolon cannot invite people via the dashboard.
    // They have to be provisioned via Skolon SSO.
    if (currentLibrary.value?.skolonLinked === true) return false
    return currentLibrary.value?.libraryAccess?.canInviteMembers === true
  })
  const canInviteSchoolAdmins = computed<boolean>(
    () => currentLibrary.value?.libraryAccess?.canInviteSchoolAdmins ?? false,
  )
  const canInviteSchoolStudents = computed<boolean>(
    () => currentLibrary.value?.libraryAccess?.canInviteSchoolStudents ?? false,
  )
  const canInviteSchoolTeachers = computed<boolean>(
    () => currentLibrary.value?.libraryAccess?.canInviteSchoolTeachers ?? false,
  )
  const canManageMembers = computed<boolean>(() => currentLibrary.value?.libraryAccess?.canManageMembers ?? false)
  const canUpdateLibraryInfo = computed<boolean>(
    () => currentLibrary.value?.libraryAccess?.canUpdateLibraryInfo ?? false,
  )
  const canSetupLti = computed<boolean>(() => currentLibrary.value?.libraryAccess?.canSetupLti ?? false)
  const currentLibrary = computed<Library | null>(() => {
    const library = libraries.value.find((library: Library) => library.id === libraryId.value)
    return library != null ? library : null
  })
  const isClassroomLibrary = computed<boolean>(() => currentLibrary.value?.libraryType === LibraryType.Classroom)
  const isCurrentUserAtLeastLibraryTeacher = computed<boolean>(
    () => currentLibrary.value?.libraryAccess?.canUseLibraryAsTeacher ?? false,
  )
  const isCurrentUserLibraryAdmin = computed<boolean>(
    () => currentLibrary.value?.libraryAccess?.canUseLibraryAsAdmin ?? false,
  )
  const isCurrentUserLibraryOwner = computed<boolean>(
    () => currentLibrary.value?.libraryAccess?.canUseLibraryAsOwner ?? false,
  )
  const isSchoolLibrary = computed<boolean>(() => currentLibrary.value?.libraryType === LibraryType.School)
  const isTeamLibrary = computed<boolean>(() => currentLibrary.value?.libraryType === LibraryType.Team)
  const libraries = computed<Library[]>(() =>
    sortBy(getVuexStore<DashboardSettingsState>()?.state.libraries ?? [], ['joinedAt']),
  )
  const libraryId = computed<LibraryId | null>(() => useSettingsNavigationStore().currentLibraryId)
  const libraryMakersCount = computed<number>(() => currentLibrary.value?.makersCount ?? 0)
  const libraryName = computed<string>(() => currentLibrary.value?.name ?? '')
  const librarySlug = computed<string>(() => currentLibrary.value?.slug ?? '')
  const libraryUserQuota = computed<LibraryUserQuotaInfo | undefined>(() => currentLibrary.value?.userQuota)
  const libraryWallsUsed = computed<number>(() => currentLibrary.value?.quota.wallsUsed ?? 0)
  const ownedLibraries = computed<Library[]>(() =>
    libraries.value.filter((library: Library) => library.libraryAccess?.canUseLibraryAsOwner === true),
  )
  const trialDaysLeft = computed<number>(() => currentLibrary.value?.trialDaysLeft ?? 0)

  // Actions
  function fetchViewableLibraries(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('fetchViewableLibraries', null, { root: true })
  }

  return {
    // State

    // Getters
    canInviteMembers,
    canInviteSchoolAdmins,
    canInviteSchoolStudents,
    canInviteSchoolTeachers,
    canManageMembers,
    canUpdateLibraryInfo,
    canSetupLti,
    currentLibrary,
    isClassroomLibrary,
    isCurrentUserAtLeastLibraryTeacher,
    isCurrentUserLibraryAdmin,
    isCurrentUserLibraryOwner,
    isSchoolLibrary,
    isTeamLibrary,
    libraries,
    libraryId,
    libraryMakersCount,
    libraryName,
    librarySlug,
    libraryUserQuota,
    libraryWallsUsed,
    ownedLibraries,
    trialDaysLeft,

    // Actions
    fetchViewableLibraries,
  }
}

interface LibraryBillingComposable {
  currentLibraryScheduledChange: ComputedRef<LibraryScheduledChange | null>
  estimatedTeamNeonDowngradeRefundAmount: ComputedRef<string | null>
  hasLibraryScheduledChanges: ComputedRef<boolean>
  hasPendingMakerCountUpdate: ComputedRef<boolean>
  isLibraryClassroomGoldTier: ComputedRef<boolean>
  isLibraryOnV2ClassroomPlan: ComputedRef<boolean>
  isLibraryPaying: ComputedRef<boolean>
  isLibraryTeamGoldTier: ComputedRef<boolean>
  isLibraryTeamNeonTier: ComputedRef<boolean>
  isLibraryWebPlan: ComputedRef<boolean>
  isLoadingLibraryBillingInformation: ComputedRef<boolean>
  isLoadingLibraryChangeCardUrl: ComputedRef<boolean>
  isLoadingLibraryInvoices: ComputedRef<boolean>
  isLoadingTeamNeonDowngradeInformation: ComputedRef<boolean>
  isOfferedTeamCurrencyEqualToCurrentCurrency: ComputedRef<boolean>
  isProcessingLibraryDowngrade: ComputedRef<boolean>
  libraryBilling: ComputedRef<LibraryBilling | null>
  libraryBillingError: ComputedRef<string>
  libraryDowngradeError: ComputedRef<string>
  libraryDowngradeStep: ComputedRef<LibraryDowngradeStep | null>
  libraryInvoices: ComputedRef<LibraryInvoice[]>
  libraryInvoicesError: ComputedRef<string>
  librarySubscriptionStatus: ComputedRef<LibrarySubscriptionStatus | null>
  libraryUsersBilledCount: ComputedRef<number>
  xClassroomLibraryDowngradeModal: ComputedRef<boolean>
  xLibraryDowngradeModal: ComputedRef<boolean>
  isLibraryGoldLegacyTier: ComputedRef<boolean>
  currentOfferedLibraryPlan: ComputedRef<Partial<Plan> | null>
  classroomPlanCurrentTierPrice: ComputedRef<string>

  changeStepLibraryDowngradeModal: ({ libraryDowngradeStep }: { libraryDowngradeStep: LibraryDowngradeStep }) => void
  closeClassroomLibraryDowngradeModal: () => void
  closeLibraryDowngradeModal: () => void
  downgradeLibraryToTeamNeonImmediately: () => void
  loadAndRedirectToLibraryChangeCardUrl: () => Promise<void>
  scheduleLibraryDowngradeToTeamNeon: () => void
  showLibraryDowngradeToTeamNeonModalCheckingQuota: ({
    tierQuota,
    wallsUsed,
  }: {
    tierQuota: number
    wallsUsed: number
  }) => void
  showUndoLibraryScheduledChangeModal: () => void
}

const useLibraryBilling = (): LibraryBillingComposable => {
  const libraryPlansStore = useLibraryPlansStore()
  const { currentLibrary, isClassroomLibrary, isTeamLibrary, libraryMakersCount } = useLibrary()

  // State

  // Getters
  const currentLibraryScheduledChange = computed((): LibraryScheduledChange | null => {
    if (!isPaidLibraryBilling(libraryBilling.value)) return null
    if (libraryBilling.value?.status?.is_cancelling_soon) return LibraryScheduledChange.DowngradeToTeamNeon
    if (libraryBilling.value?.switch_plan_status?.is_switching_soon) {
      return libraryBilling.value.period === 'year'
        ? LibraryScheduledChange.SwitchToMonthly
        : LibraryScheduledChange.SwitchToAnnual
    }
    if (libraryBilling.value?.switch_plan_quantity_status?.is_switching_soon) {
      return LibraryScheduledChange.ChangePlanQuantity
    }
    return null
  })
  const estimatedTeamNeonDowngradeRefund = computed<EstimateTeamNeonDowngradeRefund | null>(
    () => getVuexStore<DashboardSettingsState>()?.state.estimatedTeamNeonDowngradeRefund ?? null,
  )
  const estimatedTeamNeonDowngradeRefundAmount = computed((): string | null => {
    if (estimatedTeamNeonDowngradeRefund.value == null) return null

    return translatePrice(
      parseFloat(estimatedTeamNeonDowngradeRefund.value.amount),
      estimatedTeamNeonDowngradeRefund.value.currency,
    )
  })
  const hasLibraryScheduledChanges = computed((): boolean => currentLibraryScheduledChange.value != null)
  const hasPendingMakerCountUpdate = computed(
    (): boolean => libraryUsersBilledCount.value !== 0 && libraryUsersBilledCount.value !== libraryMakersCount.value,
  )
  const isLibraryClassroomGoldTier = computed(
    (): boolean => isClassroomLibrary.value && libraryBilling.value?.tier === LibraryMembershipTier.Gold,
  )
  const isLibraryOnV2ClassroomPlan = computed((): boolean => {
    if (!isClassroomLibrary.value) return false
    if (!isPaidLibraryBilling(libraryBilling.value)) return false
    return libraryBilling.value?.plan.plan_id?.includes('v2') ?? false
  })
  const isLibraryPaying = computed((): boolean => isLibraryTeamGoldTier.value || isLibraryClassroomGoldTier.value)
  const isLibraryTeamGoldTier = computed(
    (): boolean => isTeamLibrary.value && libraryBilling.value?.tier === LibraryMembershipTier.Gold,
  )
  const isLibraryTeamNeonTier = computed(
    (): boolean => isTeamLibrary.value && libraryBilling.value?.tier === LibraryMembershipTier.Neon,
  )
  const isLibraryWebPlan = computed(
    (): boolean => isPaidLibraryBilling(libraryBilling.value) && libraryBilling.value.payment_channel === 'chargebee',
  )
  const isLoadingLibraryBillingInformation = computed(
    (): boolean => getVuexStore<DashboardSettingsState>()?.state.isLoadingLibraryBillingInformation ?? false,
  )
  const isLoadingLibraryChangeCardUrl = computed(
    (): boolean => getVuexStore<DashboardSettingsState>()?.state.isLoadingLibraryChangeCardUrl ?? false,
  )
  const isLoadingLibraryInvoices = computed(
    (): boolean => getVuexStore<DashboardSettingsState>()?.state.isLoadingLibraryInvoices ?? false,
  )
  const isLoadingTeamNeonDowngradeInformation = computed(
    (): boolean => getVuexStore<DashboardSettingsState>()?.state.isLoadingTeamNeonDowngradeInformation ?? false,
  )
  const isOfferedTeamCurrencyEqualToCurrentCurrency = computed((): boolean => {
    if (!isLibraryPaying.value || !isPaidLibraryBilling(libraryBilling.value)) return true

    return (
      libraryPlansStore.plans.gold.annual.currency?.toLowerCase() ===
      libraryBilling.value?.plan?.currency?.toLowerCase()
    )
  })
  const isProcessingLibraryDowngrade = computed(
    (): boolean => getVuexStore<DashboardSettingsState>()?.state.isProcessingLibraryDowngrade ?? false,
  )
  const libraryBilling = computed(
    (): LibraryBilling | null => getVuexStore<DashboardSettingsState>()?.state.libraryBilling ?? null,
  )
  const libraryBillingError = computed(
    (): string => getVuexStore<DashboardSettingsState>()?.state.libraryBillingError ?? '',
  )
  const libraryDowngradeError = computed(
    (): string => getVuexStore<DashboardSettingsState>()?.state.libraryDowngradeError ?? '',
  )
  const libraryDowngradeStep = computed(
    (): LibraryDowngradeStep | null => getVuexStore<DashboardSettingsState>()?.state.libraryDowngradeStep ?? null,
  )
  const libraryInvoices = computed(
    (): LibraryInvoice[] => getVuexStore<DashboardSettingsState>()?.state.libraryInvoices ?? [],
  )
  const libraryInvoicesError = computed(
    (): string => getVuexStore<DashboardSettingsState>()?.state.libraryInvoicesError ?? '',
  )
  const librarySubscriptionStatus = computed((): LibrarySubscriptionStatus | null => {
    if (currentLibrary.value == null) return null
    if (currentLibrary.value.isDeprecatedFreeTier) return null
    if (currentLibrary.value.membershipTier === LibraryMembershipTier.Trial) return LibrarySubscriptionStatus.OnTrial
    if (currentLibrary.value.isTrialExpired === true) return LibrarySubscriptionStatus.TrialExpired
    if (currentLibrary.value.isPlanCancelled) return LibrarySubscriptionStatus.Cancelled
    if (currentLibrary.value.membershipTier === LibraryMembershipTier.Gold) return LibrarySubscriptionStatus.Paying
    return null
  })
  const libraryUsersBilledCount = computed((): number => {
    return isPaidLibraryBilling(libraryBilling.value) ? libraryBilling.value.users_billed_count : 0
  })
  const classroomPlanCurrentTierPrice = computed((): string => {
    // Current classroom plans are stairstep. Multiplying the price on
    // the frontend will have small rounding errors. Instead we use the plans
    // price tiers from ChargeBee.
    if (!isPaidLibraryBilling(libraryBilling.value) || libraryBilling.value.price_tiers.length === 0)
      return libraryPlansStore.plans.gold.annual.formattedPrice

    const currentTier = libraryBilling.value.users_billed_count

    // If users_billed_count exceeds the highest tier, return the last tier's price
    const priceTiers = libraryBilling.value.price_tiers
    const lastTier = priceTiers[priceTiers.length - 1]

    if (currentTier >= lastTier.starting_unit) {
      return lastTier.formatted_price_with_currency
    }

    const currentPlanTier = priceTiers.find((tier) => {
      return currentTier >= tier.starting_unit && currentTier <= tier.ending_unit
    })

    return currentPlanTier?.formatted_price_with_currency ?? ''
  })
  const xClassroomLibraryDowngradeModal = computed(
    (): boolean => getVuexStore<DashboardSettingsState>()?.state.xClassroomLibraryDowngradeModal ?? false,
  )
  const xLibraryDowngradeModal = computed(
    (): boolean => getVuexStore<DashboardSettingsState>()?.state.xLibraryDowngradeModal ?? false,
  )
  const currentOfferedLibraryPlan = computed<Partial<Plan> | null>(() => {
    if (!isPaidLibraryBilling(libraryBilling.value)) return null
    return libraryPlansStore.plans[libraryBilling.value.tier][normalizedPaymentSchedule(libraryBilling.value.period)]
  })
  const isLibraryOnNewPlan = computed<boolean>(() => {
    if (currentOfferedLibraryPlan.value == null || !isPaidLibraryBilling(libraryBilling.value)) return false
    // A school library might not have a plan_id. Their plan tier is gold by default.
    if (currentLibrary.value?.libraryType === LibraryType.School && libraryBilling.value.plan?.plan_id == null)
      return false
    return currentOfferedLibraryPlan.value.id === libraryBilling.value.plan?.plan_id
  })
  const isLibraryGoldLegacyTier = computed<boolean>(
    () => libraryBilling.value?.tier === 'gold' && !isLibraryOnNewPlan.value && isLibraryWebPlan.value,
  )

  // Actions
  const changeStepLibraryDowngradeModal = (payload: { libraryDowngradeStep: LibraryDowngradeStep }): void => {
    void getVuexStore<DashboardSettingsState>()?.dispatch('changeStepLibraryDowngradeModal', payload, { root: true })
  }
  const closeClassroomLibraryDowngradeModal = (): void => {
    void getVuexStore<DashboardSettingsState>()?.dispatch('closeClassroomLibraryDowngradeModal', null, { root: true })
  }
  const closeLibraryDowngradeModal = (): void => {
    void getVuexStore<DashboardSettingsState>()?.dispatch('closeLibraryDowngradeModal', null, { root: true })
  }
  const downgradeLibraryToTeamNeonImmediately = (): void => {
    void getVuexStore<DashboardSettingsState>()?.dispatch('downgradeLibraryToTeamNeonImmediately', null, { root: true })
  }
  const loadAndRedirectToLibraryChangeCardUrl = async (): Promise<void> => {
    void getVuexStore<DashboardSettingsState>()?.dispatch('loadAndRedirectToLibraryChangeCardUrl', null, { root: true })
  }
  const scheduleLibraryDowngradeToTeamNeon = (): void => {
    void getVuexStore<DashboardSettingsState>()?.dispatch('scheduleLibraryDowngradeToTeamNeon', null, { root: true })
  }
  const showLibraryDowngradeToTeamNeonModalCheckingQuota = (payload: {
    tierQuota: number
    wallsUsed: number
  }): void => {
    void getVuexStore<DashboardSettingsState>()?.dispatch('showLibraryDowngradeToTeamNeonModalCheckingQuota', payload, {
      root: true,
    })
  }
  const showUndoLibraryScheduledChangeModal = (): void => {
    void getVuexStore<DashboardSettingsState>()?.dispatch('showUndoLibraryScheduledChangeModal', null, { root: true })
  }

  return {
    // State

    // Getters
    currentLibraryScheduledChange,
    estimatedTeamNeonDowngradeRefundAmount,
    hasLibraryScheduledChanges,
    hasPendingMakerCountUpdate,
    isLibraryClassroomGoldTier,
    isLibraryOnV2ClassroomPlan,
    isLibraryPaying,
    isLibraryTeamGoldTier,
    isLibraryTeamNeonTier,
    isLibraryWebPlan,
    isLoadingLibraryBillingInformation,
    isLoadingLibraryChangeCardUrl,
    isLoadingLibraryInvoices,
    isLoadingTeamNeonDowngradeInformation,
    isOfferedTeamCurrencyEqualToCurrentCurrency,
    isProcessingLibraryDowngrade,
    libraryBilling,
    libraryBillingError,
    libraryDowngradeError,
    libraryDowngradeStep,
    libraryInvoices,
    libraryInvoicesError,
    librarySubscriptionStatus,
    libraryUsersBilledCount,
    xClassroomLibraryDowngradeModal,
    xLibraryDowngradeModal,
    isLibraryGoldLegacyTier,
    currentOfferedLibraryPlan,
    classroomPlanCurrentTierPrice,

    // Actions
    changeStepLibraryDowngradeModal,
    closeClassroomLibraryDowngradeModal,
    closeLibraryDowngradeModal,
    downgradeLibraryToTeamNeonImmediately,
    loadAndRedirectToLibraryChangeCardUrl,
    scheduleLibraryDowngradeToTeamNeon,
    showLibraryDowngradeToTeamNeonModalCheckingQuota,
    showUndoLibraryScheduledChangeModal,
  }
}

export type BillingState = Billing | null

interface PersonalBillingComposable {
  billing: ComputedRef<BillingState>
  invoices: ComputedRef<Invoice[]>
  isBillingInitialized: ComputedRef<boolean>
  isCancellingSoon: ComputedRef<boolean>
  isNeonTier: ComputedRef<boolean>
}

const usePersonalBilling = (): PersonalBillingComposable => {
  const billing = computed<BillingState>(() => getVuexStore<DashboardSettingsState>()?.getters.billing)

  const invoices = computed<Invoice[]>(() => getVuexStore<DashboardSettingsState>()?.state.invoices ?? [])

  const isBillingInitialized = computed<boolean>(() => billing.value != null)
  const isCancellingSoon = computed<boolean>(() => {
    if (!isPaidBilling(billing.value)) return false

    return billing.value.status.is_cancelling_soon
  })
  const isNeonTier = computed<boolean>(() => !isPaidBilling(billing.value))

  return {
    billing,
    invoices,
    isBillingInitialized,
    isCancellingSoon,
    isNeonTier,
  }
}

interface PersonalPlansComposable {
  crossgradeError: ComputedRef<string>
  crossgradeStep: ComputedRef<CrossgradeStep | null>
  currentScheduledChange: ComputedRef<ScheduledChange | null>
  currentScheduleGoldPlan: ComputedRef<Partial<Plan> | null>
  currentScheduleSilverPlan: ComputedRef<Partial<Plan> | null>
  displayedEstimatedCrossgradeCost: ComputedRef<EstimateSwitchingCostResult>
  displayedEstimatedGoldDowngradeCost: ComputedRef<EstimateSwitchingCostResult>
  displayedEstimatedSilverDowngradeCost: ComputedRef<EstimateSwitchingCostResult>
  downgradeError: ComputedRef<string>
  downgradeStep: ComputedRef<DowngradeStep | null>
  estimatedCancellationRefundAmount: ComputedRef<string | null>
  estimatedCrossgradeCost: ComputedRef<EstimateSwitchingCostResult | null>
  estimatedGoldDowngradeCost: ComputedRef<EstimateSwitchingCostResult | null>
  oppositeSchedulePlan: ComputedRef<Partial<Plan> | null>
  hasScheduledChanges: ComputedRef<boolean>
  isGoldLegacyTier: ComputedRef<boolean>
  isLoadingCancellationRefundInformation: ComputedRef<boolean>
  isLoadingCrossgradeInformation: ComputedRef<boolean>
  isLoadingGoldDowngradeInformation: ComputedRef<boolean>
  isLoadingSilverDowngradeInformation: ComputedRef<boolean>
  isOfferedPlanCurrencyEqualToUserPlanCurrency: ComputedRef<boolean>
  isPlatinumLegacyTier: ComputedRef<boolean>
  isProcessingCrossgrade: ComputedRef<boolean>
  isProcessingDowngrade: ComputedRef<boolean>
  isWebPlan: ComputedRef<boolean>
  xCrossgradeModal: ComputedRef<boolean>
  xDowngradeModal: ComputedRef<boolean>
  cancelImmediately: () => void
  closePlanCrossgradeModal: () => void
  closePlanDowngradeModal: () => void
  doNotRenew: () => void
  downgradePlatinumToGoldDifferentCurrencyImmediately: () => void
  downgradePlatinumToGoldImmediately: () => void
  schedulePlatinumToGoldDowngrade: () => void
  downgradeToSilverDifferentCurrencyImmediately: () => void
  downgradeToSilverImmediately: () => void
  scheduleToSilverDowngrade: () => void
  scheduleSwitch: () => void
  showPlanCrossgradeModal: (step: CrossgradeStep) => void
  showPlanCrossgradeModalCheckingCurrency: (payload: { userPlanCurrency: string }) => void
  showPlanDowngradeModal: (step: DowngradeStep) => void
  showPlanDowngradeToNeonModalCheckingQuota: (payload: { tierQuota: number; wallsUsed: number }) => void
  showPlanDowngradeToGoldModalCheckingQuota: (payload: {
    tierQuota: number
    wallsUsed: number
    userPlanCurrency: string
  }) => void
  showPlanDowngradeToSilverModalCheckingQuota: (payload: {
    tierQuota: number
    wallsUsed: number
    userPlanCurrency: string
  }) => void
  showScheduledChangeModal: () => void
  switchDifferentCurrencyImmediately: () => void
  switchImmediately: () => void
}

const usePersonalPlans = (billing: ComputedRef<BillingState>): PersonalPlansComposable => {
  const personalPlansStore = usePersonalPlansStore()

  const currentOfferedPlan = computed<Partial<Plan> | null>(() => {
    if (!isPaidBilling(billing.value)) return null
    return personalPlansStore.plans[billing.value.tier][normalizedPaymentSchedule(billing.value.period)]
  })

  const currentScheduleGoldPlan = computed<Partial<Plan> | null>(() => {
    if (!isPaidBilling(billing.value)) return null
    return personalPlansStore.plans.gold[normalizedPaymentSchedule(billing.value.period)]
  })

  const currentScheduleSilverPlan = computed<Partial<Plan> | null>(() => {
    if (!isPaidBilling(billing.value)) return null
    return personalPlansStore.plans.silver[normalizedPaymentSchedule(billing.value.period)]
  })

  const isGoldLegacyTier = computed<boolean>(() => isLegacyTier.value('gold'))

  const isLegacyTier = computed<(tier: 'silver' | 'gold' | 'platinum') => boolean>(
    () => (tier: 'silver' | 'gold' | 'platinum') =>
      billing.value?.tier === tier && !isOnNewPlan.value && isWebPlan.value,
  )

  const isOfferedPlanCurrencyEqualToUserPlanCurrency = computed<boolean>(() => {
    if (!isPaidBilling(billing.value)) return false
    return currentOfferedPlan.value?.currency === billing.value.plan.currency
  })

  const isOnNewPlan = computed<boolean>(() => {
    if (currentOfferedPlan.value == null || !isPaidBilling(billing.value)) return false

    return currentOfferedPlan.value.id === billing.value.plan.plan_id
  })

  const isPlatinumLegacyTier = computed<boolean>(() => isLegacyTier.value('platinum'))

  const oppositeSchedulePlan = computed<Partial<Plan> | null>(() => {
    if (!isPaidBilling(billing.value)) return null
    return personalPlansStore.plans[billing.value.tier][normalizedOppositePaymentSchedule(billing.value.period)]
  })

  const isWebPlan = computed<boolean>(() => {
    if (!isPaidBilling(billing.value)) return false
    return billing.value.payment_channel === 'chargebee'
  })

  // Plan cancellation
  const estimatedCancellationRefund = computed<EstimateCancellationRefundResult | null>(
    () => getVuexStore<DashboardSettingsState>()?.state.estimatedCancellationRefund ?? null,
  )

  const estimatedCancellationRefundAmount = computed<string | null>(() => {
    if (estimatedCancellationRefund.value == null) return null

    return translatePrice(
      parseFloat(estimatedCancellationRefund.value.amount),
      estimatedCancellationRefund.value.currency,
    )
  })

  const isLoadingCancellationRefundInformation = computed<boolean>(
    () => getVuexStore<DashboardSettingsState>()?.state.isLoadingCancellationRefundInformation ?? false,
  )

  function cancelImmediately(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('cancelImmediately')
  }

  // Plan crossgrade
  const crossgradeError = computed<string>(() => getVuexStore<DashboardSettingsState>()?.state.crossgradeError ?? '')

  const crossgradeStep = computed<CrossgradeStep | null>(
    () => getVuexStore<DashboardSettingsState>()?.state.crossgradeStep ?? null,
  )

  const estimatedCrossgradeCost = computed<EstimateSwitchingCostResult | null>(
    () => getVuexStore<DashboardSettingsState>()?.state.estimatedCrossgradeCost ?? null,
  )

  const isLoadingCrossgradeInformation = computed<boolean>(
    () => getVuexStore<DashboardSettingsState>()?.state.isLoadingCrossgradeInformation ?? false,
  )

  const isProcessingCrossgrade = computed<boolean>(
    () => getVuexStore<DashboardSettingsState>()?.state.isProcessingCrossgrade ?? false,
  )

  const xCrossgradeModal = computed<boolean>(
    () => getVuexStore<DashboardSettingsState>()?.state.xCrossgradeModal ?? false,
  )

  function closePlanCrossgradeModal(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('closePlanCrossgradeModal')
  }

  function scheduleSwitch(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('scheduleSwitch')
  }

  function showPlanCrossgradeModal(step: CrossgradeStep): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('showPlanCrossgradeModal', step)
  }

  function showPlanCrossgradeModalCheckingCurrency(payload: { userPlanCurrency: string }): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('showPlanCrossgradeModalCheckingCurrency', payload, {
      root: true,
    })
  }

  function switchDifferentCurrencyImmediately(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('switchDifferentCurrencyImmediately')
  }

  function switchImmediately(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('switchImmediately')
  }

  // Plan scheduled changes
  const currentScheduledChange = computed<ScheduledChange | null>(() => {
    if (!isPaidBilling(billing.value)) return null
    if (billing.value.status.is_cancelling_soon) return ScheduledChange.DowngradeToNeon
    if (billing.value.switch_plan_status.is_switching_soon) {
      if (
        billing.value.switch_plan_status.new_plan_id ===
        personalPlansStore.plans.gold[normalizedPaymentSchedule(billing.value.period)].id
      ) {
        return ScheduledChange.DowngradeToGold
      }
      if (
        billing.value.switch_plan_status.new_plan_id ===
        personalPlansStore.plans.silver[normalizedPaymentSchedule(billing.value.period)].id
      ) {
        return ScheduledChange.DowngradeToSilver
      }
      return billing.value.period === 'year' ? ScheduledChange.SwitchToMonthly : ScheduledChange.SwitchToAnnual
    }
    return null
  })

  const hasScheduledChanges = computed<boolean>(() => currentScheduledChange.value != null)

  function showScheduledChangeModal(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('showScheduledChangeModal')
  }

  // Plan downgrade
  const downgradeError = computed<string>(() => getVuexStore<DashboardSettingsState>()?.state.downgradeError ?? '')

  const downgradeStep = computed<DowngradeStep | null>(
    () => getVuexStore<DashboardSettingsState>()?.state.downgradeStep ?? null,
  )

  const estimatedGoldDowngradeCost = computed<EstimateSwitchingCostResult | null>(
    () => getVuexStore<DashboardSettingsState>()?.state.estimatedGoldDowngradeCost ?? null,
  )

  const estimatedSilverDowngradeCost = computed<EstimateSwitchingCostResult | null>(
    () => getVuexStore<DashboardSettingsState>()?.state.estimatedSilverDowngradeCost ?? null,
  )

  const isLoadingGoldDowngradeInformation = computed<boolean>(
    () => getVuexStore<DashboardSettingsState>()?.state.isLoadingGoldDowngradeInformation ?? false,
  )

  const isLoadingSilverDowngradeInformation = computed<boolean>(
    () => getVuexStore<DashboardSettingsState>()?.state.isLoadingSilverDowngradeInformation ?? false,
  )

  const isProcessingDowngrade = computed<boolean>(
    () => getVuexStore<DashboardSettingsState>()?.state.isProcessingDowngrade ?? false,
  )

  const xDowngradeModal = computed<boolean>(
    () => getVuexStore<DashboardSettingsState>()?.state.xDowngradeModal ?? false,
  )

  function closePlanDowngradeModal(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('closePlanDowngradeModal')
  }

  function doNotRenew(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('doNotRenew')
  }

  function downgradePlatinumToGoldDifferentCurrencyImmediately(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('downgradePlatinumToGoldDifferentCurrencyImmediately')
  }

  function downgradePlatinumToGoldImmediately(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('downgradePlatinumToGoldImmediately')
  }

  function schedulePlatinumToGoldDowngrade(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('schedulePlatinumToGoldDowngrade')
  }

  function downgradeToSilverDifferentCurrencyImmediately(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('downgradeToSilverDifferentCurrencyImmediately')
  }

  function downgradeToSilverImmediately(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('downgradeToSilverImmediately')
  }

  function scheduleToSilverDowngrade(): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('scheduleToSilverDowngrade')
  }

  function showPlanDowngradeModal(step: DowngradeStep): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('showPlanDowngradeModal', step)
  }

  function showPlanDowngradeToNeonModalCheckingQuota(payload: { tierQuota: number; wallsUsed: number }): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('showPlanDowngradeToNeonModalCheckingQuota', payload)
  }

  function showPlanDowngradeToGoldModalCheckingQuota(payload: {
    tierQuota: number
    wallsUsed: number
    userPlanCurrency: string
  }): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('showPlanDowngradeToGoldModalCheckingQuota', payload)
  }

  function showPlanDowngradeToSilverModalCheckingQuota(payload: {
    tierQuota: number
    wallsUsed: number
    userPlanCurrency: string
  }): void {
    void getVuexStore<DashboardSettingsState>()?.dispatch('showPlanDowngradeToSilverModalCheckingQuota', payload)
  }

  // Plan details, normalized for display
  const emptyEstimatedDowngradeCost = {
    is_refund: false,
    currency_code: '',
    new_plan_cost: '',
    unused_portion: '',
    refund_amount: '',
    amount_due: '',
    invoice_amount: '',
  }

  const displayedEstimatedGoldDowngradeCost = computed<EstimateSwitchingCostResult>(() => {
    if (estimatedGoldDowngradeCost.value == null) return emptyEstimatedDowngradeCost
    return estimatedGoldDowngradeCost.value
  })

  const displayedEstimatedSilverDowngradeCost = computed<EstimateSwitchingCostResult>(() => {
    if (estimatedSilverDowngradeCost.value == null) return emptyEstimatedDowngradeCost
    return estimatedSilverDowngradeCost.value
  })

  const displayedEstimatedCrossgradeCost = computed<EstimateSwitchingCostResult>(() => {
    if (estimatedCrossgradeCost.value == null) {
      return {
        is_refund: false,
        currency_code: '',
        new_plan_cost: '',
        unused_portion: '',
        refund_amount: '',
        amount_due: '',
        invoice_amount: '',
      }
    }
    return estimatedCrossgradeCost.value
  })

  return {
    // Getters
    crossgradeError,
    crossgradeStep,
    currentScheduledChange,
    currentScheduleGoldPlan,
    currentScheduleSilverPlan,
    displayedEstimatedCrossgradeCost,
    displayedEstimatedGoldDowngradeCost,
    displayedEstimatedSilverDowngradeCost,
    downgradeError,
    downgradeStep,
    estimatedCancellationRefundAmount,
    estimatedCrossgradeCost,
    estimatedGoldDowngradeCost,
    hasScheduledChanges,
    isGoldLegacyTier,
    isLoadingCancellationRefundInformation,
    isLoadingCrossgradeInformation,
    isLoadingGoldDowngradeInformation,
    isLoadingSilverDowngradeInformation,
    isOfferedPlanCurrencyEqualToUserPlanCurrency,
    isPlatinumLegacyTier,
    isProcessingCrossgrade,
    isProcessingDowngrade,
    isWebPlan,
    oppositeSchedulePlan,
    xCrossgradeModal,
    xDowngradeModal,

    // Actions
    cancelImmediately,
    closePlanCrossgradeModal,
    closePlanDowngradeModal,
    doNotRenew,
    downgradePlatinumToGoldDifferentCurrencyImmediately,
    downgradePlatinumToGoldImmediately,
    schedulePlatinumToGoldDowngrade,
    downgradeToSilverDifferentCurrencyImmediately,
    downgradeToSilverImmediately,
    scheduleToSilverDowngrade,
    scheduleSwitch,
    showPlanCrossgradeModal,
    showPlanCrossgradeModalCheckingCurrency,
    showPlanDowngradeModal,
    showPlanDowngradeToGoldModalCheckingQuota,
    showPlanDowngradeToSilverModalCheckingQuota,
    showPlanDowngradeToNeonModalCheckingQuota,
    showScheduledChangeModal,
    switchDifferentCurrencyImmediately,
    switchImmediately,
  }
}
