// @file Pinia Store for Library Members in Dashboard settings
import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import device from '@@/bits/device'
import { isEmail } from '@@/bits/email'
import { captureFetchException, captureMessage } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { buildUrlFromPath, navigateTo } from '@@/bits/location'
import { getDiff } from '@@/bits/object_diff_helper'
import PromiseQueue from '@@/bits/promise_queue'
import { clearCachedQueries } from '@@/bits/query_client'
import { LibraryInfo, LibraryMembers as LibraryMembersApi } from '@@/dashboard/padlet_api'
import { HttpCode, LibraryMembershipRole, LibraryMembershipStatus, SnackbarNotificationType } from '@@/enums'
import stopHandIcon from '@@/images/stop_hand.svg'
import { leaveLibrary as leaveLibraryMessage } from '@@/native_bridge/actions'
import postMessage from '@@/native_bridge/post_message'
import { useDashboardSettingsStore } from '@@/pinia/dashboard_settings'
import {
  OzConfirmationDialogBoxButtonScheme,
  useGlobalConfirmationDialogStore,
} from '@@/pinia/global_confirmation_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSettingsNavigationStore } from '@@/pinia/settings_navigation'
import type {
  JsonApiData,
  Library,
  LibraryId,
  LibraryInviteLinks,
  LibraryMembership,
  LibraryMembershipId,
  UserId,
} from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { unionBy } from 'es-toolkit'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

interface LibraryUserQuota {
  [LibraryMembershipRole.Owner]?: number
  [LibraryMembershipRole.Teacher]?: number
  [LibraryMembershipRole.Student]?: number
  [LibraryMembershipRole.Admin]?: number
}

interface LibraryUserQuotaLimit {
  advertizedTeacherQuota?: number
  advertizedStudentQuota?: number
  advertizedTeacherAndAdminQuota?: number
  maxTeacherQuota?: number
  maxStudentQuota?: number
  maxTeacherAndAdminQuota?: number
  maxOwnerQuota?: number
}

interface InviteRow {
  email: string
  validationMessage: string
  role?: LibraryMembershipRole
}

enum LibraryMembersStatus {
  Loading = 'Loading',
  Searching = 'Searching',
  LoadingMore = 'LoadingMore',
  Completed = 'Completed',
  Errored = 'Errored',
}

interface LibraryMembershipUpdatePayload {
  role: LibraryMembershipRole
  status: LibraryMembershipStatus
}

const isApp = device.app

// Promise queue to handle library membership update requests.
const q = new PromiseQueue()

const CSV_BATCH_SIZE = 100

export const useSettingsLibraryMembersStore = defineStore('settingsLibraryMembers', () => {
  const settingsNavigationStore = useSettingsNavigationStore()
  const dashboardSettingsStore = useDashboardSettingsStore()
  const globalSnackbarStore = useGlobalSnackbarStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()

  // State
  const xInviteModal = ref<boolean>(false)
  const adminLink = ref<string>('')
  const contributorLink = ref<string>('')
  const currentUserMembership = ref<LibraryMembership | null>(null)
  const isAwaitingInviteLinkForAdmin = ref<boolean>(false)
  const isAwaitingInviteLinkForContributor = ref<boolean>(false)
  const isAwaitingInviteLinkForMaker = ref<boolean>(false)
  const isAwaitingInviteLinkForStudent = ref<boolean>(false)
  const isAwaitingInviteLinkForTeacher = ref<boolean>(false)
  const isAwaitingEmailInviteResponse = ref<boolean>(false)
  const emailInviteResponseError = ref<string>('')
  const libraryMembersStatus = ref<LibraryMembersStatus>(LibraryMembersStatus.Loading)
  const makerLink = ref<string>('')
  const memberships = ref<LibraryMembership[]>([])
  const nextPage = ref<number | null>(null)
  const searchErrorMessage = ref<string>('')
  const studentLink = ref<string>('')
  const teacherLink = ref<string>('')
  const userQuota = ref<LibraryUserQuota>({ [LibraryMembershipRole.Owner]: 1 })
  const userQuotaLimits = ref<LibraryUserQuotaLimit>({ maxOwnerQuota: 1 })
  const selectedUsers = ref<UserId[]>([])
  const lastSelectedUser = ref<UserId | null>(null)
  const inviteRows = ref<InviteRow[]>([{ email: '', role: undefined, validationMessage: '' }])
  const isCsvErrored = ref<boolean>(false)
  const isCsvLoading = ref<boolean>(false)
  const csvErrorMessage = ref<string>('')
  const isCappedPlan = ref<boolean>(false)
  const csvTotalRows = ref<number>(0)
  const csvCompletedBatches = ref<number>(0)

  // Getters
  const exceededLibraryStudentQuota = computed((): boolean => {
    if (libraryStudentCount.value === 0 || libraryMaxStudentQuota.value === 0) return false
    return libraryStudentCount.value >= libraryMaxStudentQuota.value
  })
  const exceededLibraryTeacherQuota = computed((): boolean => {
    if (libraryTeacherCount.value === 0 || libraryMaxTeacherQuota.value === 0) return false
    return libraryTeacherCount.value >= libraryMaxTeacherQuota.value
  })
  const exceededLibraryTeacherAndAdminQuota = computed((): boolean => {
    if (libraryTeacherAndAdminCount.value === 0 || libraryMaxTeacherAndAdminQuota.value === 0) return false
    return libraryTeacherAndAdminCount.value >= libraryMaxTeacherAndAdminQuota.value
  })
  const hasMoreMemberships = computed((): boolean => Boolean(nextPage.value))
  const isLoadingLibraryMemberships = computed(
    (): boolean => libraryMembersStatus.value === LibraryMembersStatus.Loading,
  )
  const isLoadingMoreLibraryMemberships = computed(
    (): boolean => libraryMembersStatus.value === LibraryMembersStatus.LoadingMore,
  )
  const isSearchingLibraryMemberships = computed(
    (): boolean => libraryMembersStatus.value === LibraryMembersStatus.Searching,
  )
  const libraryAdvertizedStudentQuota = computed((): number => userQuotaLimits.value.advertizedStudentQuota ?? 0)
  const libraryAdvertizedTeacherAndOwnerQuota = computed(
    (): number => libraryAdvertizedTeacherQuota.value + libraryMaxOwnerQuota.value,
  )
  const libraryAdvertizedTeacherQuota = computed((): number => userQuotaLimits.value.advertizedTeacherQuota ?? 0)
  const libraryAdvertizedTeacherAndAdminQuota = computed(
    (): number => userQuotaLimits.value.advertizedTeacherAndAdminQuota ?? 0,
  )
  const libraryMaxOwnerQuota = computed((): number => userQuotaLimits.value.maxOwnerQuota ?? 0)
  const libraryMaxStudentQuota = computed((): number => userQuotaLimits.value.maxStudentQuota ?? 0)
  const libraryMaxTeacherQuota = computed((): number => userQuotaLimits.value.maxTeacherQuota ?? 0)
  const libraryMaxTeacherAndAdminQuota = computed((): number => userQuotaLimits.value.maxTeacherAndAdminQuota ?? 0)

  const libraryOwnerCount = computed((): number => userQuota.value[LibraryMembershipRole.Owner] ?? 0)
  const libraryStudentCount = computed((): number => userQuota.value[LibraryMembershipRole.Student] ?? 0)
  const libraryTeacherAndOwnerCount = computed((): number => libraryTeacherCount.value + libraryOwnerCount.value)
  const libraryTeacherCount = computed((): number => userQuota.value[LibraryMembershipRole.Teacher] ?? 0)
  const libraryTeacherAndAdminCount = computed((): number => libraryTeacherCount.value + libraryAdminCount.value)
  const libraryAdminCount = computed((): number => userQuota.value[LibraryMembershipRole.Admin] ?? 0)
  const commonSelectedRole = computed((): LibraryMembershipRole => {
    if (selectedUsers.value.length === 0) return LibraryMembershipRole.Guest

    if (selectedMemberships.value.length === 0) return LibraryMembershipRole.Guest

    const firstRole = selectedMemberships.value[0].role
    if (selectedMemberships.value.every((member) => member.role === firstRole && member.status === 'added'))
      return firstRole
    return LibraryMembershipRole.Guest
  })

  const atLeastOneEmail = computed<boolean>(() => {
    return inviteRows.value.some((row) => row.email !== '' && isEmail(row.email))
  })

  const allRowsValid = computed<boolean>(() => {
    const allEmailsValid = inviteRows.value.every((row) => {
      return row.email === '' || (!!row.role && isEmail(row.email))
    })
    const noRepeatedEmails = new Set(inviteRows.value.map((row) => row.email)).size === inviteRows.value.length
    return atLeastOneEmail.value && allEmailsValid && noRepeatedEmails
  })

  const selectedMemberships = computed((): LibraryMembership[] =>
    memberships.value.filter((membership) => selectedUsers.value.includes(membership.userId)),
  )

  const csvTotalBatches = computed((): number => {
    return Math.ceil(csvTotalRows.value / CSV_BATCH_SIZE)
  })

  // Actions
  const changeOwner = async ({ userId }: { userId: UserId }): Promise<void> => {
    if (settingsNavigationStore.currentLibraryId === null) {
      captureMessage('LibraryMembersChangeOwner: currentLibraryId is null')
      return
    }

    try {
      await LibraryMembersApi.changeOwner({
        libraryId: settingsNavigationStore.currentLibraryId,
        userId,
      })

      clearCachedQueries()

      dashboardSettingsStore.fetchViewableLibraries()
      // We set current library membership as null to force a re-fetch of the current user's membership in fetchLibraryMemberships
      // currentLibraryMembership will be the library owner's if this method is called
      currentUserMembership.value = null
      void fetchLibraryMemberships()
    } catch (e) {
      captureFetchException(e, { source: 'LibraryMembersChangeOwner' })
      globalSnackbarStore.setSnackbar({
        message: __('Owner could not be changed'),
        notificationType: SnackbarNotificationType.error,
        timeout: 1500,
      })
    }
  }

  const fetchLibraryInviteLinks = async (): Promise<void> => {
    if (settingsNavigationStore.currentLibraryId === null) {
      captureMessage('LibraryMembersFetchLibraryInviteLinks: currentLibraryId is null')
      return
    }

    try {
      // TODO: Show loading state for invite modal
      const response = await LibraryMembersApi.fetchLibraryInviteLinks({
        libraryId: settingsNavigationStore.currentLibraryId,
      })
      const libraryInviteLinks = (response?.data as JsonApiData<LibraryInviteLinks>)?.attributes
      adminLink.value = libraryInviteLinks.linkForAdmin ?? ''
      makerLink.value = libraryInviteLinks.linkForMaker ?? ''
      contributorLink.value = libraryInviteLinks.linkForContributor ?? ''
      teacherLink.value = libraryInviteLinks.linkForTeacher ?? ''
      studentLink.value = libraryInviteLinks.linkForStudent ?? ''
    } catch (e) {
      captureFetchException(e, { source: 'LibraryMembersFetchLibraryInviteLinks' })
    }
  }

  const fetchLibraryMemberships = async (): Promise<void> => {
    if (settingsNavigationStore.currentLibraryId === null) {
      captureMessage('LibraryMembersFetchLibraryMemberships: currentLibraryId is null')
      return
    }

    try {
      libraryMembersStatus.value = LibraryMembersStatus.Loading
      const libraryInfo = await LibraryInfo.fetch({ libraryId: settingsNavigationStore.currentLibraryId })
      const payload = (libraryInfo.data as JsonAPIResource<Library>).attributes.userQuota
      userQuota.value = {
        [LibraryMembershipRole.Owner]: payload?.ownerQuotaUsed,
        [LibraryMembershipRole.Student]: payload?.studentQuotaUsed,
        [LibraryMembershipRole.Teacher]: payload?.teacherQuotaUsed,
        [LibraryMembershipRole.Admin]: payload?.adminQuotaUsed,
      }
      userQuotaLimits.value = {
        advertizedTeacherQuota: payload?.advertizedTeacherQuota,
        advertizedStudentQuota: payload?.advertizedStudentQuota,
        advertizedTeacherAndAdminQuota: payload?.advertizedTeacherAndAdminQuota,
        maxTeacherQuota: payload?.maxTeacherQuota,
        maxStudentQuota: payload?.maxStudentQuota,
        maxOwnerQuota: payload?.maxOwnerQuota,
        maxTeacherAndAdminQuota: payload?.maxTeacherAndAdminQuota,
      }

      isCappedPlan.value = payload?.isCappedPlan ?? false

      const response = await LibraryMembersApi.fetchMemberships({
        libraryId: settingsNavigationStore.currentLibraryId,
        next: '0',
      })
      const libraryMemberships = (response?.data as Array<JsonAPIResource<LibraryMembership>>).map(
        (membership) => membership.attributes,
      )

      memberships.value = libraryMemberships
      nextPage.value = response?.links?.next !== undefined ? Number(response.links.next) : null
      libraryMembersStatus.value = LibraryMembersStatus.Completed

      // Only set the current user membership on the first fetch
      if (currentUserMembership.value === null) {
        currentUserMembership.value =
          libraryMemberships.find((member) => member.userId === dashboardSettingsStore.user.id) ?? null
      }
    } catch (e) {
      libraryMembersStatus.value = LibraryMembersStatus.Errored
      captureFetchException(e, { source: 'LibraryMembersFetchLibraryMemberships' })
    }
  }

  const fetchMoreLibraryMemberships = async (): Promise<void> => {
    if (settingsNavigationStore.currentLibraryId === null) {
      captureMessage('LibraryMembersFetchMoreLibraryMemberships: currentLibraryId is null')
      return
    }

    try {
      if (!hasMoreMemberships.value) return

      libraryMembersStatus.value = LibraryMembersStatus.LoadingMore

      const response = await LibraryMembersApi.fetchMemberships({
        libraryId: settingsNavigationStore.currentLibraryId,
        next: String(nextPage.value),
      })
      const libraryMemberships = (response?.data as Array<JsonApiData<LibraryMembership>>).map(
        (membership) => membership.attributes,
      )

      memberships.value = unionBy(memberships.value, libraryMemberships, (membership) => membership.id)
      nextPage.value = response?.links?.next !== undefined ? Number(response.links.next) : null
      libraryMembersStatus.value = LibraryMembersStatus.Completed
    } catch (e) {
      libraryMembersStatus.value = LibraryMembersStatus.Errored
      captureFetchException(e, { source: 'LibraryMembersFetchMoreLibraryMemberships' })
      globalSnackbarStore.setSnackbar({
        message: __('Results could not be loaded'),
        notificationType: SnackbarNotificationType.error,
      })
    }
  }

  const leaveLibrary = async (): Promise<void> => {
    if (settingsNavigationStore.currentLibraryId === null) {
      captureMessage('LibraryMembersLeaveLibrary: currentLibraryId is null')
      return
    }

    try {
      await LibraryMembersApi.leaveLibrary({
        libraryId: settingsNavigationStore.currentLibraryId,
      })

      if (isApp) {
        postMessage(leaveLibraryMessage())
      } else {
        clearCachedQueries()
        navigateTo(buildUrlFromPath('dashboard'))
      }
    } catch (e) {
      captureFetchException(e, { source: 'LibraryMembersLeaveLibrary' })
    }
  }

  const queueUpdateLibraryMembership = ({
    membershipId,
    role,
    status,
  }: {
    membershipId: LibraryMembershipId
    role: LibraryMembershipRole
    status: LibraryMembershipStatus
  }): void => {
    const preUpdateMembership = memberships.value.find((membership) => membership.id === membershipId)

    if (preUpdateMembership === undefined) {
      captureMessage('LibraryMembersQueueUpdateLibraryMembership: preUpdateMembership is undefined')
      return
    }

    // Update the membership immediately, but revert it if the update fails
    const updatedMemberships = memberships.value.map((membership) => {
      if (membership.id === membershipId) {
        return {
          ...membership,
          role,
          status,
        }
      }
      return membership
    })

    memberships.value = updatedMemberships

    updateUserRoleCount({
      preUpdateRole: preUpdateMembership.role,
      role,
      preUpdateStatus: preUpdateMembership.status as LibraryMembershipStatus,
      status,
    })
    void q.enqueue(
      'updateLibraryMembership',
      async () =>
        await updateLibraryMembership({
          membershipId,
          role,
          status,
          preUpdateRole: preUpdateMembership.role,
          preUpdateStatus: preUpdateMembership.status as LibraryMembershipStatus,
        }),
    )
  }

  const searchMembers = async ({
    searchQuery,
    loadMore = false,
  }: {
    searchQuery: string
    loadMore?: boolean
  }): Promise<void> => {
    const query = searchQuery.trim().toLowerCase()
    if (query.length === 0) {
      void fetchLibraryMemberships()
      return
    }

    if (settingsNavigationStore.currentLibraryId === null) {
      captureMessage('LibraryMembersSearchMembers: currentLibraryId is null')
      return
    }

    if (!loadMore) {
      nextPage.value = 0
      libraryMembersStatus.value = LibraryMembersStatus.Searching
    } else {
      libraryMembersStatus.value = LibraryMembersStatus.LoadingMore
    }

    try {
      searchErrorMessage.value = ''

      const response = await LibraryMembersApi.search({
        libraryId: settingsNavigationStore.currentLibraryId,
        query,
        next: String(nextPage.value !== null ? nextPage.value : 0),
      })
      const libraryMemberships = (response?.data as Array<JsonApiData<LibraryMembership>>).map(
        (membership) => membership.attributes,
      )

      if (loadMore) {
        memberships.value = unionBy(memberships.value, libraryMemberships, (membership) => membership.id)
      } else {
        memberships.value = libraryMemberships
      }

      nextPage.value = response?.links?.next !== undefined ? Number(response.links.next) : 0
      libraryMembersStatus.value = LibraryMembersStatus.Completed
    } catch (e) {
      searchErrorMessage.value = __('Search results could not be loaded. Please try again or check your connection.')
      memberships.value = []
      libraryMembersStatus.value = LibraryMembersStatus.Errored

      captureFetchException(e, { source: 'LibraryMembersSearchMembers' })
    }
  }

  const setIsAwaitingInviteLinkByRole = ({
    role,
    isAwaitingInviteLink,
  }: {
    role: LibraryMembershipRole
    isAwaitingInviteLink: boolean
  }): void => {
    if (role === LibraryMembershipRole.Admin) {
      isAwaitingInviteLinkForAdmin.value = isAwaitingInviteLink
    } else if (role === LibraryMembershipRole.Maker) {
      isAwaitingInviteLinkForMaker.value = isAwaitingInviteLink
    } else if (role === LibraryMembershipRole.Contributor) {
      isAwaitingInviteLinkForContributor.value = isAwaitingInviteLink
    } else if (role === LibraryMembershipRole.Teacher) {
      isAwaitingInviteLinkForTeacher.value = isAwaitingInviteLink
    } else if (role === LibraryMembershipRole.Student) {
      isAwaitingInviteLinkForStudent.value = isAwaitingInviteLink
    }
  }

  const toggleInviteLink = async ({ role }: { role: LibraryMembershipRole }): Promise<void> => {
    if (settingsNavigationStore.currentLibraryId === null) {
      captureMessage('LibraryMembersToggleInviteLink: currentLibraryId is null')
      return
    }

    try {
      setIsAwaitingInviteLinkByRole({ role, isAwaitingInviteLink: true })

      const updateAttributes: {
        libraryId: LibraryId
        codeForAdminEnabled?: boolean
        codeForMakerEnabled?: boolean
        codeForContributorEnabled?: boolean
        codeForTeacherEnabled?: boolean
        codeForStudentEnabled?: boolean
      } = {
        libraryId: settingsNavigationStore.currentLibraryId,
      }
      if (role === LibraryMembershipRole.Admin) {
        updateAttributes.codeForAdminEnabled = adminLink.value === ''
      } else if (role === LibraryMembershipRole.Maker) {
        updateAttributes.codeForMakerEnabled = makerLink.value === ''
      } else if (role === LibraryMembershipRole.Contributor) {
        updateAttributes.codeForContributorEnabled = contributorLink.value === ''
      } else if (role === LibraryMembershipRole.Teacher) {
        updateAttributes.codeForTeacherEnabled = teacherLink.value === ''
      } else if (role === LibraryMembershipRole.Student) {
        updateAttributes.codeForStudentEnabled = studentLink.value === ''
      }

      const response = await LibraryMembersApi.toggleInviteLink(updateAttributes)
      const libraryInviteLinks = (response?.data as JsonApiData<LibraryInviteLinks>)?.attributes
      adminLink.value = libraryInviteLinks.linkForAdmin ?? ''
      makerLink.value = libraryInviteLinks.linkForMaker ?? ''
      contributorLink.value = libraryInviteLinks.linkForContributor ?? ''
      teacherLink.value = libraryInviteLinks.linkForTeacher ?? ''
      studentLink.value = libraryInviteLinks.linkForStudent ?? ''
    } catch (e) {
      captureFetchException(e, { source: 'LibraryMembersToggleInviteLink' })
      globalSnackbarStore.setSnackbar({
        message: __('Link could not be updated'),
        notificationType: SnackbarNotificationType.error,
        timeout: 1500,
      })
    } finally {
      void setIsAwaitingInviteLinkByRole({ role, isAwaitingInviteLink: false })
    }
  }

  const updateLibraryMembership = async ({
    membershipId,
    role,
    status,
    preUpdateRole,
    preUpdateStatus,
  }: {
    membershipId: LibraryMembershipId
    role: LibraryMembershipRole
    status: LibraryMembershipStatus
    preUpdateRole: LibraryMembershipRole
    preUpdateStatus: LibraryMembershipStatus
  }): Promise<void> => {
    try {
      const updatePayload = getDiff<LibraryMembershipUpdatePayload>(
        { role: preUpdateRole, status: preUpdateStatus },
        { role, status },
      )

      const response = await LibraryMembersApi.updateMembership({
        id: membershipId,
        ...updatePayload,
      })

      const updatedCurrentUserMembership = (response.data as JsonAPIResource<LibraryMembership>).attributes
      // Only re-fetch viewable libraries when the current user is the one being updated
      if (currentUserMembership.value?.id === membershipId) {
        dashboardSettingsStore.fetchViewableLibraries()
        currentUserMembership.value = updatedCurrentUserMembership
        clearCachedQueries()
      }
    } catch (e) {
      captureFetchException(e, { source: 'LibraryMembersUpdateMembership' })
      globalSnackbarStore.setSnackbar({
        message: __('Role could not be changed'),
        notificationType: SnackbarNotificationType.error,
        timeout: 1500,
      })

      q.clear()

      // Revert the role and status of the membership to the pre-update values
      memberships.value = memberships.value.map((membership) => {
        if (membership.id === membershipId) {
          return {
            ...membership,
            role: preUpdateRole,
            status: preUpdateStatus,
          }
        }
        return membership
      })
      updateUserRoleCount({
        role: preUpdateRole,
        preUpdateRole: role,
        status: preUpdateStatus,
        preUpdateStatus: status,
      })
    }
  }

  const updateUserRoleCount = ({
    preUpdateRole,
    role,
    preUpdateStatus,
    status,
  }: {
    preUpdateRole: LibraryMembershipRole
    role: LibraryMembershipRole
    preUpdateStatus: LibraryMembershipStatus
    status: LibraryMembershipStatus
  }): void => {
    const userTransitionFromDisabledToEnabled =
      [LibraryMembershipStatus.Deactivated, LibraryMembershipStatus.Left, LibraryMembershipStatus.Suspended].includes(
        preUpdateStatus,
      ) && status === LibraryMembershipStatus.Added

    if (userTransitionFromDisabledToEnabled && userQuota.value[role] !== undefined) {
      userQuota.value[role] += 1
    }

    const userTransitionFromEnabledToDisabled =
      preUpdateStatus === LibraryMembershipStatus.Added &&
      [LibraryMembershipStatus.Deactivated, LibraryMembershipStatus.Left, LibraryMembershipStatus.Suspended].includes(
        status,
      )
    if (userTransitionFromEnabledToDisabled && userQuota.value[preUpdateRole] !== undefined) {
      userQuota.value[preUpdateRole] -= 1
    }

    const isUserSwappingRoles =
      preUpdateStatus === LibraryMembershipStatus.Added && status === LibraryMembershipStatus.Added

    if (isUserSwappingRoles) {
      if (userQuota.value[preUpdateRole] !== undefined) {
        userQuota.value[preUpdateRole] -= 1
      }
      if (userQuota.value[role] !== undefined) {
        userQuota.value[role] += 1
      }
    }
  }

  const selectUser = (userId: UserId, event: PointerEvent | MouseEvent | KeyboardEvent): void => {
    if (!isAppUsing('libraryMembersPageV2')) {
      return
    }
    const membership = memberships.value.find((membership) => membership.userId === userId)
    if (membership === undefined || membership.role === LibraryMembershipRole.Owner) {
      return
    }

    if (event.shiftKey && lastSelectedUser.value !== null) {
      const pivot = getMembershipIndexOfUserId(lastSelectedUser.value)
      const toIndex = getMembershipIndexOfUserId(userId)

      const start = Math.min(pivot, toIndex)
      const end = Math.max(pivot, toIndex)

      for (const membership of memberships.value.slice(start, end + 1)) {
        if (!selectedUsers.value.includes(membership.userId) && membership.role !== LibraryMembershipRole.Owner) {
          selectedUsers.value.push(membership.userId)
        }
      }
      return
    }

    const index = selectedUsers.value.indexOf(userId)
    index === -1 ? selectedUsers.value.push(userId) : selectedUsers.value.splice(index, 1)

    lastSelectedUser.value = userId
  }

  const toggleSelectOnAllUsers = (select: boolean): void => {
    if (!isAppUsing('libraryMembersPageV2')) {
      return
    }
    if (!select) {
      selectedUsers.value = []
    } else {
      memberships.value.forEach((membership) => {
        if (membership.role !== LibraryMembershipRole.Owner) {
          selectedUsers.value.push(membership.userId)
        }
      })
    }
  }

  const bulkDeactivateMembers = async (): Promise<void> => {
    if (!dashboardSettingsStore.canManageMembers) {
      return
    }
    const confirmationDialogAttributes = {
      title:
        selectedUsers.value.length > 1
          ? __('Deactivate %{count} Members?', { count: selectedUsers.value.length })
          : __('Deactivate Member?'),
      confirmButtonText: __('Deactivate'),
      body: __(
        'They will no longer be able to access %{schoolName}. All of their padlets, posts, and comments will stay as is. Admins can add them back at any time.',
        { schoolName: dashboardSettingsStore.currentLibrary?.name ?? 'your school' },
      ),
    }
    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      ...confirmationDialogAttributes,
      shouldFadeIn: true,

      forceFullWidthButtons: true,
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      afterConfirmActions: [
        async () => {
          await deactivateSelectedMembers()
        },
      ],
    })
  }

  const bulkChangeRole = async (role: LibraryMembershipRole): Promise<void> => {
    if (!dashboardSettingsStore.canManageMembers) {
      return
    }
    globalConfirmationDialogStore.openConfirmationDialog({
      iconSrc: stopHandIcon,
      iconAlt: __('Stop Hand Icon'),
      shouldFadeIn: true,
      title:
        selectedUsers.value.length > 1
          ? __('Change %{count} roles?', { count: selectedUsers.value.length })
          : __('Change Role?'),
      body:
        selectedUsers.value.length > 1
          ? __('%{count} members will be changed to %{role}.', {
              count: selectedUsers.value.length,
              role,
            })
          : __('The member you have selected will be changed to %{role}.', {
              role,
            }),
      confirmButtonText: selectedUsers.value.length > 1 ? __('Change Roles') : __('Change Role'),
      forceFullWidthButtons: true,
      buttonScheme: OzConfirmationDialogBoxButtonScheme.Default,
      afterConfirmActions: [
        async () => {
          await changeRoleOfSelectedMembers(role)
        },
      ],
    })
  }

  const validateAndSendEmails = async (): Promise<void> => {
    if (!allRowsValid.value) {
      return
    }

    // Send emails
    const rowsToEmail: InviteRow[] = inviteRows.value.filter((row) => row.email !== '')
    const onlyEmailAndRole: Array<{ role: string; email: string }> = rowsToEmail.map((row) => ({
      role: row.role ?? '',
      email: row.email,
    }))

    if (settingsNavigationStore.currentLibraryId === null) {
      captureMessage('LibraryMembersChangeOwner: currentLibraryId is null')
      return
    }

    try {
      isAwaitingEmailInviteResponse.value = true
      emailInviteResponseError.value = ''
      await LibraryMembersApi.sendEmailInvites({
        libraryId: settingsNavigationStore.currentLibraryId,
        emailRows: onlyEmailAndRole,
      })

      inviteRows.value = [{ email: '', role: undefined, validationMessage: '' }]
      xInviteModal.value = false

      globalSnackbarStore.setSnackbar({
        message: __('Invite emails sent'),
        notificationType: SnackbarNotificationType.success,
        timeout: 1500,
      })
    } catch (e) {
      if (e.status === HttpCode.UnprocessableEntity) {
        const parsedErrorMessage = JSON.parse(e.message)
        emailInviteResponseError.value = parsedErrorMessage.errors[0].title ?? __('Email invites could not be sent')
      } else {
        emailInviteResponseError.value = __('Email invites could not be sent')
      }

      captureFetchException(e, { source: 'LibraryMembersSendEmailInvites' })
    } finally {
      isAwaitingEmailInviteResponse.value = false
    }
  }

  const csvBulkAddMembers = async (data): Promise<void> => {
    if (settingsNavigationStore.currentLibraryId === null) {
      captureMessage('LibraryMembersCsvBulkAddMembers: currentLibraryId is null')
      return
    }
    try {
      isCsvLoading.value = true
      isCsvErrored.value = false
      csvTotalRows.value = data.length
      csvCompletedBatches.value = 0

      // Process data in batches
      const totalBatches = csvTotalBatches.value
      const batchPromises: Array<Promise<void>> = []

      for (let i = 0; i < totalBatches; i++) {
        const startIndex = i * CSV_BATCH_SIZE
        const endIndex = Math.min(startIndex + CSV_BATCH_SIZE, data.length)
        const batchData = data.slice(startIndex, endIndex)

        // Create a promise for each batch and track its completion
        const batchPromise = LibraryMembersApi.csvBulkAddMembers({
          libraryId: settingsNavigationStore.currentLibraryId,
          InvitedUsersList: batchData,
        }).then(() => {
          csvCompletedBatches.value += 1
        })

        batchPromises.push(batchPromise)
      }

      // Process all batches concurrently
      const results = await Promise.allSettled(batchPromises)

      // Check for any failures
      const failures = results.filter((result) => result.status === 'rejected')
      if (failures.length > 0) {
        // At least one batch failed
        csvErrorMessage.value = __(
          'Some invites could not be sent. %{processed} out of %{total} batches were processed successfully.',
          {
            processed: csvCompletedBatches.value,
            total: totalBatches,
          },
        )
        isCsvErrored.value = true

        failures.forEach((failure, index) => {
          try {
            const parsedErrorMessage = JSON.parse(failure.reason.message)
            captureFetchException(parsedErrorMessage.errors[0]?.title ?? 'Unknown error', {
              source: 'csvBulkAddMembers',
              extra: { batchIndex: index, errorCount: failures.length },
            })
          } catch (e) {
            captureFetchException(failure.reason.message ?? 'Unknown error', {
              source: 'csvBulkAddMembers',
              extra: { batchIndex: index, errorCount: failures.length, parseError: true },
            })
          }
        })
      } else {
        xInviteModal.value = false
        globalSnackbarStore.setSnackbar({
          message: __('Invite emails sent'),
          notificationType: SnackbarNotificationType.success,
          timeout: 1500,
        })
      }
    } catch (e) {
      csvErrorMessage.value = __('There was an error with the CSV file. Please try again.')
      captureFetchException(e, { source: 'csvBulkAddMembers' })
      isCsvErrored.value = true
    } finally {
      isCsvLoading.value = false
      csvTotalRows.value = 0
      csvCompletedBatches.value = 0
    }
  }

  // helpers

  async function queueBulkDeactivateMembers(memberships: LibraryMembership[]): Promise<void> {
    const promises = memberships.map((membership) =>
      queueUpdateLibraryMembership({
        membershipId: membership.id,
        role: membership.role,
        status: LibraryMembershipStatus.Deactivated,
      }),
    )

    await Promise.all(promises)
  }

  async function deactivateSelectedMembers(): Promise<void> {
    if (!dashboardSettingsStore.canManageMembers) {
      return
    }

    await queueBulkDeactivateMembers(selectedMemberships.value)

    const message =
      selectedUsers.value.length > 1
        ? __('%{count} users deactivated', { count: selectedUsers.value.length })
        : __('User deactivated')

    globalSnackbarStore.setSnackbar({
      message,
      notificationType: SnackbarNotificationType.success,
      timeout: 1500,
    })
  }

  async function changeRoleOfSelectedMembers(role: LibraryMembershipRole): Promise<void> {
    if (!dashboardSettingsStore.canManageMembers) {
      return
    }

    const promises = selectedMemberships.value.map((membership) =>
      queueUpdateLibraryMembership({
        membershipId: membership.id,
        role,
        status: LibraryMembershipStatus.Added,
      }),
    )
    await Promise.all(promises)
    globalSnackbarStore.setSnackbar({
      message:
        selectedUsers.value.length > 1
          ? __('%{count} users changed to teacher', { count: selectedUsers.value.length })
          : __('User changed to teacher'),
      notificationType: SnackbarNotificationType.success,
      timeout: 1500,
    })
  }

  function getMembershipIndexOfUserId(userId: UserId): number {
    return memberships.value.findIndex((membership) => membership.userId === userId) ?? -1
  }

  watch(
    () => settingsNavigationStore.currentLibraryId,
    (newLibraryId) => {
      selectedUsers.value = []
      nextPage.value = null
    },
  )

  return {
    // State
    xInviteModal,
    adminLink,
    contributorLink,
    currentUserMembership,
    isAwaitingInviteLinkForAdmin,
    isAwaitingInviteLinkForContributor,
    isAwaitingInviteLinkForMaker,
    isAwaitingInviteLinkForStudent,
    isAwaitingInviteLinkForTeacher,
    isAwaitingEmailInviteResponse,
    makerLink,
    memberships,
    searchErrorMessage,
    studentLink,
    teacherLink,
    userQuota,
    selectedUsers,
    inviteRows,
    emailInviteResponseError,
    isCsvErrored,
    isCsvLoading,
    csvErrorMessage,
    isCappedPlan,
    csvCompletedBatches,

    // Getters
    exceededLibraryStudentQuota,
    exceededLibraryTeacherQuota,
    exceededLibraryTeacherAndAdminQuota,
    hasMoreMemberships,
    isLoadingLibraryMemberships,
    isLoadingMoreLibraryMemberships,
    isSearchingLibraryMemberships,
    libraryAdvertizedStudentQuota,
    libraryAdvertizedTeacherAndOwnerQuota,
    libraryAdvertizedTeacherQuota,
    libraryAdvertizedTeacherAndAdminQuota,
    libraryMaxOwnerQuota,
    libraryMaxStudentQuota,
    libraryMaxTeacherQuota,
    libraryMaxTeacherAndAdminQuota,
    libraryStudentCount,
    libraryTeacherAndOwnerCount,
    libraryTeacherCount,
    libraryTeacherAndAdminCount,
    libraryAdminCount,
    commonSelectedRole,
    atLeastOneEmail,
    allRowsValid,
    selectedMemberships,
    csvTotalBatches,

    // Actions
    changeOwner,
    fetchLibraryInviteLinks,
    fetchLibraryMemberships,
    fetchMoreLibraryMemberships,
    leaveLibrary,
    queueUpdateLibraryMembership,
    searchMembers,
    toggleInviteLink,
    selectUser,
    toggleSelectOnAllUsers,
    bulkDeactivateMembers,
    bulkChangeRole,
    validateAndSendEmails,
    csvBulkAddMembers,
  }
})
