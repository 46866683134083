// @file Briefcase permissions settings store
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import PromiseQueue from '@@/bits/promise_queue'
import { TenantPermissions as TenantPermissionsApi } from '@@/dashboard/padlet_api'
import { SnackbarNotificationType, WallPermission, WallPrivacy } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { Tenant } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum BriefcasePermissionsStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

export const useSettingsBriefcasePermissionsStore = defineStore('settingsBriefcasePermissions', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const queue = new PromiseQueue()

  // State
  const tenant = ref<Tenant>()
  const briefcaseDefaultWallPrivacy = ref(WallPrivacy.Private)
  const briefcaseDefaultWallPermission = ref(WallPermission.Read)
  const briefcasePermissionsStatus = ref(BriefcasePermissionsStatus.Loading)

  // Getters
  const isBriefcasePermissionsLoading = computed(
    () => briefcasePermissionsStatus.value === BriefcasePermissionsStatus.Loading,
  )

  // Actions
  async function initialize(tenantObject?: Tenant): Promise<void> {
    if (tenantObject === undefined) {
      void globalSnackbarStore.genericFetchError()
      return
    }
    tenant.value = tenantObject
    await fetchBriefcasePermissions()
  }

  async function fetchBriefcasePermissions(): Promise<void> {
    try {
      briefcasePermissionsStatus.value = BriefcasePermissionsStatus.Loading
      const response = await TenantPermissionsApi.fetch(tenant.value?.id as number)
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings?.permissions as {
        briefcaseDefaultWallPermission: WallPermission
        briefcaseDefaultWallPrivacy: WallPrivacy
      }

      briefcaseDefaultWallPrivacy.value = permissions?.briefcaseDefaultWallPrivacy ?? WallPrivacy.Private
      briefcaseDefaultWallPermission.value = permissions?.briefcaseDefaultWallPermission ?? WallPermission.Read
      briefcasePermissionsStatus.value = BriefcasePermissionsStatus.Completed
    } catch (e) {
      void globalSnackbarStore.genericFetchError()
      briefcasePermissionsStatus.value = BriefcasePermissionsStatus.Errored
      captureFetchException(e, { source: 'SettingsBriefcasePermissionsFetchBriefcasePermissions' })
    }
  }

  async function updateBriefcaseDefaultPrivacy(
    wallPrivacy: WallPrivacy,
    initialDefaultPrivacy: WallPrivacy,
  ): Promise<void> {
    const tenantId = tenant.value?.id as number
    try {
      const response = await TenantPermissionsApi.update(tenantId, {
        settings: {
          permissions: { briefcaseDefaultWallPrivacy: wallPrivacy },
        },
      })
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings?.permissions
      if (permissions?.briefcaseDefaultWallPrivacy !== undefined) {
        briefcaseDefaultWallPrivacy.value = permissions.briefcaseDefaultWallPrivacy
      }

      if (permissions?.briefcaseDefaultWallPermission !== undefined) {
        briefcaseDefaultWallPermission.value = permissions.briefcaseDefaultWallPermission
      }
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating default privacy.'),
        notificationType: SnackbarNotificationType.error,
      })
      queue.clear()
      briefcaseDefaultWallPrivacy.value = initialDefaultPrivacy
      captureFetchException(e, { source: 'SettingsBriefcasePermissionsUpdateBriefcaseDefaultPrivacy' })
    }
  }

  async function queueUpdateBriefcaseDefaultPrivacy(wallPrivacy: WallPrivacy): Promise<void> {
    const initialDefaultPrivacy = briefcaseDefaultWallPrivacy.value
    briefcaseDefaultWallPrivacy.value = wallPrivacy
    if (wallPrivacy === WallPrivacy.Private) {
      briefcaseDefaultWallPermission.value = WallPermission.None
    }
    void queue.enqueue(
      'updateBriefcaseDefaultPrivacy',
      async () => await updateBriefcaseDefaultPrivacy(wallPrivacy, initialDefaultPrivacy),
    )
  }

  async function updateBriefcaseDefaultWallPermission(
    permission: WallPermission,
    initialPermission: WallPermission,
  ): Promise<void> {
    const tenantId = tenant.value?.id as number
    try {
      const response = await TenantPermissionsApi.update(tenantId, {
        settings: {
          permissions: { briefcaseDefaultWallPermission: permission },
        },
      })
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings?.permissions
      if (permissions?.briefcaseDefaultWallPermission !== undefined) {
        briefcaseDefaultWallPermission.value = permissions.briefcaseDefaultWallPermission
      }
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating permission.'),
        notificationType: SnackbarNotificationType.error,
      })
      queue.clear()
      briefcaseDefaultWallPermission.value = initialPermission
      captureFetchException(e, { source: 'SettingsBriefcasePermissionsUpdateBriefcaseDefaultWallPermission' })
    }
  }

  async function queueUpdateWallDefaultPermission(wallPermission: WallPermission): Promise<void> {
    const initialDefaultWallPermission = briefcaseDefaultWallPermission.value
    briefcaseDefaultWallPermission.value = wallPermission
    void queue.enqueue(
      'updateBriefcaseDefaultWallPermission',
      async () => await updateBriefcaseDefaultWallPermission(wallPermission, initialDefaultWallPermission),
    )
  }

  return {
    // State
    tenant,
    briefcaseDefaultWallPrivacy,
    briefcaseDefaultWallPermission,

    // Getters
    isBriefcasePermissionsLoading,

    // Actions
    initialize,
    queueUpdateBriefcaseDefaultPrivacy,
    queueUpdateWallDefaultPermission,
  }
})
