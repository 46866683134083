// @file Tenant analytics settings store
import { captureFetchException } from '@@/bits/error_tracker'
import window from '@@/bits/global'
import { parseAnalytics } from '@@/bits/metabase_helper'
import { TenantAnalytics as TenantAnalyticsApi } from '@@/dashboard/padlet_api'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { Tenant, TenantAnalytics, TenantId } from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum TenantAnalyticsSettingsTab {
  General = 'general',
  Padlets = 'padlets',
  Users = 'users',
}

enum TenantAnalyticsStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

const tenantAnalyticsSettingsTabToHash = {
  [TenantAnalyticsSettingsTab.General]: '#general',
  [TenantAnalyticsSettingsTab.Padlets]: '#padlets',
  [TenantAnalyticsSettingsTab.Users]: '#users',
}
const hashToTenantAnalyticsSettingsTab = Object.fromEntries(
  Object.entries(tenantAnalyticsSettingsTabToHash).map(([k, v]) => [v, k as TenantAnalyticsSettingsTab]),
)

const getTenantAnalyticsSettingsTabFromHash = (): TenantAnalyticsSettingsTab => {
  return hashToTenantAnalyticsSettingsTab[window.location.hash] ?? TenantAnalyticsSettingsTab.General
}

export const useSettingsTenantAnalyticsStore = defineStore('settingsTenantAnalytics', () => {
  const darkModeStore = useDarkModeStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  // State
  const analyticsData = ref<TenantAnalytics>({
    orgAnalyticsEmbedLink: '',
    monthlyAnalyticsEmbedLink: '',
    orgUserAnalyticsEmbedLink: '',
  })
  const analyticsStatus = ref<TenantAnalyticsStatus>(TenantAnalyticsStatus.Loading)
  const currentTab = ref<TenantAnalyticsSettingsTab>(getTenantAnalyticsSettingsTabFromHash())
  const tenantId = ref<TenantId>()

  // Getters
  const isErroredTenantAnalytics = computed((): boolean => analyticsStatus.value === TenantAnalyticsStatus.Errored)
  const isLoadingAnalyticsInformation = computed((): boolean => analyticsStatus.value === TenantAnalyticsStatus.Loading)

  // Actions
  async function initialize(tenant?: Tenant): Promise<void> {
    if (tenant === undefined) {
      void globalSnackbarStore.genericFetchError()
      return
    }
    tenantId.value = tenant.id
    await fetchTenantAnalytics()
  }

  async function fetchTenantAnalytics(): Promise<void> {
    analyticsStatus.value = TenantAnalyticsStatus.Loading
    try {
      const response: JsonAPIResponse<TenantAnalytics> = await TenantAnalyticsApi.fetch(tenantId.value as number)
      const data: TenantAnalytics = (response?.data as JsonAPIResource<TenantAnalytics>).attributes
      analyticsData.value = parseAnalytics(data, darkModeStore.isDarkMode) as TenantAnalytics
      analyticsStatus.value = TenantAnalyticsStatus.Completed
    } catch (e) {
      captureFetchException(e, { source: 'TenantAnalyticsFetch' })
      analyticsStatus.value = TenantAnalyticsStatus.Errored
    }
  }

  function setTenantAnalyticsSettingsTabFromHash(): void {
    currentTab.value = getTenantAnalyticsSettingsTabFromHash()
  }

  return {
    // State
    analyticsData,
    currentTab,

    // Getters
    isErroredTenantAnalytics,
    isLoadingAnalyticsInformation,

    // Actions
    fetchTenantAnalytics,
    initialize,
    setTenantAnalyticsSettingsTabFromHash,
  }
})

export { TenantAnalyticsSettingsTab, tenantAnalyticsSettingsTabToHash }
