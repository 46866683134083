/**
 * @file Tenant Info store
 */

import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { modifyCurrentUrl, navigateTo } from '@@/bits/location'
import { MAX_NAME_LENGTH, MAX_TENANT_SUBDOMAIN_LENGTH } from '@@/bits/numbers'
import { NATIVE_HOST } from '@@/bits/url'
import { GoogleAppLicensingSettings, Lti, Saml as SamlApi, Tenant as TenantApi } from '@@/dashboard/padlet_api'
import { ApiErrorCode, SnackbarNotificationType } from '@@/enums'
import renameDark from '@@/images/rename-dark.svg'
import renameLight from '@@/images/rename-light.svg'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { useDashboardSettingsStore } from '@@/pinia/dashboard_settings'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { useGlobalInputDialogStore } from '@@/pinia/global_input_dialog'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type {
  DomainValidation,
  GoogleAppLicensingCreateResponse,
  Id,
  JsonApiData,
  SamlConnectionApiResponse,
  Tenant,
  TenantId,
  TenantParams,
} from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export enum InputStatus {
  Editing,
  Loading,
  Viewing,
  Errored,
}

export enum TenantNameInputStatus {
  Editing,
  Loading,
  Viewing,
  Errored,
}

export enum TenantSubdomainNameInputStatus {
  Editing,
  Loading,
  Viewing,
  Errored,
}

export enum TenantAlternativeEmailDomainInputStatus {
  Editing,
  Loading,
  Viewing,
}

export enum GenerateLinkStatus {
  Initial,
  Loading,
  Completed,
}

export enum ThirdPartyLoginProvider {
  Disabled = 'disabled',
  Google = 'google',
  Microsoft = 'office365',
  Classlink = 'classlink',
  Saml = 'saml',
  Clever = 'clever',
}

export enum UpdateUserGroupSyncStatus {
  Loading = 'Loading',
  Completed = 'Completed',
}

export const DEFAULT_AVATAR_URL = 'https://padlet.net/avatars/tenant_avatar.webp'

export const useSettingsTenantInfoStore = defineStore('settingsTenantInfo', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const dashboardSettingsStore = useDashboardSettingsStore()
  const darkModeStore = useDarkModeStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
  const globalInputDialogStore = useGlobalInputDialogStore()

  // State
  const tenantId = ref<TenantId | null>(null)
  const tenantAvatarUrl = ref<string>('')
  const tenantAvatarUrlInput = ref<string>('')
  const tenantName = ref<string>('')
  const tenantNameInput = ref<string>('')
  const tenantNameInputStatus = ref<TenantNameInputStatus>(TenantNameInputStatus.Viewing)
  const tenantNameValidationMessage = ref<string>('')
  const tenantDomainName = ref<string>('')
  const tenantSubdomainNameInput = ref<string>('')
  const tenantSubdomainNameInputStatus = ref<TenantSubdomainNameInputStatus>(TenantSubdomainNameInputStatus.Viewing)
  const tenantSubdomainNameValidationMessage = ref<string>('')
  const tenantThirdPartyLoginProvider = ref<ThirdPartyLoginProvider>(ThirdPartyLoginProvider.Disabled)
  const classlinkTenantId = ref<string>('')
  const cleverDistrictId = ref<string>('')
  const isTenantAllowThirdPartyLoginOnly = ref<boolean>(false)
  const invalidEmailDomains = ref<string[]>([])
  const tenantOwnerEmailDomain = ref<string>('')
  const isTenantUseCreateAccountsAutomatically = ref<boolean>(false)
  const isBackpack = ref<boolean>(false)
  const tenantAlternativeEmailDomain = ref<string>('')
  const tenantAlternativeEmailDomainInput = ref<string>('')
  const tenantAlternativeEmailDomainInputStatus = ref<TenantAlternativeEmailDomainInputStatus>(
    TenantAlternativeEmailDomainInputStatus.Viewing,
  )
  const ltiAdvantageDynamicRegistrationLink = ref<string>('')
  const ltiAdvantageDynamicRegistrationLinkStatus = ref<GenerateLinkStatus>(GenerateLinkStatus.Initial)
  const ltiLinkDeploymentToken = ref<string>('')
  const ltiLinkDeploymentTokenStatus = ref<GenerateLinkStatus>(GenerateLinkStatus.Initial)
  const ltiEnabled = ref<boolean>(false)
  const lmsUserGroupSyncConfigEnabled = ref<boolean>(false)
  const ltiUserGroupSyncEnabled = ref<boolean>(false)
  const ltiUserGroupSyncEnabledStatus = ref<UpdateUserGroupSyncStatus>(UpdateUserGroupSyncStatus.Completed)
  const isTenantUsingGoogleAppLicensing = ref<boolean>(false)
  const isGoogleAppLicenseLoading = ref<boolean>(false)
  const isTenantUsingExternalRostering = ref<boolean>(false)
  const isTenantUsingOneRoster = ref<boolean>(false)
  const googleAppLicenseData = ref<GoogleAppLicensingCreateResponse>({})

  // Getters
  const tenant = computed(() => dashboardSettingsStore.tenant)
  const tenantSubdomainName = computed<string>(() => tenantDomainName.value.split('.')[0])
  const hasValidEmailDomain = computed<boolean>(() => !invalidEmailDomains.value.includes(tenantOwnerEmailDomain.value))

  // Actions
  function initialize(): void {
    const avatarUrl =
      tenant.value.assets?.home_logo !== undefined && tenant.value.assets.home_logo !== ''
        ? tenant.value.assets.home_logo
        : DEFAULT_AVATAR_URL

    tenantId.value = tenant.value.id
    tenantAvatarUrl.value = avatarUrl
    tenantAvatarUrlInput.value = avatarUrl
    tenantName.value = tenant.value.name
    tenantNameInput.value = tenant.value.name
    tenantNameInputStatus.value = TenantNameInputStatus.Viewing
    tenantNameValidationMessage.value = ''
    tenantThirdPartyLoginProvider.value = getTenantThirdPartyLoginProvider(tenant.value)
    classlinkTenantId.value = tenant.value.classlink_tenant_id ?? ''
    cleverDistrictId.value = tenant.value.clever_district_id ?? ''
    isTenantAllowThirdPartyLoginOnly.value = tenant.value.hide_password_login_if_sso_enabled
    invalidEmailDomains.value = tenant.value.invalid_email_domains ?? []
    tenantOwnerEmailDomain.value = tenant.value.owner_email_domain ?? ''
    isTenantUseCreateAccountsAutomatically.value = tenant.value.auto_create_accounts ?? false
    isBackpack.value = tenant.value.type === 'school'
    tenantDomainName.value = tenant.value.domain_name
    tenantSubdomainNameInput.value = tenantDomainName.value.split('.')[0]
    tenantSubdomainNameInputStatus.value = TenantSubdomainNameInputStatus.Viewing
    tenantSubdomainNameValidationMessage.value = ''
    tenantAlternativeEmailDomain.value = tenant.value.alternate_user_domains ?? ''
    tenantAlternativeEmailDomainInput.value = tenant.value.alternate_user_domains ?? ''
    tenantAlternativeEmailDomainInputStatus.value = TenantAlternativeEmailDomainInputStatus.Viewing
    isTenantUsingGoogleAppLicensing.value = tenant.value.enable_google_app_licensing ?? false
    isTenantUsingExternalRostering.value = tenant.value.enable_external_rostering ?? false
    isTenantUsingOneRoster.value = tenant.value.enable_oneroster ?? false
    googleAppLicenseData.value.orderId = tenant.value.google_app_licensing_order_id ?? ''
    googleAppLicenseData.value.redeemUri = tenant.value.google_app_licensing_redeem_uri ?? ''
    ltiEnabled.value = tenant.value.lti_enabled ?? false
    ltiUserGroupSyncEnabled.value = tenant.value.enable_lti_user_group_sync ?? false
    lmsUserGroupSyncConfigEnabled.value = tenant.value.lms_user_group_sync_config_enabled ?? false

    // SAML login
    if (isBackpack.value) {
      void fetchSamlLoginInfo()
    }
  }

  function getTenantThirdPartyLoginProvider(tenant: Tenant): ThirdPartyLoginProvider {
    if (tenant.enable_saml_login === true) return ThirdPartyLoginProvider.Saml
    if (tenant.enable_google_login === true) return ThirdPartyLoginProvider.Google
    if (tenant.enable_office365_login === true) return ThirdPartyLoginProvider.Microsoft
    if (tenant.enable_classlink_login === true) return ThirdPartyLoginProvider.Classlink
    if (tenant.enable_clever_login === true) return ThirdPartyLoginProvider.Clever
    return ThirdPartyLoginProvider.Disabled
  }

  async function updateTenantAvatarUrl(avatarUrl: string): Promise<void> {
    if (tenantId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      await TenantApi.updateTenantInfo(tenantId.value, {
        assets: { home_logo: avatarUrl },
      })

      tenantAvatarUrl.value = avatarUrl
      tenantAvatarUrlInput.value = avatarUrl
      dashboardSettingsStore.updateCurrentTenant({ assets: { home_logo: avatarUrl } })
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing organization avatar'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateTenantAvatarUrl' })
    }
  }

  function setTenantName(payload: {
    input: string
    inputStatus: TenantNameInputStatus
    validationMessage?: string
  }): void {
    tenantNameInput.value = payload.input
    tenantNameInputStatus.value = payload.inputStatus
    if (payload.validationMessage !== undefined) {
      tenantNameValidationMessage.value = payload.validationMessage
    }
  }

  async function updateTenantName(): Promise<void> {
    if (tenantId.value === null) {
      tenantNameInputStatus.value = TenantNameInputStatus.Errored
      globalSnackbarStore.genericFetchError()
      return
    }

    const trimmedTenantName = tenantNameInput.value.trim()

    if (trimmedTenantName === '') {
      tenantNameInputStatus.value = TenantNameInputStatus.Errored
      tenantNameValidationMessage.value = __('Name can not be blank')
      return
    } else if (trimmedTenantName.length > MAX_NAME_LENGTH) {
      tenantNameInputStatus.value = TenantNameInputStatus.Errored
      tenantNameValidationMessage.value = __('Maximum %{maxLength} characters', { maxLength: MAX_NAME_LENGTH })
      return
    }

    if (trimmedTenantName === tenantName.value) {
      tenantNameInputStatus.value = TenantNameInputStatus.Viewing
      return
    }

    try {
      tenantNameInputStatus.value = TenantNameInputStatus.Loading

      await TenantApi.updateTenantInfo(tenantId.value, {
        name: trimmedTenantName,
      })

      tenantName.value = trimmedTenantName
      tenantNameInput.value = trimmedTenantName

      dashboardSettingsStore.updateCurrentTenant({ name: trimmedTenantName })
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing organization name'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateTenantName' })
    } finally {
      tenantNameInputStatus.value = TenantNameInputStatus.Viewing
    }
  }

  function setTenantSubdomainName(payload: {
    input: string
    inputStatus: TenantSubdomainNameInputStatus
    validationMessage?: string
  }): void {
    tenantSubdomainNameInput.value = payload.input
    tenantSubdomainNameInputStatus.value = payload.inputStatus
    if (payload.validationMessage !== undefined) {
      tenantSubdomainNameValidationMessage.value = payload.validationMessage
    }
  }

  async function updateTenantDomainName(): Promise<void> {
    if (tenantId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      const newDomainName: string = tenantDomainName.value.replace(
        tenantSubdomainName.value,
        tenantSubdomainNameInput.value,
      )

      await TenantApi.updateTenantInfo(tenantId.value, {
        domain_name: newDomainName,
      })

      tenantDomainName.value = newDomainName
      dashboardSettingsStore.updateCurrentTenant({ domain_name: newDomainName })

      navigateTo(modifyCurrentUrl({ host: newDomainName }))
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing web address'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateTenantDomainName' })
      tenantSubdomainNameInput.value = tenantSubdomainName.value
    } finally {
      tenantSubdomainNameInputStatus.value = TenantSubdomainNameInputStatus.Viewing
    }
  }

  async function validateTenantSubdomainName(): Promise<void> {
    if (tenantSubdomainNameInput.value === '') {
      tenantSubdomainNameInputStatus.value = TenantSubdomainNameInputStatus.Errored
      tenantSubdomainNameValidationMessage.value = __('Web address can not be blank')
      return
    } else if (tenantSubdomainNameInput.value.length > MAX_TENANT_SUBDOMAIN_LENGTH) {
      tenantSubdomainNameInputStatus.value = TenantSubdomainNameInputStatus.Errored
      tenantSubdomainNameValidationMessage.value = __('Maximum %{maxLength} characters', {
        maxLength: MAX_TENANT_SUBDOMAIN_LENGTH,
      })
      return
    }

    if (tenantSubdomainNameInput.value === tenantSubdomainName.value) {
      tenantSubdomainNameInputStatus.value = TenantSubdomainNameInputStatus.Viewing
      return
    }

    tenantSubdomainNameInputStatus.value = TenantSubdomainNameInputStatus.Loading

    try {
      const response: DomainValidation = await TenantApi.isSubdomainValid(tenantSubdomainNameInput.value)
      if (!response.result) {
        tenantSubdomainNameInputStatus.value = TenantSubdomainNameInputStatus.Errored

        if (response.code === ApiErrorCode.SUBDOMAIN_ALREADY_IN_USE) {
          tenantSubdomainNameValidationMessage.value = __('This domain is already in use')
        } else {
          tenantSubdomainNameValidationMessage.value = __('Invalid web address')
        }

        return
      }
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing web address'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoValidationTenantSubdomainName' })
      tenantSubdomainNameInput.value = tenantSubdomainName.value
      tenantSubdomainNameInputStatus.value = TenantSubdomainNameInputStatus.Viewing
      return
    }

    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      title: __('Change organization URL?'),
      body: __(
        'Important: please note that changing the address for your organization invalidates all previously shared addresses to padlets and LTI 1.0 links.',
      ),
      confirmButtonText: __('Change'),
      cancelButtonText: __('Nevermind'),
      forceFullWidthButtons: true,
      afterConfirmActions: [updateTenantDomainName],
      afterCancelActions: [
        () => {
          tenantSubdomainNameInput.value = tenantSubdomainName.value
          tenantSubdomainNameInputStatus.value = TenantSubdomainNameInputStatus.Viewing
        },
      ],
    })
  }

  function showInputDialogThirdPartyTenantId(thirdPartyLoginProvider: ThirdPartyLoginProvider): void {
    const thirdPartyIdString =
      thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink
        ? __('ClassLink tenant ID')
        : __('Clever district ID')

    const subtitleText =
      thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink
        ? __(
            'This is available in your admin’s ClassLink Management Console. Read more here: <a id="link-external" href="%{classlinkArticleUrl}" target="_blank" rel="noopener nofollow" class="oz-text-link-light-secondary dark:oz-text-link-dark-secondary break-all">%{classlinkArticleUrl}</a>',
            {
              classlinkArticleUrl: 'https://help.classlink.com/s/article/Management-Console',
            },
          )
        : __(
            'This is available in your admin’s Clever Management Console. Read more here: <a id="link-external" href="%{cleverArticleUrl}" target="_blank" rel="noopener nofollow" class="oz-text-link-light-secondary dark:oz-text-link-dark-secondary break-all">%{cleverArticleUrl}</a>',
            {
              cleverArticleUrl: 'https://support.clever.com/hc/s/articles/000001500?language=en_US',
            },
          )

    globalInputDialogStore.openInputDialog({
      title:
        tenantThirdPartyLoginProvider.value === thirdPartyLoginProvider
          ? __('Edit your ') + thirdPartyIdString
          : __('Add your ') + thirdPartyIdString,
      subtitle: subtitleText,
      inputPlaceholder: __('E.g. %{tenantIdExample}', { tenantIdExample: 351800 }),
      inputLabel: thirdPartyIdString,
      inputValue:
        thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink
          ? classlinkTenantId.value
          : cleverDistrictId.value,
      // Classlink tenant ID is 6 characters clever district ID is 20-25
      inputMaxLength: thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink ? 6 : 25,
      iconSrc: darkModeStore.isDarkMode ? renameDark : renameLight,
      inputAriaLabel: __('Enter your ') + thirdPartyIdString,
      submitButtonText: tenantThirdPartyLoginProvider.value === thirdPartyLoginProvider ? __('Save') : __('Add'),
      submitActions: [
        ({ inputValue }): void => {
          void updateTenantThirdPartyLoginProvider(thirdPartyLoginProvider, inputValue)
        },
      ],
      validationActions: [
        ({ inputValue }) => {
          const isContainsZeroOrMoreNumbersOnly: boolean = /^[0-9]*$/.test(inputValue)
          const isContainsAlphaNumericOnly: boolean = /^[0-9a-zA-Z]*$/.test(inputValue)
          const validationFunction =
            thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink
              ? isContainsZeroOrMoreNumbersOnly
              : isContainsAlphaNumericOnly

          globalInputDialogStore.setInputIsValid(validationFunction)
          globalInputDialogStore.setValidationMessage(
            validationFunction
              ? ''
              : thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink
              ? __('Invalid tenant ID')
              : __('Invalid district ID'),
          )
          globalInputDialogStore.setSubmitButtonDisable(!validationFunction || inputValue.length === 0)
        },
      ],
    })
  }

  function showConfirmationDialogueGoogleAppLicensing(): void {
    globalConfirmationDialogStore.openConfirmationDialog({
      title: __('Enable Google App Licensing?'),
      body: __(
        'Users in %{tenantName} will be signed out and will not be able to sign back in until you turn Google App Licensing off or assign them a license in the Google admin panel',
        { tenantName: tenantName.value },
      ),
      confirmButtonText: __('Confirm'),
      afterConfirmActions: [showExistingTenantsConfirmationDialog],
    })
  }

  async function showExistingTenantsConfirmationDialog(): Promise<void> {
    const response = await GoogleAppLicensingSettings.checkDomainForExistingTenants()
    const tenantNames = (response.data as JsonAPIResource<string[]>).attributes

    if (!(tenantNames?.length > 0)) {
      void updateAllowGoogleAppLicensing(true)
      return
    }

    globalConfirmationDialogStore.openConfirmationDialog({
      title: __('Enable Google App Licensing?'),
      body:
        __('Enabling App licensing will also give users access to the following domain(s): ') + tenantNames.join(', '),
      confirmButtonText: __('Confirm'),
      afterConfirmActions: [
        () => {
          void updateAllowGoogleAppLicensing(true)
        },
      ],
    })
  }

  async function openRosteringDisableConfirmationDialog(
    thirdPartyLoginProvider: ThirdPartyLoginProvider,
  ): Promise<void> {
    if (tenantId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }
    globalConfirmationDialogStore.openConfirmationDialog({
      title: __('Change SSO Provider?'),
      body: __(
        'You currently have %{provider} rostering enabled. Changing your SSO provider will disable rostering for this organization. To re-enable rostering contact us at %{support_email}',
        {
          provider:
            tenantThirdPartyLoginProvider.value[0].toUpperCase() + tenantThirdPartyLoginProvider.value.substring(1),
          support_email: 'hello@padlet.com',
        },
      ),
      confirmButtonText: __('Confirm'),
      afterConfirmActions: [
        async () => {
          if (
            thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink ||
            thirdPartyLoginProvider === ThirdPartyLoginProvider.Clever
          ) {
            await showInputDialogThirdPartyTenantId(thirdPartyLoginProvider)
          } else {
            await updateTenantThirdPartyLoginProvider(thirdPartyLoginProvider)
          }
        },
      ],
    })
  }

  async function updateTenantThirdPartyLoginProvider(
    thirdPartyLoginProvider: ThirdPartyLoginProvider,
    tenantThirdPartyIdInput?: string,
  ): Promise<void> {
    if (tenantId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    // Early return if the third party login provider has not changed and
    // using classlink and classlink id has not changed
    // using clever and clever id has not changed
    if (thirdPartyLoginProvider === tenantThirdPartyLoginProvider.value) {
      if (
        thirdPartyLoginProvider !== ThirdPartyLoginProvider.Classlink &&
        thirdPartyLoginProvider !== ThirdPartyLoginProvider.Clever
      )
        return
      if (
        thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink &&
        tenantThirdPartyIdInput === classlinkTenantId.value
      )
        return
      if (
        thirdPartyLoginProvider === ThirdPartyLoginProvider.Clever &&
        tenantThirdPartyIdInput === cleverDistrictId.value
      )
        return
    }

    try {
      const params: Partial<TenantParams> = {
        enable_google_login: thirdPartyLoginProvider === ThirdPartyLoginProvider.Google,
        enable_office365_login: thirdPartyLoginProvider === ThirdPartyLoginProvider.Microsoft,
        enable_classlink_login: thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink,
        enable_clever_login: thirdPartyLoginProvider === ThirdPartyLoginProvider.Clever,
      }

      switch (thirdPartyLoginProvider) {
        case ThirdPartyLoginProvider.Disabled:
          params.hide_password_login_if_sso_enabled = false
          break
        case ThirdPartyLoginProvider.Classlink:
          params.classlink_tenant_id = tenantThirdPartyIdInput
          break
        case ThirdPartyLoginProvider.Clever:
          params.clever_district_id = tenantThirdPartyIdInput
          break
        default:
          break
      }

      await TenantApi.updateTenantInfo(tenantId.value, params)

      tenantThirdPartyLoginProvider.value = thirdPartyLoginProvider
      isTenantAllowThirdPartyLoginOnly.value =
        params.hide_password_login_if_sso_enabled ?? isTenantAllowThirdPartyLoginOnly.value

      if (thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink) {
        classlinkTenantId.value = tenantThirdPartyIdInput ?? ''
      }

      if (thirdPartyLoginProvider === ThirdPartyLoginProvider.Clever) {
        cleverDistrictId.value = tenantThirdPartyIdInput ?? ''
      }

      dashboardSettingsStore.updateCurrentTenant({
        enable_google_login: thirdPartyLoginProvider === ThirdPartyLoginProvider.Google,
        enable_office365_login: thirdPartyLoginProvider === ThirdPartyLoginProvider.Microsoft,
        enable_classlink_login: thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink,
        enable_clever_login: thirdPartyLoginProvider === ThirdPartyLoginProvider.Clever,
        hide_password_login_if_sso_enabled:
          thirdPartyLoginProvider === ThirdPartyLoginProvider.Disabled ? false : isTenantAllowThirdPartyLoginOnly.value,
        enable_google_app_licensing: false,
      })

      isTenantUsingGoogleAppLicensing.value = false
      isTenantUsingExternalRostering.value = false
      isTenantUsingOneRoster.value = false
    } catch (e) {
      if (thirdPartyLoginProvider === ThirdPartyLoginProvider.Classlink && e.status === 409) {
        globalSnackbarStore.setSnackbar({
          message: __('Sorry, that Classlink Tenant ID is already in use'),
          notificationType: SnackbarNotificationType.error,
        })
      } else if (thirdPartyLoginProvider === ThirdPartyLoginProvider.Clever && e.status === 409) {
        globalSnackbarStore.setSnackbar({
          message: __('Sorry, that Clever District ID is already in use'),
          notificationType: SnackbarNotificationType.error,
        })
      } else {
        globalSnackbarStore.setSnackbar({
          message: __('Error changing third party log in'),
          notificationType: SnackbarNotificationType.error,
        })
      }
      captureFetchException(e, { source: 'TenantInfoUpdateThirdPartyLoginProvider' })
    }
  }

  async function updateAllowThirdPartyLoginOnly(isAllowThirdPartyLoginOnly: boolean): Promise<void> {
    if (tenantId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      isTenantAllowThirdPartyLoginOnly.value = isAllowThirdPartyLoginOnly

      await TenantApi.updateTenantInfo(tenantId.value, {
        hide_password_login_if_sso_enabled: isAllowThirdPartyLoginOnly,
      })
      dashboardSettingsStore.updateCurrentTenant({ hide_password_login_if_sso_enabled: isAllowThirdPartyLoginOnly })
    } catch (e) {
      isTenantAllowThirdPartyLoginOnly.value = !isAllowThirdPartyLoginOnly

      globalSnackbarStore.setSnackbar({
        message: __('Error setting third party log in'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateAllowThirdPartyLoginOnly' })
    }
  }

  function handleGoogleAppLicensingError(errorMessage: string): void {
    isGoogleAppLicenseLoading.value = false
    isTenantUsingGoogleAppLicensing.value = false
    googleAppLicenseData.value = { orderId: '', redeemUri: '' }
    globalSnackbarStore.setSnackbar({
      message: errorMessage,
      notificationType: SnackbarNotificationType.error,
    })
  }

  async function updateAllowGoogleAppLicensing(useGoogleAppLicensing: boolean): Promise<void> {
    if (tenantId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }
    isTenantUsingGoogleAppLicensing.value = useGoogleAppLicensing

    if (useGoogleAppLicensing) {
      try {
        await updateAllowCreateAccountsAutomatically(true)
      } catch {
        handleGoogleAppLicensingError(__('Error setting up app licensing. Please try again.'))
      }
    }

    if (
      !useGoogleAppLicensing ||
      (googleAppLicenseData.value.orderId !== '' && googleAppLicenseData.value.redeemUri !== '')
    ) {
      await TenantApi.updateTenantInfo(tenantId.value, {
        enable_google_app_licensing: useGoogleAppLicensing,
      })
      dashboardSettingsStore.updateCurrentTenant({ enable_google_app_licensing: useGoogleAppLicensing })
      return
    }

    isGoogleAppLicenseLoading.value = true

    GoogleAppLicensingSettings.createLicenses()
      .then(async (response) => {
        if (tenantId.value === null) {
          globalSnackbarStore.genericFetchError()
          return
        }

        const licenseData = (response.data as JsonAPIResource<GoogleAppLicensingCreateResponse>).attributes

        try {
          await TenantApi.updateTenantInfo(tenantId.value, {
            enable_google_app_licensing: useGoogleAppLicensing,
            google_app_licensing_order_id: licenseData.orderId,
            google_app_licensing_redeem_uri: licenseData.redeemUri,
          })
          dashboardSettingsStore.updateCurrentTenant({
            enable_google_app_licensing: useGoogleAppLicensing,
            google_app_licensing_order_id: licenseData.orderId,
            google_app_licensing_redeem_uri: licenseData.redeemUri,
          })
        } catch (e) {
          handleGoogleAppLicensingError(__('Error setting up app licensing. Please try again.'))
          captureFetchException(e, { source: 'GoogleAppLicenseSettingsUpdateUseGoogleAppLicensing' })
          return
        }

        isGoogleAppLicenseLoading.value = false
        googleAppLicenseData.value = licenseData
      })
      .catch((e) => {
        const parsedErrorMessage = JSON.parse(e.message)
        const error = parsedErrorMessage.errors[0]
        handleGoogleAppLicensingError(error?.title)
        captureFetchException(error, { source: 'TenantInfoUpdateAllowGoogleAppLicensing' })
      })
  }

  async function updateAllowCreateAccountsAutomatically(toggleValue: boolean): Promise<void> {
    if (tenantId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      isTenantUseCreateAccountsAutomatically.value = toggleValue

      await TenantApi.updateTenantInfo(tenantId.value, {
        auto_create_accounts: toggleValue,
      })
      dashboardSettingsStore.updateCurrentTenant({ auto_create_accounts: toggleValue })
    } catch (e) {
      isTenantUseCreateAccountsAutomatically.value = !toggleValue

      globalSnackbarStore.setSnackbar({
        message: __('Unable to set'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateAllowCreateAccountsAutomatically' })
    }
  }

  function setAlternativeEmailDomain(payload: {
    input: string
    inputStatus: TenantAlternativeEmailDomainInputStatus
  }): void {
    tenantAlternativeEmailDomainInput.value = payload.input
    tenantAlternativeEmailDomainInputStatus.value = payload.inputStatus
  }

  async function updateAlternativeEmailDomain(): Promise<void> {
    if (tenantId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    const trimmedTenantAlternativeEmailDomain: string = tenantAlternativeEmailDomainInput.value
      .split(',')
      .map((emailDomain) => {
        const trimmedEmailDomain = emailDomain.trim()
        return trimmedEmailDomain.split('@').length === 2 ? trimmedEmailDomain.split('@')[1] : trimmedEmailDomain
      })
      .join(', ')

    if (trimmedTenantAlternativeEmailDomain === tenantAlternativeEmailDomain.value) {
      tenantAlternativeEmailDomainInputStatus.value = TenantAlternativeEmailDomainInputStatus.Viewing
      return
    }

    try {
      tenantAlternativeEmailDomainInputStatus.value = TenantAlternativeEmailDomainInputStatus.Loading

      const tenantResponse: Tenant = await TenantApi.updateTenantInfo(tenantId.value, {
        alternate_user_domains: trimmedTenantAlternativeEmailDomain,
      })

      tenantAlternativeEmailDomain.value = tenantResponse.alternate_user_domains ?? ''
      tenantAlternativeEmailDomainInput.value = tenantResponse.alternate_user_domains ?? ''
      dashboardSettingsStore.updateCurrentTenant({
        alternate_user_domains: tenantResponse.alternate_user_domains ?? '',
      })
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Unable to set'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateAlternativeEmailDomain' })
    } finally {
      tenantAlternativeEmailDomainInputStatus.value = TenantAlternativeEmailDomainInputStatus.Viewing
    }
  }

  async function generateLtiAdvantageDynamicRegistrationLink(): Promise<void> {
    if (tenantId.value === null) {
      void globalSnackbarStore.genericFetchError()
      return
    }

    try {
      ltiAdvantageDynamicRegistrationLinkStatus.value = GenerateLinkStatus.Loading

      const response = await Lti.generateLtiDynamicRegistrationUrl(tenantId.value)
      ltiAdvantageDynamicRegistrationLink.value = response.url
      ltiAdvantageDynamicRegistrationLinkStatus.value = GenerateLinkStatus.Completed

      globalSnackbarStore.setSnackbar({
        message: __('LTI link generated'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      ltiAdvantageDynamicRegistrationLinkStatus.value = GenerateLinkStatus.Initial

      globalSnackbarStore.setSnackbar({
        message: __('Error generating LTI link'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoGenerateLtiAdvantageDynamicRegistrationLink' })
    }
  }

  async function generateLtiLinkDeploymentToken(): Promise<void> {
    if (tenantId.value === null) {
      void globalSnackbarStore.genericFetchError()
      return
    }

    try {
      ltiLinkDeploymentTokenStatus.value = GenerateLinkStatus.Loading

      const response = await Lti.generateLtiLinkDeploymentToken(tenantId.value)
      ltiLinkDeploymentToken.value = response.token
      ltiLinkDeploymentTokenStatus.value = GenerateLinkStatus.Completed

      globalSnackbarStore.setSnackbar({
        message: __('LTI token generated'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      ltiLinkDeploymentTokenStatus.value = GenerateLinkStatus.Initial

      globalSnackbarStore.setSnackbar({
        message: __('Error generating LTI link token'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoGenerateLtiLinkDeploymentToken' })
    }
  }

  async function setLtiUserGroupSyncEnabled(value: boolean): Promise<void> {
    if (tenantId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      ltiUserGroupSyncEnabledStatus.value = UpdateUserGroupSyncStatus.Loading

      await Lti.updateLtiUserGroupSyncEnabled(tenantId.value, value)

      ltiUserGroupSyncEnabled.value = value

      globalSnackbarStore.setSnackbar({
        message: value ? __('User Group Sync enabled') : __('User Group Sync disabled'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      ltiUserGroupSyncEnabled.value = !value
      captureFetchException(e, { source: 'TenantInfoUserGroupSync' })
    } finally {
      ltiUserGroupSyncEnabledStatus.value = UpdateUserGroupSyncStatus.Completed
    }
  }

  // SAML login

  const samlConnectionId = ref<Id | null>(null)
  const xSamlLoginSection = ref<boolean>(false)
  const samlSpEntityId = ref<string | null>(null) // It looks like a URL, e.g. https://padlet.dev/auth/saml/sp/2476480

  const hasSamlLoginRegistered = computed<boolean>(() => samlConnectionId.value != null)
  const tenantIdWithEpoch = computed<string>(() => {
    if (hasSamlLoginRegistered.value && samlSpEntityId.value === null) return ''
    // <tenant_id><epoch time in hours>
    return samlSpEntityId.value != null
      ? samlSpEntityId.value.substring(samlSpEntityId.value.lastIndexOf('/') + 1)
      : `${String(tenantId.value)}${Math.floor(Date.now() / 3600000)}`
  })
  const samlSPMetadataUrl = computed<string>(() => {
    // Before the launch of the SAML setup self-serve tool, most SAML connections did not have a samlSpEntityId,
    // and identity providers used https://padlet.com/auth/saml/metadata on their end. Refer to https://github.com/padlet/mozart/pull/16797.
    // After the launch, identity providers will use the URL format: https://padlet.com/auth/saml/metadata/<tenant_id><epoch time in hours>
    return `https://${NATIVE_HOST}/auth/saml/metadata/${tenantIdWithEpoch.value}`
  })

  function toggleSamlLoginSection(): void {
    xSamlLoginSection.value = !xSamlLoginSection.value
  }

  const samlIdpMetadataUrl = ref<string>('')
  const samlIdpMetadataUrlInput = ref<string>('')
  const samlIdpMetadataUrlValidationMessage = ref<string>('')
  const samlIdpMetadataUrlInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlIdpMetadataUrl(payload: {
    input: string
    inputStatus: InputStatus
    validationMessage?: string
  }): void {
    samlIdpMetadataUrlInput.value = payload.input
    samlIdpMetadataUrlInputStatus.value = payload.inputStatus
    if (payload.validationMessage !== undefined) {
      samlIdpMetadataUrlValidationMessage.value = payload.validationMessage
    }
  }

  async function updateSamlIdpMetadataUrl(): Promise<void> {
    const trimmedSamlIdpMetadataUrl = samlIdpMetadataUrlInput.value.trim()

    if (trimmedSamlIdpMetadataUrl === '') {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Errored
      samlIdpMetadataUrlValidationMessage.value = __('IdP metadata URL can not be blank')
      return
    }

    if (trimmedSamlIdpMetadataUrl === samlIdpMetadataUrl.value) {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      // TODO: Add API call to update SAML settings
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing IdP metadata URL'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateSamlIdpMetadataUrl' })
    } finally {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Viewing
    }
  }

  const samlEmailAttribute = ref<string>('')
  const samlEmailAttributeInput = ref<string>('')
  const samlEmailAttributeValidationMessage = ref<string>('')
  const samlEmailAttributeInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlEmailAttribute(payload: {
    input: string
    inputStatus: InputStatus
    validationMessage?: string
  }): void {
    samlEmailAttributeInput.value = payload.input
    samlEmailAttributeInputStatus.value = payload.inputStatus
    if (payload.validationMessage !== undefined) {
      samlEmailAttributeValidationMessage.value = payload.validationMessage
    }
  }

  async function updateSamlEmailAttribute(): Promise<void> {
    const trimmedSamlEmailAttribute = samlEmailAttributeInput.value.trim()

    if (trimmedSamlEmailAttribute === '') {
      samlEmailAttributeInputStatus.value = InputStatus.Errored
      samlEmailAttributeValidationMessage.value = __('Email attribute can not be blank')
      return
    }

    if (trimmedSamlEmailAttribute === samlEmailAttribute.value) {
      samlEmailAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      await SamlApi.updateSamlConnection(samlConnectionId.value as Id, { emailAttribute: trimmedSamlEmailAttribute })
      samlEmailAttribute.value = trimmedSamlEmailAttribute
      samlEmailAttributeInput.value = trimmedSamlEmailAttribute
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing email attribute.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateSamlEmailAttribute' })
    } finally {
      samlEmailAttributeInputStatus.value = InputStatus.Viewing
    }
  }

  const samlNameAttribute = ref<string>('')
  const samlNameAttributeInput = ref<string>('')
  const samlNameAttributeValidationMessage = ref<string>('')
  const samlNameAttributeInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlNameAttribute(payload: {
    input: string
    inputStatus: InputStatus
    validationMessage?: string
  }): void {
    samlNameAttributeInput.value = payload.input
    samlNameAttributeInputStatus.value = payload.inputStatus
    if (payload.validationMessage !== undefined) {
      samlNameAttributeValidationMessage.value = payload.validationMessage
    }
  }

  async function updateSamlNameAttribute(): Promise<void> {
    const trimmedSamlNameAttribute = samlNameAttributeInput.value.trim()

    if (trimmedSamlNameAttribute === '') {
      samlNameAttributeInputStatus.value = InputStatus.Errored
      samlNameAttributeValidationMessage.value = __('Name attribute can not be blank')
      return
    }

    if (trimmedSamlNameAttribute === samlNameAttribute.value) {
      samlNameAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      await SamlApi.updateSamlConnection(samlConnectionId.value as Id, { nameAttribute: trimmedSamlNameAttribute })
      samlNameAttribute.value = trimmedSamlNameAttribute
      samlNameAttributeInput.value = trimmedSamlNameAttribute
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing name attribute.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateSamlNameAttribute' })
    } finally {
      samlNameAttributeInputStatus.value = InputStatus.Viewing
    }
  }

  const samlRoleAttribute = ref<string>('')
  const samlRoleAttributeInput = ref<string>('')
  const samlRoleAttributeInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlRoleAttribute(payload: { input: string; inputStatus: InputStatus }): void {
    samlRoleAttributeInput.value = payload.input
    samlRoleAttributeInputStatus.value = payload.inputStatus
  }

  async function updateSamlRoleAttribute(): Promise<void> {
    const trimmedSamlRoleAttribute = samlRoleAttributeInput.value.trim()

    if (trimmedSamlRoleAttribute === samlRoleAttribute.value) {
      samlRoleAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      await SamlApi.updateSamlConnection(samlConnectionId.value as Id, { roleAttribute: trimmedSamlRoleAttribute })
      samlRoleAttribute.value = trimmedSamlRoleAttribute
      samlRoleAttributeInput.value = trimmedSamlRoleAttribute
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing role attribute.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateSamlRoleAttribute' })
    } finally {
      samlRoleAttributeInputStatus.value = InputStatus.Viewing
    }
  }

  const samlConnectionName = ref<string>('')
  const samlConnectionNameInput = ref<string>('')
  const samlConnectionNameInputStatus = ref<InputStatus>(InputStatus.Viewing)

  function setSamlConnectionName(payload: { input: string; inputStatus: InputStatus }): void {
    samlConnectionNameInput.value = payload.input
    samlConnectionNameInputStatus.value = payload.inputStatus
  }

  async function updateSamlConnectionName(): Promise<void> {
    const trimmedSamlConnectionName = samlConnectionNameInput.value.trim()

    if (trimmedSamlConnectionName === samlConnectionName.value) {
      samlConnectionNameInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      await SamlApi.updateSamlConnection(samlConnectionId.value as Id, { connectionName: trimmedSamlConnectionName })
      samlConnectionName.value = trimmedSamlConnectionName
      samlConnectionNameInput.value = trimmedSamlConnectionName
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing login text.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateSamlConnectionName' })
    } finally {
      samlConnectionNameInputStatus.value = InputStatus.Viewing
    }
  }

  const isSamlDevModeEnabled = ref<boolean>(true)

  function setSamlDevMode(value: boolean): void {
    const title = value ? __('Enable Dev mode?') : __('Disable Dev mode?')
    const body = value
      ? __('Enabling dev mode will reset your login method to email only. Are you sure you want to proceed?')
      : __('Disabling dev mode will launch SAML login to all users. Are you sure you want to proceed?')
    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      title,
      body,
      confirmButtonText: __('Change'),
      cancelButtonText: __('Nevermind'),
      forceFullWidthButtons: true,
      afterConfirmActions: [async () => await updateSamlDevMode(value)],
    })
  }

  async function updateSamlDevMode(isEnabled: boolean): Promise<void> {
    try {
      isSamlDevModeEnabled.value = isEnabled
      await SamlApi.updateSamlConnection(samlConnectionId.value as Id, { devMode: isEnabled })
      if (isEnabled) {
        await updateTenantThirdPartyLoginProvider(ThirdPartyLoginProvider.Disabled)
        dashboardSettingsStore.updateCurrentTenant({ enable_saml_login: false })
      } else {
        await updateTenantThirdPartyLoginProvider(ThirdPartyLoginProvider.Saml)
      }
    } catch (e) {
      isSamlDevModeEnabled.value = !isEnabled

      globalSnackbarStore.setSnackbar({
        message: __('Error updating dev mode.'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoUpdateSamlDevMode' })
    }
  }

  async function fetchSamlLoginInfo(): Promise<void> {
    try {
      const response = await SamlApi.fetchSamlConnections()
      const samlConnections = response.data as Array<JsonApiData<SamlConnectionApiResponse>>

      if (samlConnections.length !== 0) {
        // A backpack tenant can have multiple SAML connections, but we only support one for now
        const samlConnection = samlConnections[0].attributes

        samlConnectionId.value = samlConnection.id
        samlIdpMetadataUrl.value = samlConnection.idpMetadataUrl
        samlEmailAttribute.value = samlConnection.emailAttribute
        samlEmailAttributeInput.value = samlConnection.emailAttribute
        samlNameAttribute.value = samlConnection.nameAttribute
        samlNameAttributeInput.value = samlConnection.nameAttribute
        samlRoleAttribute.value = samlConnection.roleAttribute
        samlRoleAttributeInput.value = samlConnection.roleAttribute
        samlConnectionName.value = samlConnection.connectionName
        samlConnectionNameInput.value = samlConnection.connectionName
        isSamlDevModeEnabled.value = samlConnection.devMode
        samlSpEntityId.value = samlConnection.spEntityId
        xSamlLoginSection.value = true
      } else {
        samlConnectionName.value = 'SAML'
        samlConnectionNameInput.value = 'SAML'
        samlIdpMetadataUrlValidationMessage.value = __('IdP metadata URL can not be blank')
        samlIdpMetadataUrlInputStatus.value = InputStatus.Errored
        samlEmailAttributeValidationMessage.value = __('Email attribute can not be blank')
        samlEmailAttributeInputStatus.value = InputStatus.Errored
        samlNameAttributeValidationMessage.value = __('Name attribute can not be blank')
        samlNameAttributeInputStatus.value = InputStatus.Errored
      }
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error fetching SAML login info'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoFetchSamlLoginInfo' })
    }
  }

  const isAwaitingCreateSamlConnectionResponse = ref<boolean>(false)

  async function createSamlConnection(): Promise<void> {
    const trimmedSamlIdpMetadataUrl = samlIdpMetadataUrlInput.value.trim()

    if (trimmedSamlIdpMetadataUrl === '') {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Errored
      samlIdpMetadataUrlValidationMessage.value = __('IdP metadata URL can not be blank')
      return
    }

    if (trimmedSamlIdpMetadataUrl === samlIdpMetadataUrl.value) {
      samlIdpMetadataUrlInputStatus.value = InputStatus.Viewing
      return
    }

    const trimmedSamlEmailAttribute = samlEmailAttributeInput.value.trim()

    if (trimmedSamlEmailAttribute === '') {
      samlEmailAttributeInputStatus.value = InputStatus.Errored
      samlEmailAttributeValidationMessage.value = __('Email attribute can not be blank')
      return
    }

    if (trimmedSamlEmailAttribute === samlEmailAttribute.value) {
      samlEmailAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    const trimmedSamlNameAttribute = samlNameAttributeInput.value.trim()

    if (trimmedSamlNameAttribute === '') {
      samlNameAttributeInputStatus.value = InputStatus.Errored
      samlNameAttributeValidationMessage.value = __('Name attribute can not be blank')
      return
    }

    if (trimmedSamlNameAttribute === samlNameAttribute.value) {
      samlNameAttributeInputStatus.value = InputStatus.Viewing
      return
    }

    try {
      isAwaitingCreateSamlConnectionResponse.value = true
      const response = await SamlApi.createSamlConnection({
        idpMetadataUrl: trimmedSamlIdpMetadataUrl,
        emailAttribute: trimmedSamlEmailAttribute,
        nameAttribute: trimmedSamlNameAttribute,
        roleAttribute: samlRoleAttributeInput.value.trim(),
        connectionName: samlConnectionNameInput.value.trim() !== '' ? samlConnectionNameInput.value.trim() : 'SAML',
        devMode: isSamlDevModeEnabled.value,
        prespecifiedSpEntityId: `https://${NATIVE_HOST}/auth/saml/sp/${tenantIdWithEpoch.value}`,
      })
      const samlConnection = (response.data as Array<JsonApiData<SamlConnectionApiResponse>>)[0].attributes

      samlConnectionId.value = samlConnection.id
      samlIdpMetadataUrl.value = samlConnection.idpMetadataUrl
      samlEmailAttribute.value = samlConnection.emailAttribute
      samlEmailAttributeInput.value = samlConnection.emailAttribute
      samlNameAttribute.value = samlConnection.nameAttribute
      samlNameAttributeInput.value = samlConnection.nameAttribute
      samlRoleAttribute.value = samlConnection.roleAttribute
      samlRoleAttributeInput.value = samlConnection.roleAttribute
      samlConnectionName.value = samlConnection.connectionName
      samlConnectionNameInput.value = samlConnection.connectionName
      isSamlDevModeEnabled.value = samlConnection.devMode

      globalSnackbarStore.setSnackbar({
        message: __('SAML connection created.'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error creating SAML connection'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'TenantInfoCreateSamlConnection' })
    } finally {
      isAwaitingCreateSamlConnectionResponse.value = false
    }
  }

  return {
    // State
    tenant,
    tenantId,
    tenantAvatarUrl,
    tenantAvatarUrlInput,
    tenantName,
    tenantNameInput,
    tenantNameInputStatus,
    tenantNameValidationMessage,
    tenantSubdomainNameInput,
    tenantSubdomainNameInputStatus,
    tenantSubdomainNameValidationMessage,
    tenantThirdPartyLoginProvider,
    classlinkTenantId,
    cleverDistrictId,
    isTenantAllowThirdPartyLoginOnly,
    tenantOwnerEmailDomain,
    isTenantUseCreateAccountsAutomatically,
    isBackpack,
    tenantAlternativeEmailDomain,
    tenantAlternativeEmailDomainInput,
    tenantAlternativeEmailDomainInputStatus,
    ltiAdvantageDynamicRegistrationLink,
    ltiAdvantageDynamicRegistrationLinkStatus,
    ltiLinkDeploymentToken,
    ltiLinkDeploymentTokenStatus,
    isTenantUsingGoogleAppLicensing,
    isGoogleAppLicenseLoading,
    isTenantUsingExternalRostering,
    isTenantUsingOneRoster,
    googleAppLicenseData,
    ltiEnabled,
    ltiUserGroupSyncEnabled,
    ltiUserGroupSyncEnabledStatus,
    lmsUserGroupSyncConfigEnabled,

    // Getters
    tenantSubdomainName,
    hasValidEmailDomain,

    // Actions
    initialize,
    updateTenantAvatarUrl,
    setTenantName,
    updateTenantName,
    setTenantSubdomainName,
    validateTenantSubdomainName,
    updateTenantThirdPartyLoginProvider,
    showConfirmationDialogueGoogleAppLicensing,
    showInputDialogThirdPartyTenantId,
    updateAllowThirdPartyLoginOnly,
    updateAllowGoogleAppLicensing,
    updateAllowCreateAccountsAutomatically,
    setAlternativeEmailDomain,
    updateAlternativeEmailDomain,
    generateLtiAdvantageDynamicRegistrationLink,
    generateLtiLinkDeploymentToken,
    openRosteringDisableConfirmationDialog,

    // SAML login

    hasSamlLoginRegistered,
    samlSPMetadataUrl,
    xSamlLoginSection,
    isAwaitingCreateSamlConnectionResponse,
    createSamlConnection,
    toggleSamlLoginSection,

    samlIdpMetadataUrl,
    samlIdpMetadataUrlInput,
    samlIdpMetadataUrlValidationMessage,
    samlIdpMetadataUrlInputStatus,
    setSamlIdpMetadataUrl,
    updateSamlIdpMetadataUrl,

    samlEmailAttribute,
    samlEmailAttributeInput,
    samlEmailAttributeValidationMessage,
    samlEmailAttributeInputStatus,
    setSamlEmailAttribute,
    updateSamlEmailAttribute,

    samlNameAttribute,
    samlNameAttributeInput,
    samlNameAttributeValidationMessage,
    samlNameAttributeInputStatus,
    setSamlNameAttribute,
    updateSamlNameAttribute,

    samlRoleAttribute,
    samlRoleAttributeInput,
    samlRoleAttributeInputStatus,
    setSamlRoleAttribute,
    updateSamlRoleAttribute,

    samlConnectionName,
    samlConnectionNameInput,
    samlConnectionNameInputStatus,
    setSamlConnectionName,
    updateSamlConnectionName,

    isSamlDevModeEnabled,
    setSamlDevMode,
    updateSamlDevMode,

    // LTI
    setLtiUserGroupSyncEnabled,
  }
})
