// @file Backpack permissions settings store
import window from '@@/bits/global'
import { useSettingsBackpackPermissionsStudentsStore } from '@@/pinia/settings_backpack_permissions_students'
import { useSettingsBackpackPermissionsTeachersStore } from '@@/pinia/settings_backpack_permissions_teachers'
import type { Tenant } from '@@/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'

enum BackpackPermissionsSettingsTab {
  Students = 'students',
  Teachers = 'teachers',
}

const backpackPermissionsSettingsTabToHash = {
  [BackpackPermissionsSettingsTab.Students]: '#students',
  [BackpackPermissionsSettingsTab.Teachers]: '#teachers',
}
const hashToBackpackPermissionsSettingsTab = Object.fromEntries(
  Object.entries(backpackPermissionsSettingsTabToHash).map(([k, v]) => [v, k as BackpackPermissionsSettingsTab]),
)

const getBackpackPermissionsSettingsTabFromHash = (): BackpackPermissionsSettingsTab => {
  return hashToBackpackPermissionsSettingsTab[window.location.hash] ?? BackpackPermissionsSettingsTab.Students
}

export const useSettingsBackpackPermissionsStore = defineStore('settingsBackpackPermissions', () => {
  const settingsBackpackPermissionsStudentsStore = useSettingsBackpackPermissionsStudentsStore()
  const settingsBackpackPermissionsTeachersStore = useSettingsBackpackPermissionsTeachersStore()

  // State
  const currentTab = ref<BackpackPermissionsSettingsTab>(getBackpackPermissionsSettingsTabFromHash())
  const visitedTabs = ref<Record<BackpackPermissionsSettingsTab, boolean>>({
    [BackpackPermissionsSettingsTab.Students]: false,
    [BackpackPermissionsSettingsTab.Teachers]: false,
  })
  const tenant = ref<Tenant>()

  // Getters

  // Actions
  function initialize(tenantObject?: Tenant): void {
    tenant.value = tenantObject
    initializeTab()
  }

  function initializeTab(): void {
    visitedTabs.value[currentTab.value] = true
    switch (currentTab.value) {
      case BackpackPermissionsSettingsTab.Teachers:
        void settingsBackpackPermissionsTeachersStore.initialize(tenant.value)
        break
      case BackpackPermissionsSettingsTab.Students:
      default:
        void settingsBackpackPermissionsStudentsStore.initialize(tenant.value)
    }
  }

  function setBackpackPermissionsSettingsTabFromHash(): void {
    currentTab.value = getBackpackPermissionsSettingsTabFromHash()

    // We check if the currentTab is visited,
    // if it has not been visited we will initialize the tab's store
    // and set the tab as visited.
    if (!visitedTabs.value[currentTab.value]) {
      initializeTab()
    }
  }

  return {
    // State
    currentTab,
    visitedTabs,

    // Getters

    // Actions
    initialize,
    setBackpackPermissionsSettingsTabFromHash,
  }
})

export { BackpackPermissionsSettingsTab, backpackPermissionsSettingsTabToHash }
