// @file Pinia store for handling notifications settings
import window from '@@/bits/global'
import { useSettingsNotificationsGeneralStore } from '@@/pinia/settings_notifications_general'
import { useUserFollowsStore } from '@@/pinia/user_follows'
import { useWallFollowsStore } from '@@/pinia/wall_follows'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum NotificationsSettingsTab {
  General = 'general',
  Padlets = 'padlets',
  Accounts = 'accounts',
}

export const notificationsSettingsTabToHash = {
  [NotificationsSettingsTab.General]: '#general',
  [NotificationsSettingsTab.Padlets]: '#padlets',
  [NotificationsSettingsTab.Accounts]: '#accounts',
}

const hashToNotificationsSettingsTab = Object.fromEntries(
  Object.entries(notificationsSettingsTabToHash).map(([k, v]) => [v, k as NotificationsSettingsTab]),
)

const getNotificationsSettingsTabFromHash = (): NotificationsSettingsTab => {
  return hashToNotificationsSettingsTab[window.location.hash] ?? NotificationsSettingsTab.General
}

export const useSettingsNotificationsStore = defineStore('settingsNotifications', () => {
  const settingsNotificationsGeneralStore = useSettingsNotificationsGeneralStore()
  const userFollowsStore = useUserFollowsStore()
  const wallFollowsStore = useWallFollowsStore()

  // State
  const currentTab = ref<NotificationsSettingsTab>(getNotificationsSettingsTabFromHash())
  const visitedTabs = ref<Record<NotificationsSettingsTab, boolean>>({
    [NotificationsSettingsTab.General]: false,
    [NotificationsSettingsTab.Padlets]: false,
    [NotificationsSettingsTab.Accounts]: false,
  })

  // Getters

  // Actions
  const initialize = (): void => {
    void settingsNotificationsGeneralStore.initialize()
    setCurrentTab({ currentTab: currentTab.value })
  }

  const initializeTab = ({ tab }: { tab: NotificationsSettingsTab }): void => {
    switch (tab) {
      case NotificationsSettingsTab.Accounts:
        void userFollowsStore.initialize()
        break
      case NotificationsSettingsTab.Padlets:
        void wallFollowsStore.initialize()
    }
  }

  const setCurrentTab = (payload: { currentTab: NotificationsSettingsTab }): void => {
    currentTab.value = payload.currentTab
    if (visitedTabs.value[payload.currentTab]) return
    initializeTab({ tab: payload.currentTab })
    visitedTabs.value[payload.currentTab] = true
  }

  const refreshTab = ({ tab }: { tab: NotificationsSettingsTab }): void => {
    if (settingsNotificationsGeneralStore.isErroredNotificationsSettings)
      void settingsNotificationsGeneralStore.initialize()
    initializeTab({ tab })
  }

  const setNotificationsSettingsTabFromHash = (): void => {
    setCurrentTab({ currentTab: getNotificationsSettingsTabFromHash() })
  }

  return {
    // State
    currentTab,

    // Getters

    // Actions
    initialize,
    refreshTab,
    setNotificationsSettingsTabFromHash,
  }
})
