// @file Pinia store for handling wall follows
import { captureFetchException } from '@@/bits/error_tracker'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import PromiseQueue from '@@/bits/promise_queue'
import { WallFollows as WallFollowsApi } from '@@/dashboard/padlet_api'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { UserId, Wall, WallFollowApiResponse } from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod, fetchJson } from '@padlet/fetch'
import { unionBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export interface WallFollow {
  id: number
  userId: UserId
  wall: Pick<Wall, 'id' | 'address' | 'title' | 'builder' | 'thumbnail' | 'updated_at'>
  followed: boolean
}

export interface WallFollowUpdateRecord {
  wallFollowId: number
  followed: boolean
}

enum WallFollowsStatus {
  Loading = 'Loading',
  LoadingMore = 'LoadingMore',
  Completed = 'Completed',
  Errored = 'Errored',
}

const WALL_FOLLOWS_RESULT_LIMIT = 50

// Promise queue to handle update requests.
const q = new PromiseQueue()

export const useWallFollowsStore = defineStore('wallFollows', () => {
  const { genericFetchError } = useGlobalSnackbarStore()
  // State
  const wallFollows = ref<WallFollow[]>([])
  const wallFollowsStatus = ref<WallFollowsStatus>(WallFollowsStatus.Loading)
  const nextWallFollowsPageUrl = ref<string | null>(null)

  // Getters
  const hasWallFollows = computed((): boolean => wallFollows.value.length > 0)
  const isErroredWallFollows = computed((): boolean => wallFollowsStatus.value === WallFollowsStatus.Errored)
  const isLoadingWallFollows = computed((): boolean => wallFollowsStatus.value === WallFollowsStatus.Loading)
  const isLoadingMoreWallFollows = computed((): boolean => wallFollowsStatus.value === WallFollowsStatus.LoadingMore)

  // Actions
  const initialize = async (): Promise<void> => {
    wallFollowsStatus.value = WallFollowsStatus.Loading
    try {
      const response: JsonAPIResponse<WallFollowApiResponse> = await WallFollowsApi.fetch({
        pageNumber: 0,
        pageSize: WALL_FOLLOWS_RESULT_LIMIT,
      })
      const wallFollowsData: WallFollow[] = (response?.data as Array<JsonAPIResource<WallFollowApiResponse>>).map(
        (wallFollowData) => {
          const attributes = wallFollowData.attributes
          const wallFollowResult: WallFollow = {
            id: Number(wallFollowData.id),
            userId: attributes.userId,
            wall: attributes.wall as Pick<Wall, 'id' | 'address' | 'title' | 'builder' | 'thumbnail' | 'updated_at'>,
            followed: attributes.follows,
          }
          return wallFollowResult
        },
      )
      wallFollows.value = wallFollowsData
      nextWallFollowsPageUrl.value = response?.links?.next !== undefined ? response.links.next : null
      wallFollowsStatus.value = WallFollowsStatus.Completed
    } catch (e) {
      captureFetchException(e, { source: 'WallFollowsInitialize' })
      wallFollowsStatus.value = WallFollowsStatus.Errored
    }
  }

  const fetchMoreWallFollows = async (): Promise<void> => {
    if (nextWallFollowsPageUrl.value == null || nextWallFollowsPageUrl.value === '') return
    wallFollowsStatus.value = WallFollowsStatus.LoadingMore
    try {
      const response: JsonAPIResponse<WallFollowApiResponse> = await fetchJson(nextWallFollowsPageUrl.value, {
        method: HTTPMethod.get,
      })
      const wallFollowsData: WallFollow[] = (response?.data as Array<JsonAPIResource<WallFollowApiResponse>>).map(
        (wallFollowData) => {
          const attributes = wallFollowData.attributes
          const wallFollowResult: WallFollow = {
            id: Number(wallFollowData.id),
            userId: attributes.userId,
            wall: attributes.wall as Pick<Wall, 'id' | 'address' | 'title' | 'builder' | 'thumbnail' | 'updated_at'>,
            followed: attributes.follows,
          }
          return wallFollowResult
        },
      )
      wallFollows.value = unionBy(wallFollows.value, wallFollowsData, 'id')
      nextWallFollowsPageUrl.value = response?.links?.next !== undefined ? response.links.next : null
    } catch (e) {
      captureFetchException(e, { source: 'WallFollowsFetchMoreWallFollows' })
      genericFetchError()
    } finally {
      wallFollowsStatus.value = WallFollowsStatus.Completed
    }
  }

  const setWallFollowFollowed = ({ wallFollowId, followed }: WallFollowUpdateRecord): void => {
    const wallFollow = wallFollows.value.find((wallFollow: WallFollow) => wallFollow.id === wallFollowId)
    if (wallFollow === undefined) return
    wallFollow.followed = followed
  }

  const updateWallFollow = async ({ wallFollowId, followed }: WallFollowUpdateRecord): Promise<void> => {
    try {
      await WallFollowsApi.update(
        { wallFollowId },
        {
          body: asciiSafeStringify({
            data: {
              attributes: { follows: followed },
            },
          }),
        },
      )
    } catch (e) {
      // If one of the requests fails, we don't want to execute
      // the following requests.
      q.clear()
      captureFetchException(e, { source: 'WallFollowsUpdateWallFollow' })
      genericFetchError()
      setWallFollowFollowed({
        wallFollowId,
        followed: !followed,
      })
    }
  }

  const queueUpdateWallFollow = ({ wallFollowId, followed }: WallFollowUpdateRecord): void => {
    setWallFollowFollowed({
      wallFollowId,
      followed,
    })
    void q.enqueue(
      'updateWallFollow',
      async (): Promise<void> =>
        await updateWallFollow({
          wallFollowId,
          followed,
        }),
    )
  }

  return {
    // State
    nextWallFollowsPageUrl,
    wallFollows,

    // Getters
    hasWallFollows,
    isErroredWallFollows,
    isLoadingWallFollows,
    isLoadingMoreWallFollows,

    // Actions
    initialize,
    fetchMoreWallFollows,
    queueUpdateWallFollow,
  }
})
