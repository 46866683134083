// @file User info store
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'
import { isEmail } from '@@/bits/email'
import { captureException, captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { getLocaleName } from '@@/bits/locale'
import { MAX_NAMESPACE_LENGTH, MAX_NAME_LENGTH, MAX_USER_BIO_LENGTH } from '@@/bits/numbers'
import { USERNAME_REGEX } from '@@/bits/regex'
import { isOwner } from '@@/bits/user_model'
import { User as UserApi } from '@@/dashboard/padlet_api'
import { ApiErrorCode, HttpCode, SnackbarNotificationType, UserAccountType } from '@@/enums'
import { useDashboardSettingsStore } from '@@/pinia/dashboard_settings'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useScreenReaderNotificationsStore } from '@@/pinia/screen_reader_notifications'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { UserCamelCase as User } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum UserInfoStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

export const USER_ACCOUNT_TYPE_LABEL_MAP = {
  [UserAccountType.PERSONAL]: __('Personal'),
  [UserAccountType.STUDENT]: __('Student'),
  [UserAccountType.TEACHER]: __('Teacher'),
  [UserAccountType.STAFF]: __('School staff'),
  [UserAccountType.BUSINESS]: __('Business'),
}

export const useSettingsUserInfoStore = defineStore('settingsUserInfo', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const windowSizeStore = useWindowSizeStore()
  const dashboardSettingsStore = useDashboardSettingsStore()
  const screenReaderNotificationStore = useScreenReaderNotificationsStore()

  // Actions

  function initializeState(user: User): void {
    defaultAvatar.value = user.defaultAvatar ?? ''
    initialAvatar.value = user.avatar ?? ''
    initialName.value = user.name ?? ''
    initialEmail.value = user.email ?? ''
    initialUsername.value = user.username ?? ''
    initialBio.value = user.bio ?? ''
    id.value = user.id ?? 0
    avatar.value = user.avatar ?? ''
    name.value = user.name ?? ''
    email.value = user.email ?? ''
    username.value = user.username ?? ''
    bio.value = user.bio ?? ''
    currentLocale.value = user.lang ?? ''
    isBetaFeaturePreviewEnabled.value = user.isBetaFeaturePreviewEnabled ?? false
    isTenantOwner.value = isOwner(user)
    canUpdateEmail.value = user.canUpdateEmail ?? true
    canUpdateName.value = user.canUpdateName ?? true
    canUpdateAvatar.value = user.canUpdateAvatar ?? true
    canUpdateUsername.value = user.canUpdateUsername ?? true
    accountType.value = user.accountType ?? UserAccountType.PERSONAL
    isNativeUser.value = user.isNative ?? false
  }

  const id = ref(0)
  const userInfoStatus = ref(UserInfoStatus.Loading)

  const isLoadingUserInfo = computed(() => userInfoStatus.value === UserInfoStatus.Loading)

  async function fetchUserInfo(): Promise<void> {
    try {
      userInfoStatus.value = UserInfoStatus.Loading
      const response = await UserApi.fetchSettingsUserInfo()
      const user = (response.data as JsonAPIResource<User>).attributes

      initializeState(user)
      userInfoStatus.value = UserInfoStatus.Completed

      if (user.isEmailRecentlyUpdatedAndVerified) {
        void globalSnackbarStore.setSnackbar({
          message: __('Email verified'),
          notificationType: SnackbarNotificationType.success,
        })
      }
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsBasicInfoFetchUserInfo' })
      userInfoStatus.value = UserInfoStatus.Errored
      void globalSnackbarStore.setSnackbar({
        message: __('Error fetching User info'),
        notificationType: SnackbarNotificationType.error,
      })
    }
  }

  // Avatar
  const defaultAvatar = ref('')
  const initialAvatar = ref('')
  const avatar = ref('')

  function handleUploadStart(): void {
    avatar.value = ''
  }

  async function saveAvatarUrl(url: string): Promise<void> {
    if (url === '') return

    if (url === defaultAvatar.value) {
      // Show the default avatar immediately after the user selects it, don't wait for the request to finish
      avatar.value = url
    }

    try {
      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { avatar: url },
          },
        }),
      })
      avatar.value = url
      initialAvatar.value = url
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsBasicInfoSaveAvatarUrl' })
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating avatar'),
        notificationType: SnackbarNotificationType.error,
      })
    }
  }

  function handleUploadFail(): void {
    avatar.value = initialAvatar.value
    void globalSnackbarStore.setSnackbar({
      message: __('Error updating avatar'),
      notificationType: SnackbarNotificationType.error,
    })
  }

  // Name
  const initialName = ref('')
  const name = ref('')
  const nameValidationMessage = ref('')
  const isNameSaved = ref(false)
  const isAwaitingSaveNameResponse = ref(false)

  const hasNameChanged = computed(() => name.value !== initialName.value)

  async function saveName(): Promise<void> {
    // Don't send update request if the name hasn't changed
    if (!hasNameChanged.value) {
      isNameSaved.value = true
      return
    }

    const trimmedName = name.value.trim()

    if (trimmedName.length > MAX_NAME_LENGTH) {
      nameValidationMessage.value = __('Maximum %{maxLength} characters', { maxLength: MAX_NAME_LENGTH })
      return
    }

    try {
      isAwaitingSaveNameResponse.value = true
      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { name: trimmedName },
          },
        }),
      })
      isNameSaved.value = true
      name.value = trimmedName
      initialName.value = trimmedName
      screenReaderNotificationStore.addScreenReaderMessage(__('Name updated to %{name}', { name: trimmedName }))
    } catch (e) {
      if (e.status === HttpCode.Unauthorized) {
        nameValidationMessage.value = __('Name changes not allowed')
        screenReaderNotificationStore.addScreenReaderMessage(__('Unauthorized to change name'))
      } else {
        void globalSnackbarStore.setSnackbar({
          message: __('Error updating name'),
          notificationType: SnackbarNotificationType.error,
        })
        captureFetchException(e, { source: 'DashboardSettingsBasicInfoSaveName' })
      }
    } finally {
      isAwaitingSaveNameResponse.value = false
    }
  }

  // Email
  const initialEmail = ref('')
  const email = ref('')
  const emailValidationMessage = ref('')
  const isEmailSaved = ref(false)
  const isAwaitingSaveEmailResponse = ref(false)
  const canUpdateEmail = ref(true)
  const canUpdateName = ref(true)
  const canUpdateAvatar = ref(true)
  const canUpdateUsername = ref(true)
  const isTenantOwner = ref(false)

  const hasEmailChanged = computed(() => email.value !== initialEmail.value)

  async function saveEmail(): Promise<void> {
    // Don't send update request if the email hasn't changed
    if (!hasEmailChanged.value) {
      isEmailSaved.value = true
      return
    }

    const trimmedEmail = email.value.trim()

    if (trimmedEmail === '') {
      emailValidationMessage.value = __('Email can not be blank')
      screenReaderNotificationStore.addScreenReaderMessage(__('Email can not be blank'))
      return
    }

    if (!isEmail(trimmedEmail)) {
      emailValidationMessage.value = __('Email is invalid')
      screenReaderNotificationStore.addScreenReaderMessage(__('Email is invalid'))
      return
    }

    try {
      isAwaitingSaveEmailResponse.value = true
      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { email: trimmedEmail },
          },
        }),
      })
      isEmailSaved.value = true
      email.value = trimmedEmail
      initialEmail.value = trimmedEmail
      screenReaderNotificationStore.addScreenReaderMessage(__('Email updated to %{email}', { email: trimmedEmail }))

      if (isTenantOwner.value) {
        dashboardSettingsStore.updateCurrentTenant({
          owner_email_domain: trimmedEmail.split('@')[1].toLocaleLowerCase(),
        })
      }
    } catch (e) {
      if (e.status === HttpCode.UnprocessableEntity) {
        try {
          const parsedErrorMessage = JSON.parse(e.message)
          const errorCode = parsedErrorMessage?.errors?.[0]?.code

          if (errorCode === ApiErrorCode.INVALID_EMAIL) {
            emailValidationMessage.value = __('Email is invalid')
            screenReaderNotificationStore.addScreenReaderMessage(__('Email is invalid'))
          } else if (errorCode === ApiErrorCode.EMAIL_ALREADY_IN_USE) {
            emailValidationMessage.value = __('Email is taken')
            screenReaderNotificationStore.addScreenReaderMessage(__('Email is taken'))
          } else if (errorCode === ApiErrorCode.INVALID_EMAIL_DOMAIN) {
            emailValidationMessage.value = __('Email domain is not allowed')
            screenReaderNotificationStore.addScreenReaderMessage(__('Email domain is not allowed'))
          }
        } catch (e) {
          void globalSnackbarStore.setSnackbar({
            message: __('Error updating email'),
            notificationType: SnackbarNotificationType.error,
          })
          captureException(e)
        }
      } else if (e.status === HttpCode.Unauthorized) {
        emailValidationMessage.value = __('Email changes not allowed')
        screenReaderNotificationStore.addScreenReaderMessage(__('Unauthorized to change email'))
      } else {
        void globalSnackbarStore.setSnackbar({
          message: __('Error updating email'),
          notificationType: SnackbarNotificationType.error,
        })
        captureFetchException(e, { source: 'DashboardSettingsBasicInfoSaveEmail' })
      }
    } finally {
      isAwaitingSaveEmailResponse.value = false
    }
  }

  // Username
  const initialUsername = ref('')
  const username = ref('')
  const usernameValidationMessage = ref('')
  const isUsernameSaved = ref(false)
  const isAwaitingSaveUsernameResponse = ref(false)

  const hasUsernameChanged = computed(() => username.value !== initialUsername.value)

  async function saveUsername(): Promise<void> {
    // Don't send update request if the username hasn't changed
    if (!hasUsernameChanged.value) {
      isUsernameSaved.value = true
      return
    }

    const trimmedUsername = username.value.trim()

    if (trimmedUsername === '') {
      usernameValidationMessage.value = __('Username cannot be blank')
      screenReaderNotificationStore.addScreenReaderMessage(__('Username cannot be blank'))
      return
    } else if (trimmedUsername.length > MAX_NAMESPACE_LENGTH) {
      usernameValidationMessage.value = __('Maximum %{maxLength} characters', {
        maxLength: MAX_NAMESPACE_LENGTH,
      })
      screenReaderNotificationStore.addScreenReaderMessage(
        __('Username has reached the maximum length of %{maxLength} characters', {
          maxLength: MAX_NAMESPACE_LENGTH,
        }),
      )
      return
    } else if (USERNAME_REGEX.exec(trimmedUsername) === null) {
      if (windowSizeStore.isSmallerThanTabletPortrait) {
        usernameValidationMessage.value = __('Special characters not allowed')
        screenReaderNotificationStore.addScreenReaderMessage(__('Username cannot have special characters'))
      } else {
        usernameValidationMessage.value = __('Only letters, numbers, and underscores are allowed')
        screenReaderNotificationStore.addScreenReaderMessage(
          __('Username should only have letters, numbers, and underscores'),
        )
      }
      return
    }

    try {
      isAwaitingSaveUsernameResponse.value = true
      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { username: trimmedUsername },
          },
        }),
      })
      isUsernameSaved.value = true
      username.value = trimmedUsername
      initialUsername.value = trimmedUsername
      screenReaderNotificationStore.addScreenReaderMessage(
        __('Username updated to %{username}', { username: trimmedUsername }),
      )
    } catch (e) {
      if (e.status === HttpCode.UnprocessableEntity) {
        try {
          const parsedErrorMessage = JSON.parse(e.message)
          const errorCode = parsedErrorMessage?.errors?.[0]?.code

          if (errorCode === ApiErrorCode.RECORD_INVALID) {
            usernameValidationMessage.value = __('Username is invalid')
            screenReaderNotificationStore.addScreenReaderMessage(__('Username is invalid'))
          } else if (errorCode === ApiErrorCode.USERNAME_ALREADY_IN_USE) {
            usernameValidationMessage.value = __('Username is taken')
            screenReaderNotificationStore.addScreenReaderMessage(__('Username is taken'))
          } else if (errorCode === ApiErrorCode.USERNAME_TOO_LONG) {
            usernameValidationMessage.value = __('Maximum %{maxLength} characters', {
              maxLength: MAX_NAMESPACE_LENGTH,
            })
            screenReaderNotificationStore.addScreenReaderMessage(
              __('Username has reached the maximum length of %{maxLength} characters', {
                maxLength: MAX_NAMESPACE_LENGTH,
              }),
            )
          }
        } catch (e) {
          void globalSnackbarStore.setSnackbar({
            message: __('Error updating username'),
            notificationType: SnackbarNotificationType.error,
          })
          captureException(e)
        }
      } else if (e.status === HttpCode.Unauthorized) {
        usernameValidationMessage.value = __('Username changes not allowed')
        screenReaderNotificationStore.addScreenReaderMessage(__('Unauthorized to change username'))
      } else {
        void globalSnackbarStore.setSnackbar({
          message: __('Error updating username'),
          notificationType: SnackbarNotificationType.error,
        })
        captureFetchException(e, { source: 'DashboardSettingsBasicInfoSaveUsername' })
      }
    } finally {
      isAwaitingSaveUsernameResponse.value = false
    }
  }

  // Bio
  const initialBio = ref('')
  const bioValidationMessage = ref('')
  const bio = ref('')
  const isBioSaved = ref(false)
  const isAwaitingSaveBioResponse = ref(false)

  const hasBioChanged = computed(() => bio.value !== initialBio.value)

  async function saveBio(): Promise<void> {
    // Don't send update request if the bio hasn't changed
    if (!hasBioChanged.value) {
      isBioSaved.value = true
      return
    }

    const trimmedBio = bio.value.trim()
    try {
      isAwaitingSaveBioResponse.value = true
      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { bio: trimmedBio },
          },
        }),
      })
      isBioSaved.value = true
      bio.value = trimmedBio
      initialBio.value = trimmedBio
      screenReaderNotificationStore.addScreenReaderMessage(__('"About" description has been updated'))
    } catch (e) {
      if (trimmedBio.length > MAX_USER_BIO_LENGTH) {
        bioValidationMessage.value = __('Maximum %{maxLength} characters', {
          maxLength: MAX_USER_BIO_LENGTH,
        })
        screenReaderNotificationStore.addScreenReaderMessage(
          __('"About" description exceeded the maximum length of %{maxLength} characters', {
            maxLength: MAX_USER_BIO_LENGTH,
          }),
        )
      } else {
        bioValidationMessage.value = __('Error updating bio')
        void globalSnackbarStore.setSnackbar({
          message: bioValidationMessage.value,
          notificationType: SnackbarNotificationType.error,
        })
        captureFetchException(e, { source: 'DashboardSettingsBasicInfoSaveBio' })
      }
    } finally {
      isAwaitingSaveBioResponse.value = false
    }
  }

  // Language
  const currentLocale = ref(getHyphenatedCurrentLocale())

  const currentLocaleName = computed(() => getLocaleName(currentLocale.value))

  async function saveLocale(lang: string): Promise<void> {
    currentLocale.value = lang
    try {
      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { lang },
          },
        }),
      })
      screenReaderNotificationStore.addScreenReaderMessage(
        __('Language updated to %{lang}', { lang: getLocaleName(lang) }),
      )
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsBasicInfoSaveLocale' })
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating language'),
        notificationType: SnackbarNotificationType.error,
      })
    }
  }

  // Account type
  const accountType = ref<UserAccountType>(UserAccountType.PERSONAL)
  const isNativeUser = ref(false)

  async function saveAccountType(value: UserAccountType): Promise<void> {
    try {
      accountType.value = value
      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { accountType: value },
          },
        }),
      })
      screenReaderNotificationStore.addScreenReaderMessage(
        __('Account type updated to %{accountType}', { accountType: USER_ACCOUNT_TYPE_LABEL_MAP[value] }),
      )
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsBasicInfoSaveAccountType' })
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating account type'),
        notificationType: SnackbarNotificationType.error,
      })
    }
  }

  // Beta
  const isBetaFeaturePreviewEnabled = ref(false)
  const isAwaitingSaveIsBetaFeaturePreviewEnabledResponse = ref(false)

  async function toggleIsBetaFeaturePreviewEnabled(): Promise<void> {
    isBetaFeaturePreviewEnabled.value = !isBetaFeaturePreviewEnabled.value

    try {
      isAwaitingSaveIsBetaFeaturePreviewEnabledResponse.value = true
      await UserApi.updateSettingsUserInfo({
        body: asciiSafeStringify({
          data: {
            attributes: { is_beta_feature_preview_enabled: isBetaFeaturePreviewEnabled.value },
          },
        }),
      })
      screenReaderNotificationStore.addScreenReaderMessage(
        isBetaFeaturePreviewEnabled.value
          ? __('Beta features has been enabled for your account')
          : __('Beta features has been disabled for your account'),
      )
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsBasicInfoSaveIsBetaFeaturePreviewEnabled' })
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating beta features'),
        notificationType: SnackbarNotificationType.error,
      })

      // Revert to the pre-update value if the update fails
      isBetaFeaturePreviewEnabled.value = !isBetaFeaturePreviewEnabled.value
    } finally {
      isAwaitingSaveIsBetaFeaturePreviewEnabledResponse.value = false
    }
  }

  return {
    id,
    userInfoStatus,
    isLoadingUserInfo,
    initializeState,
    fetchUserInfo,

    // Avatar
    defaultAvatar,
    initialAvatar,
    avatar,
    handleUploadStart,
    handleUploadFail,
    saveAvatarUrl,
    canUpdateAvatar,

    // Name
    name,
    initialName,
    nameValidationMessage,
    isNameSaved,
    isAwaitingSaveNameResponse,
    hasNameChanged,
    canUpdateName,
    saveName,

    // Email
    email,
    initialEmail,
    emailValidationMessage,
    isEmailSaved,
    isAwaitingSaveEmailResponse,
    hasEmailChanged,
    canUpdateEmail,
    saveEmail,

    // Username
    username,
    initialUsername,
    usernameValidationMessage,
    isUsernameSaved,
    isAwaitingSaveUsernameResponse,
    hasUsernameChanged,
    canUpdateUsername,
    saveUsername,

    // Bio
    bio,
    initialBio,
    bioValidationMessage,
    isBioSaved,
    isAwaitingSaveBioResponse,
    hasBioChanged,
    saveBio,

    // Language
    currentLocale,
    currentLocaleName,
    saveLocale,

    // Account type
    accountType,
    isNativeUser,
    saveAccountType,

    // Beta
    isBetaFeaturePreviewEnabled,
    isAwaitingSaveIsBetaFeaturePreviewEnabledResponse,
    toggleIsBetaFeaturePreviewEnabled,
  }
})
