/**
 * @file Contains helpers for the Notifications Settings.
 * */
import { __ } from '@@/bits/intl'
import { NotificationChannelType, NotificationType } from '@@/enums'

interface NotificationTypeCopy {
  title: string
  description: string
  roles?: string
}

const NOTIFICATION_CHANNEL_TYPE_TEXT: Record<NotificationChannelType, string> = {
  [NotificationChannelType.Email]: __('Email'),
  [NotificationChannelType.Push]: __('Push'),
}

// Texts used to display notifications settings.
const NOTIFICATION_TYPE_TEXT: Record<NotificationType, NotificationTypeCopy> = {
  [NotificationType.ProductUpdates]: {
    title: __('Product updates'),
    description: __('Updates about what has changed in Padlet.'),
  },
  [NotificationType.NewFollower]: {
    title: __('New follower'),
    description: __('Someone followed your account.'),
  },
  [NotificationType.InvitationToCollaborate]: {
    title: __('Invitation to collaborate'),
    description: __('You are invited to be a collaborator on a padlet.'),
  },
  [NotificationType.PostApprovedDeclined]: {
    title: __('Post approved'),
    description: __('A post you submitted was approved by a moderator.'),
  },
  [NotificationType.CommentOnWrittenPost]: {
    title: __('Comments on your posts'),
    description: __('A comment is added to a post you’ve written.'),
  },
  [NotificationType.ReactionOnWrittenPost]: {
    title: __('Reactions on your posts'),
    description: __('A reaction is added to a post you’ve written.'),
  },
  [NotificationType.NewPostSubmission]: {
    title: __('New post submission'),
    description: __('A post is submitted for approval on a padlet you moderate.'),
    roles: __('Editors and admins'),
  },
  [NotificationType.NewCommentSubmission]: {
    title: __('New comment submission'),
    description: __('A comment is submitted for approval on a padlet you moderate.'),
    roles: __('Editors and admins'),
  },
  [NotificationType.PadletRemade]: {
    title: __('Remakes'),
    description: __('Your padlet is remade by someone else.'),
    roles: __('Admins only'),
  },
  [NotificationType.PadletFollowed]: {
    title: __('New padlet follow'),
    description: __('Your padlet is followed by someone else.'),
    roles: __('Admins only'),
  },
  [NotificationType.FollowedPadletPost]: {
    title: __('New posts'),
    description: __('A post is published on a padlet you follow.'),
  },
  [NotificationType.FollowedPadletComment]: {
    title: __('Comments'),
    description: __('A comment is added to any post on a padlet you follow and moderate.'),
    roles: __('Editors and admins'),
  },
  [NotificationType.FollowedPadletReaction]: {
    title: __('Reactions'),
    description: __('A reaction is added to any post on a padlet you follow and moderate.'),
    roles: __('Editors and admins'),
  },
  [NotificationType.FollowedAccountNewPadlet]: {
    title: __('New padlets'),
    description: __('An account you follow creates a new public padlet.'),
  },
  [NotificationType.Mentioned]: {
    title: __('Mentions'),
    description: __('You are tagged in a post or comment.'),
  },
}

export { NOTIFICATION_CHANNEL_TYPE_TEXT, NOTIFICATION_TYPE_TEXT }
