// @file Get current tenant info
import { DOMContentLoadedPromise } from '@@/bits/dom'
import window, { ww } from '@@/bits/global'
import { Tenant } from '@@/types'

// The Tenant might not be set on `window` if the scripts have not run.
const getCurrentTenant = (): Tenant => window.$currentTenant ?? ww?.vueStartingState?.tenant ?? { id: null }

// Initialize with a potentially unsafe value, then update when the DOM is ready.
let currentTenant = getCurrentTenant()
export const currentTenantPromise = DOMContentLoadedPromise.then(getCurrentTenant)
void currentTenantPromise.then((tenant) => (currentTenant = tenant))

export default currentTenant
