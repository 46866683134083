/**
 * @file Pinia store for handling general notifications settings
 */

import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { NOTIFICATION_TYPE_TEXT } from '@@/bits/notifications_settings_helper'
import PromiseQueue from '@@/bits/promise_queue'
import { NotificationsSettings as NotificationsSettingsApi } from '@@/dashboard/padlet_api'
import type { NotificationChannelType, NotificationType } from '@@/enums'
import { SnackbarNotificationType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { NotificationChannelList, NotificationSettingApiResponse } from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export type NotificationsSettings = Record<NotificationType, NotificationChannelList>

export interface NotificationsSettingsUpdateRecord {
  notificationType: NotificationType
  notificationChannelType: NotificationChannelType
  notify: boolean
}

enum NotificationsSettingsStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

/**
 * Given a list of notification channels, returns whether
 * there's at least one channel available or not.
 *
 * @param {NotificationChannelList} notificationChannels The notifications settings that come from the backend.
 * @return {boolean} The notifications settings in the schema that the module defines.
 */
const getIsAnyChannelAvailable = (notificationChannels: NotificationChannelList): boolean => {
  for (const notificationChannelType in notificationChannels) {
    if (notificationChannels[notificationChannelType].available === true) return true
  }
  return false
}

/**
 * Parses the notification settings that come from the backend into
 * the schema that the module defines, filtering those that don't have
 * any channel available.
 *
 * @param {NotificationSettingApiResponse[]} notificationsSettings The notifications settings that come from the backend.
 * @return {Partial<NotificationsSettings>} The notifications settings in the schema that the module defines.
 */
const getParsedNotificationsSettings = (
  notificationsSettings: NotificationSettingApiResponse[],
): Partial<NotificationsSettings> => {
  return notificationsSettings.reduce(
    (
      accumulatedNotificationsSettings: Partial<NotificationsSettings>,
      notificationSetting: NotificationSettingApiResponse,
    ) => {
      if (!getIsAnyChannelAvailable(notificationSetting.channels)) return accumulatedNotificationsSettings
      accumulatedNotificationsSettings[notificationSetting.notification_type] = notificationSetting.channels
      return accumulatedNotificationsSettings
    },
    {},
  )
}

// Promise queue to handle update requests.
const q = new PromiseQueue()

export const useSettingsNotificationsGeneralStore = defineStore('settingsNotificationsGeneral', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()

  // State
  const notificationsSettings = ref<Partial<NotificationsSettings>>({})
  const notificationsSettingsStatus = ref<NotificationsSettingsStatus>(NotificationsSettingsStatus.Loading)

  // Getters
  const getNotificationsSettingsFromNotificationTypes = computed<
    (notificationTypes: NotificationType[]) => Partial<NotificationsSettings>
  >(() => (notificationTypes: NotificationType[]) => {
    // Given an array of notification types, return all the notifications settings corresponding to them.
    return notificationTypes.reduce(
      (
        accumulatedNotificationsSettings: NotificationsSettings,
        notificationType: NotificationType,
      ): NotificationsSettings => {
        const notification = notificationsSettings.value[notificationType]
        if (notification === undefined) return accumulatedNotificationsSettings
        accumulatedNotificationsSettings[notificationType] = notification
        return accumulatedNotificationsSettings
      },
      {} as NotificationsSettings,
    )
  })
  const isErroredNotificationsSettings = computed<boolean>(
    () => notificationsSettingsStatus.value === NotificationsSettingsStatus.Errored,
  )
  const isLoadingNotificationsSettings = computed<boolean>(
    () => notificationsSettingsStatus.value === NotificationsSettingsStatus.Loading,
  )

  // Actions
  const initialize = async (): Promise<void> => {
    notificationsSettingsStatus.value = NotificationsSettingsStatus.Loading
    try {
      const response: JsonAPIResponse<NotificationSettingApiResponse> = await NotificationsSettingsApi.fetch()
      const notificationsSettingsData: NotificationSettingApiResponse[] = (
        response?.data as Array<JsonAPIResource<NotificationSettingApiResponse>>
      ).map((notificationSetting) => notificationSetting.attributes)
      notificationsSettings.value = getParsedNotificationsSettings(notificationsSettingsData)
      notificationsSettingsStatus.value = NotificationsSettingsStatus.Completed
    } catch (e) {
      captureFetchException(e, { source: 'SettingsNotificationsInitialize' })
      notificationsSettingsStatus.value = NotificationsSettingsStatus.Errored
    }
  }

  const queueUpdateNotificationSetting = ({
    notificationType,
    notificationChannelType,
    notify,
  }: NotificationsSettingsUpdateRecord): void => {
    setNotificationSettingAvailableChannelNotify({ notificationType, notificationChannelType, notify })

    void q.enqueue(
      'updateNotificationSetting',
      async (): Promise<void> =>
        await updateNotificationSetting({
          notificationType,
          notificationChannelType,
          notify,
        }),
    )
  }

  const setNotificationSettingAvailableChannelNotify = ({
    notificationType,
    notificationChannelType,
    notify,
  }: NotificationsSettingsUpdateRecord): void => {
    const notification = notificationsSettings.value[notificationType]
    if (notification === undefined) return
    notification[notificationChannelType].notify = notify
  }

  const updateNotificationSetting = async ({
    notificationType,
    notificationChannelType,
    notify,
  }: NotificationsSettingsUpdateRecord): Promise<void> => {
    try {
      await NotificationsSettingsApi.update({
        body: asciiSafeStringify({
          data: {
            attributes: {
              notification_type: notificationType,
              channel: notificationChannelType,
              notify,
            },
          },
        }),
      })
    } catch (e) {
      // If one of the requests fails, we don't want to execute
      // the following requests.
      q.clear()
      captureFetchException(e, { source: 'SettingsNotificationsGeneralUpdate' })
      globalSnackbarStore.setSnackbar({
        message: __('Could not save "%{notificationTitle}" setting.', {
          notificationTitle: NOTIFICATION_TYPE_TEXT[notificationType].title,
        }),
        notificationType: SnackbarNotificationType.error,
        timeout: 1500,
      })

      setNotificationSettingAvailableChannelNotify({ notificationType, notificationChannelType, notify })
    }
  }

  return {
    // Getters
    getNotificationsSettingsFromNotificationTypes,
    isErroredNotificationsSettings,
    isLoadingNotificationsSettings,

    // Actions
    initialize,
    queueUpdateNotificationSetting,
  }
})
