// @file Integration permissions settings store
import { captureException } from '@@/bits/error_tracker'
import { User as UserApi } from '@@/dashboard/padlet_api'
import { useDashboardSettingsStore } from '@@/pinia/dashboard_settings'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { UserOptions } from '@@/types'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSettingsIntegrationPermissionsStore = defineStore('settingsIntegrationPermissions', () => {
  const dashboardSettingsStore = useDashboardSettingsStore()

  const userOptions = computed<UserOptions>(() => dashboardSettingsStore.userOptions)

  // State
  const googleDriveEmail = ref<string | null | undefined>(undefined)

  // Getters

  // Actions
  function initialize(): void {
    googleDriveEmail.value = userOptions.value.linkedGoogleDriveEmail ?? null
  }

  function setGoogleDriveEmail(email: string): void {
    dashboardSettingsStore.updateUserOptions({ linkedGoogleDriveEmail: email })
    googleDriveEmail.value = email
  }

  function clearGoogleDriveEmail(): void {
    dashboardSettingsStore.updateUserOptions({ linkedGoogleDriveEmail: undefined })
    googleDriveEmail.value = null
  }

  const removeGoogleDriveAuthorization = async (userId: number): Promise<void> => {
    // await an endpoint that will remove the google drive authorization
    try {
      await UserApi.removeGoogleDriveAuthorization(userId)
      clearGoogleDriveEmail()
    } catch (error) {
      useGlobalSnackbarStore().genericFetchError()
      captureException(error)
    }
  }

  return {
    // State
    googleDriveEmail,

    // Getters

    // Actions
    initialize,
    setGoogleDriveEmail,
    clearGoogleDriveEmail,
    removeGoogleDriveAuthorization,
  }
})
