// @file Content safety settings store for libraries/tenants

import { trackEvent } from '@@/bits/analytics'
import {
  contentModerationModeOptionsMap,
  moderationCategoriesContentMap,
  type ContentModerationCategoryContentDetails,
} from '@@/bits/content_safety'
import { captureFetchException } from '@@/bits/error_tracker'
import PromiseQueue from '@@/bits/promise_queue'
import {
  LibraryContentSafetySettings as LibraryContentSafetySettingsApi,
  TenantContentSafetySettings as TenantContentSafetySettingsApi,
} from '@@/dashboard/padlet_api'
import type { ContentModerationCategory, ContentModerationMode } from '@@/enums'
import { ContentSafetyEntity } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { ContentSafetySettings, ContentSafetySettingsUpdateApiRequest } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum ContentSafetySettingsTab {
  Moderation = 'moderation',
  Attachments = 'attachments',
}

export const CONTENT_SAFETY_SETTINGS_TAB_TO_HASH = {
  [ContentSafetySettingsTab.Moderation]: '#moderation',
  [ContentSafetySettingsTab.Attachments]: '#attachments',
}

const hashToContentSafetySettingsTab = Object.fromEntries(
  Object.entries(CONTENT_SAFETY_SETTINGS_TAB_TO_HASH).map(([k, v]) => [v, k as ContentSafetySettingsTab]),
)

const getContentSafetySettingsTabFromHash = (): ContentSafetySettingsTab => {
  return hashToContentSafetySettingsTab[window.location.hash] ?? ContentSafetySettingsTab.Moderation
}

export interface ContentOperationModeDetails {
  value: ContentModerationMode
  title: string
  description: string
}

export const useContentSafetySettingsStore = defineStore('contentSafetySettings', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const queue = new PromiseQueue()

  // State
  const currentTab = ref<ContentSafetySettingsTab>(getContentSafetySettingsTabFromHash())
  const isContentSafetySettingsLoading = ref(false)
  const moderationCategoriesMap = ref<Map<ContentModerationCategory, ContentModerationCategoryContentDetails>>(
    new Map(moderationCategoriesContentMap),
  )
  const contentSafetySettings = ref<ContentSafetySettings>()

  const entityType = ref<ContentSafetyEntity>(ContentSafetyEntity.Library)
  const entityId = ref<string>('')

  // Actions

  async function initializeState(eType: ContentSafetyEntity, eId: string): Promise<void> {
    entityType.value = eType
    entityId.value = eId
    try {
      contentSafetySettings.value = await fetchContentSafetySettings()
      updateStateOnApiResponse()
    } catch (e) {
      globalSnackbarStore.genericFetchError()
    }
  }

  function setContentSafetySettingsTabFromHash(): void {
    currentTab.value = getContentSafetySettingsTabFromHash()
  }

  function updateStateOnApiResponse(): void {
    if (contentSafetySettings.value === undefined) {
      return
    }
    contentSafetySettings.value.moderationCategories.forEach((category) => {
      const moderationCategory = moderationCategoriesMap.value?.get(category.name)
      if (moderationCategory !== undefined) {
        moderationCategory.enabled = category.enabled
        moderationCategory.modifiable = category.modifiable
      }
    })
  }

  async function updateContentModerationMode(
    contentModerationMode: ContentModerationMode,
    initialContentModerationMode: ContentModerationMode,
  ): Promise<void> {
    const contentSafetySettingsUpdateApiRequest = {
      contentModerationMode,
    }
    try {
      trackEvent(
        'content_safety_settings',
        'update_content_moderation_mode',
        `${entityType.value}-${entityId.value}`,
        contentModerationMode,
      )
      contentSafetySettings.value = await updateContentSafetySettings(contentSafetySettingsUpdateApiRequest)
      updateStateOnApiResponse()
    } catch (e) {
      queue.clear()
      if (contentSafetySettings.value !== undefined) {
        contentSafetySettings.value.contentModerationMode = initialContentModerationMode
      }
      globalSnackbarStore.genericFetchError()
    }
  }

  async function queueUpdateContentModerationMode(contentModerationMode: ContentModerationMode): Promise<void> {
    if (
      contentSafetySettings.value === undefined ||
      contentModerationMode === contentSafetySettings.value.contentModerationMode
    ) {
      return
    }
    // optimistically update the state
    const initialContentModerationMode = contentSafetySettings.value.contentModerationMode
    contentSafetySettings.value.contentModerationMode = contentModerationMode
    void queue.enqueue(
      'updateContentModerationMode',
      async () => await updateContentModerationMode(contentModerationMode, initialContentModerationMode),
    )
  }

  async function updateModerationCategories(
    moderationCategory: ContentModerationCategory,
    enabled: boolean,
    initialModerationCategoryEnabledState: boolean,
  ): Promise<void> {
    const contentSafetySettingsUpdateApiRequest = {
      moderationCategories: [
        {
          name: moderationCategory,
          enabled,
        },
      ],
    }
    try {
      trackEvent(
        'content_safety_settings',
        enabled ? 'enable_moderation_category' : 'disable_moderation_category',
        `${entityType.value}-${entityId.value}`,
        moderationCategory,
      )
      contentSafetySettings.value = await updateContentSafetySettings(contentSafetySettingsUpdateApiRequest)
      updateStateOnApiResponse()
    } catch (e) {
      queue.clear()
      const moderationCategoryDetail = moderationCategoriesMap.value.get(moderationCategory)
      if (moderationCategoryDetail !== undefined) {
        moderationCategoryDetail.enabled = initialModerationCategoryEnabledState
      }
      globalSnackbarStore.genericFetchError()
    }
  }

  async function queueUpdateModerationCategories(
    moderationCategory: ContentModerationCategory,
    enabled: boolean,
  ): Promise<void> {
    if (contentSafetySettings.value === undefined) {
      return
    }
    const moderationCategoryDetail = moderationCategoriesMap.value.get(moderationCategory)
    if (moderationCategoryDetail === undefined) {
      return
    }
    const moderationCategoryEnabledState = moderationCategoryDetail.enabled
    // optimistically update the state
    moderationCategoryDetail.enabled = enabled

    void queue.enqueue(
      'updateModerationCategories',
      async () => await updateModerationCategories(moderationCategory, enabled, moderationCategoryEnabledState),
    )
  }

  async function updateContentSafetySettings(
    request: ContentSafetySettingsUpdateApiRequest,
  ): Promise<ContentSafetySettings> {
    try {
      const response =
        entityType.value === ContentSafetyEntity.Library
          ? await LibraryContentSafetySettingsApi.updateContentSafetySettings(entityId.value, request)
          : await TenantContentSafetySettingsApi.updateContentSafetySettings(entityId.value, request)
      const contentSafetySettings = (response.data as JsonAPIResource<ContentSafetySettings>).attributes
      return contentSafetySettings
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsUpdateContentSafetySettings' })
      throw e
    }
  }

  async function fetchContentSafetySettings(): Promise<ContentSafetySettings> {
    try {
      isContentSafetySettingsLoading.value = true
      const response =
        entityType.value === ContentSafetyEntity.Library
          ? await LibraryContentSafetySettingsApi.fetchContentSafetySettings(entityId.value)
          : await TenantContentSafetySettingsApi.fetchContentSafetySettings(entityId.value)
      const contentSafetySettings = (response.data as JsonAPIResource<ContentSafetySettings>).attributes
      return contentSafetySettings
    } catch (e) {
      captureFetchException(e, { source: 'DashboardSettingsFetchContentSafetySettings' })
      throw e
    } finally {
      isContentSafetySettingsLoading.value = false
    }
  }

  return {
    contentModerationModeOptionsMap,

    // State
    currentTab,
    isContentSafetySettingsLoading,
    moderationCategoriesMap,
    contentSafetySettings,

    // Getters

    // Actions
    initializeState,
    queueUpdateContentModerationMode,
    queueUpdateModerationCategories,
    fetchContentSafetySettings,
    setContentSafetySettingsTabFromHash,
  }
})
