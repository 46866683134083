/**
 * @file texts for content safety setting values
 */

import { __ } from '@@/bits/intl'
import { ContentModerationCategory, ContentModerationMode } from '@@/enums'

export interface ContentOperationModeDetails {
  value: ContentModerationMode
  title: string
  description: string
  testId?: string
}

export interface ContentModerationCategoryContentDetails {
  value: ContentModerationCategory
  title: string
  description: string
  enabled: boolean
  modifiable: boolean
}

export const contentModerationModeOptionsMap = new Map<ContentModerationMode, ContentOperationModeDetails>([
  [
    ContentModerationMode.None,
    {
      value: ContentModerationMode.None,
      title: __('None'),
      description: __('No approval required for posts and comments.'),
      testId: 'contentModerationModeNone',
    },
  ],
  [
    ContentModerationMode.Auto,
    {
      value: ContentModerationMode.Auto,
      title: __('Auto'),
      description: __(
        'Posts and comments detected as inappropriate by our Safety Net AI moderation system require approval.',
      ),
      testId: 'contentModerationModeAuto',
    },
  ],
  [
    ContentModerationMode.StudentsOnly,
    {
      value: ContentModerationMode.StudentsOnly,
      title: __('Manual • Students only'),
      description: __('Manually approve all posts and comments from students.'),
      testId: 'contentModerationModeStudents only',
    },
  ],
  [
    ContentModerationMode.All,
    {
      value: ContentModerationMode.All,
      title: __('Manual • All'),
      description: __('Manually approve all posts and comments.'),
      testId: 'contentModerationModeAll',
    },
  ],
])

export const moderationCategoriesContentMap = new Map<
  ContentModerationCategory,
  ContentModerationCategoryContentDetails
>([
  [
    ContentModerationCategory.Sexual,
    {
      value: ContentModerationCategory.Sexual,
      title: __('Sexual'),
      description: __('Strong references to sexual material and presence of nudity.'),
      enabled: false,
      modifiable: false,
    },
  ],
  [
    ContentModerationCategory.Violence,
    {
      value: ContentModerationCategory.Violence,
      title: __('Violence'),
      description: __('Serious and realistic threats, mentions of past violence.'),
      enabled: false,
      modifiable: false,
    },
  ],
  [
    ContentModerationCategory.Bullying,
    {
      value: ContentModerationCategory.Bullying,
      title: __('Bullying'),
      description: __(
        'Slurs or violent threats toward specific individuals, or encouraging suicide or severe self-harm.',
      ),
      enabled: false,
      modifiable: false,
    },
  ],
  [
    ContentModerationCategory.Drugs,
    {
      value: ContentModerationCategory.Drugs,
      title: __('Drugs'),
      description: __(
        'Descriptions of the acquisition of drugs and text that explicitly promotes, advertises, or encourages drug use.',
      ),
      enabled: false,
      modifiable: false,
    },
  ],
  [
    ContentModerationCategory.Weapons,
    {
      value: ContentModerationCategory.Weapons,
      title: __('Weapons'),
      description: __('Buying, selling, trading, and constructing bombs and firearms.'),
      enabled: false,
      modifiable: false,
    },
  ],
  [
    ContentModerationCategory.Profanity,
    {
      value: ContentModerationCategory.Profanity,
      title: __('Profanity'),
      description: __('Use of inappropriate or profane words and phrases.'),
      enabled: false,
      modifiable: false,
    },
  ],
  [
    ContentModerationCategory.ChildSafety,
    {
      value: ContentModerationCategory.ChildSafety,
      title: __('Child safety'),
      description: __(
        'Content that contains a direct or indirect threat of physical violence to children in a school or school-related setting.',
      ),
      enabled: false,
      modifiable: false,
    },
  ],
])
