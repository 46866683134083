// @file Pinia store for handling user follows
import { captureFetchException } from '@@/bits/error_tracker'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import PromiseQueue from '@@/bits/promise_queue'
import { UserFollows as UserFollowsApi } from '@@/dashboard/padlet_api'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { User, UserFollowApiResponse, UserId } from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { fetchJson, HTTPMethod } from '@padlet/fetch'
import { unionBy } from 'lodash-es'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export interface UserFollow {
  followerId: UserId
  user: Partial<User>
  followed: boolean
}

export interface UserFollowUpdateRecord {
  userId: UserId
  followed: boolean
}

enum UserFollowsStatus {
  Loading = 'Loading',
  LoadingMore = 'LoadingMore',
  Completed = 'Completed',
  Errored = 'Errored',
}

const USER_FOLLOWS_RESULT_LIMIT = 50

// Promise queue to handle update requests.
const q = new PromiseQueue()

export const useUserFollowsStore = defineStore('userFollows', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  // State
  const userFollows = ref<UserFollow[]>([])
  const userFollowsStatus = ref<UserFollowsStatus>(UserFollowsStatus.Loading)
  const nextUserFollowsPageUrl = ref<string | null>(null)

  // Getters
  const hasUserFollows = computed<boolean>(() => userFollows.value.length > 0)
  const isErroredUserFollows = computed<boolean>(() => userFollowsStatus.value === UserFollowsStatus.Errored)
  const isLoadingMoreUserFollows = computed<boolean>(() => userFollowsStatus.value === UserFollowsStatus.LoadingMore)
  const isLoadingUserFollows = computed<boolean>(() => userFollowsStatus.value === UserFollowsStatus.Loading)

  // Actions
  const initialize = async (): Promise<void> => {
    userFollowsStatus.value = UserFollowsStatus.Loading
    try {
      const response: JsonAPIResponse<UserFollowApiResponse> = await UserFollowsApi.fetch({
        pageNumber: 0,
        pageSize: USER_FOLLOWS_RESULT_LIMIT,
      })
      const userFollowsData: UserFollow[] = (response?.data as Array<JsonAPIResource<UserFollowApiResponse>>).map(
        (userFollow) => {
          return { ...userFollow.attributes, followed: true }
        },
      )
      userFollows.value = userFollowsData
      nextUserFollowsPageUrl.value = response?.links?.next !== undefined ? response.links.next : null
      userFollowsStatus.value = UserFollowsStatus.Completed
    } catch (e) {
      captureFetchException(e, { source: 'UserFollowsInitialize' })
      userFollowsStatus.value = UserFollowsStatus.Errored
    }
  }

  const fetchNextUserFollows = async (): Promise<void> => {
    if (nextUserFollowsPageUrl.value === null || nextUserFollowsPageUrl.value === '') return
    userFollowsStatus.value = UserFollowsStatus.LoadingMore
    try {
      const response: JsonAPIResponse<UserFollowApiResponse> = await fetchJson(nextUserFollowsPageUrl.value, {
        method: HTTPMethod.get,
      })
      const userFollowsData: UserFollow[] = (response?.data as Array<JsonAPIResource<UserFollowApiResponse>>).map(
        (userFollow) => {
          return { ...userFollow.attributes, followed: true }
        },
      )
      userFollows.value = unionBy(userFollows.value, userFollowsData, (userFollow) => userFollow.user.id)
      nextUserFollowsPageUrl.value = response?.links?.next !== undefined ? response.links.next : null
    } catch (e) {
      captureFetchException(e, { source: 'UserFollowsFetchNextUserFollows' })
      globalSnackbarStore.genericFetchError()
    } finally {
      userFollowsStatus.value = UserFollowsStatus.Completed
    }
  }

  const setUserFollowFollowed = ({ userId, followed }: UserFollowUpdateRecord): void => {
    const userFollow = userFollows.value.find((userFollow: UserFollow) => userFollow.user.id === userId)
    if (userFollow === undefined) return
    userFollow.followed = followed
  }

  const updateUserFollow = async ({ userId, followed }: UserFollowUpdateRecord): Promise<void> => {
    try {
      const body = asciiSafeStringify({
        user_id: userId,
      })
      followed ? await UserFollowsApi.create({ body }) : await UserFollowsApi.delete({ body })
    } catch (e) {
      // If one of the requests fails, we don't want to execute
      // the following requests.
      q.clear()
      captureFetchException(e, { source: 'UserFollowsUpdate' })
      globalSnackbarStore.genericFetchError()
      setUserFollowFollowed({
        userId,
        followed: !followed,
      })
    }
  }

  const queueUpdateUserFollow = ({ userId, followed }: UserFollowUpdateRecord): void => {
    setUserFollowFollowed({ userId, followed })
    void q.enqueue(
      'updateUserFollow',
      async (): Promise<void> =>
        await updateUserFollow({
          userId,
          followed,
        }),
    )
  }

  return {
    // State
    userFollows,
    nextUserFollowsPageUrl,

    // Getters
    hasUserFollows,
    isErroredUserFollows,
    isLoadingMoreUserFollows,
    isLoadingUserFollows,

    // Actions
    initialize,
    fetchNextUserFollows,
    queueUpdateUserFollow,
  }
})
