// @file Backpack permissions students settings store
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import PromiseQueue from '@@/bits/promise_queue'
import { TenantPermissions as TenantPermissionsApi } from '@@/dashboard/padlet_api'
import type { BackpackStudentPermission } from '@@/enums'
import { SnackbarNotificationType, WallPermission, WallPrivacy } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { Tenant } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref, shallowReactive } from 'vue'

enum BackpackPermissionsStudentsStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

type StudentPermissionsObject = Record<BackpackStudentPermission, boolean | WallPrivacy | WallPermission>

export const useSettingsBackpackPermissionsStudentsStore = defineStore('settingsBackpackPermissionsStudents', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const queue = new PromiseQueue()

  // State
  const permissionsState = shallowReactive<StudentPermissionsObject>({
    studentDefaultWallPrivacy: WallPrivacy.Private,
    studentDefaultWallPermission: WallPermission.Read,
    canStudentsMakeWalls: false,
    canStudentsMakePrivateWalls: false,
    canStudentsMakeOrgWideHiddenWalls: false,
    canStudentsMakeOrgWideDashboardWalls: false,
    canStudentsMakePasswordWalls: false,
    canStudentsMakeSecretWalls: false,
    canStudentsViewGallery: false,
  })

  const backpackPermissionsStudentsStatus = ref(BackpackPermissionsStudentsStatus.Loading)
  const tenant = ref<Tenant>()
  const defaultPrivacy = ref<WallPrivacy>(WallPrivacy.Private)

  // Getters
  const isTenantPermissionsStudentsLoading = computed(
    () => backpackPermissionsStudentsStatus.value === BackpackPermissionsStudentsStatus.Loading,
  )
  const disabledStudentDefaultPrivacy = computed(() => {
    return (
      (permissionsState.canStudentsMakePrivateWalls === false && defaultPrivacy.value === WallPrivacy.Private) ||
      (permissionsState.canStudentsMakeOrgWideHiddenWalls === false &&
        defaultPrivacy.value === WallPrivacy.OrgWideUnlisted) ||
      (permissionsState.canStudentsMakeOrgWideDashboardWalls === false &&
        defaultPrivacy.value === WallPrivacy.OrgWideListed) ||
      (permissionsState.canStudentsMakePasswordWalls === false &&
        defaultPrivacy.value === WallPrivacy.PasswordProtected) ||
      (permissionsState.canStudentsMakeSecretWalls === false && defaultPrivacy.value === WallPrivacy.Secret)
    )
  })
  const mostSecureEnabledStudentPrivacyOption = computed(() => {
    if (permissionsState.canStudentsMakePrivateWalls === true) return WallPrivacy.Private
    if (permissionsState.canStudentsMakeOrgWideHiddenWalls === true) return WallPrivacy.OrgWideUnlisted
    if (permissionsState.canStudentsMakeOrgWideDashboardWalls === true) return WallPrivacy.OrgWideListed
    if (permissionsState.canStudentsMakePasswordWalls === true) return WallPrivacy.PasswordProtected
    if (permissionsState.canStudentsMakeSecretWalls === true) return WallPrivacy.Secret
    return WallPrivacy.Private
  })
  const isMakeAPadletForStudentsDisabled = computed(() => permissionsState.canStudentsMakeWalls === false)
  const areAllStudentPermissionsDisabled = computed(
    () =>
      permissionsState.canStudentsMakePrivateWalls === false &&
      permissionsState.canStudentsMakeSecretWalls === false &&
      permissionsState.canStudentsMakePasswordWalls === false &&
      permissionsState.canStudentsMakeOrgWideHiddenWalls === false &&
      permissionsState.canStudentsMakeOrgWideDashboardWalls === false,
  )

  // Actions
  function setPermissionsAndDefaultPrivacyState(permissions: StudentPermissionsObject): void {
    defaultPrivacy.value = (permissions?.studentDefaultWallPrivacy as WallPrivacy) ?? WallPrivacy.Private
    for (const permission in permissionsState) {
      permissionsState[permission] = permissions[permission] ?? false
    }
  }
  async function initialize(tenantObject?: Tenant): Promise<void> {
    if (tenantObject === undefined) {
      void globalSnackbarStore.genericFetchError()
      return
    }
    tenant.value = tenantObject
    await fetchTenantPermissionsStudents(tenantObject.id)
  }

  async function fetchTenantPermissionsStudents(tenantId: number): Promise<void> {
    try {
      backpackPermissionsStudentsStatus.value = BackpackPermissionsStudentsStatus.Loading
      const response = await TenantPermissionsApi.fetch(tenantId)
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings
        ?.permissions as StudentPermissionsObject
      if (permissions != null) setPermissionsAndDefaultPrivacyState(permissions)
      backpackPermissionsStudentsStatus.value = BackpackPermissionsStudentsStatus.Completed
    } catch (e) {
      void globalSnackbarStore.genericFetchError()
      backpackPermissionsStudentsStatus.value = BackpackPermissionsStudentsStatus.Errored
      captureFetchException(e, { source: 'SettingsBackpackPermissionsStudentsFetchTenantPermissionsStudents' })
    }
  }

  async function updatePermissions(
    studentPermission: BackpackStudentPermission,
    permissionValue: boolean,
    initialDefaultPrivacy: WallPrivacy,
  ): Promise<void> {
    const tenantId = tenant.value?.id as number
    try {
      const response = await TenantPermissionsApi.update(tenantId, {
        settings: { permissions: { [studentPermission]: permissionValue } },
      })
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings
        ?.permissions as StudentPermissionsObject
      if (permissions != null) setPermissionsAndDefaultPrivacyState(permissions)
    } catch (e) {
      queue.clear()
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating permissions.'),
        notificationType: SnackbarNotificationType.error,
      })
      permissionsState[studentPermission] = !permissionValue
      defaultPrivacy.value = initialDefaultPrivacy
      captureFetchException(e, { source: 'SettingsBackpackPermissionsStudentsUpdatePermissions' })
    }
  }

  async function updateDefaultPrivacy(wallPrivacy: WallPrivacy, initialDefaultPrivacy: WallPrivacy): Promise<void> {
    const tenantId = tenant.value?.id as number
    try {
      const response = await TenantPermissionsApi.update(tenantId, {
        settings: { permissions: { studentDefaultWallPrivacy: wallPrivacy } },
      })
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings
        ?.permissions as StudentPermissionsObject
      if (permissions != null) setPermissionsAndDefaultPrivacyState(permissions)
    } catch (e) {
      queue.clear()
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating default privacy.'),
        notificationType: SnackbarNotificationType.error,
      })
      defaultPrivacy.value = initialDefaultPrivacy
      captureFetchException(e, { source: 'SettingsBackpackPermissionsStudentsUpdateDefaultPrivacy' })
    }
  }

  async function queueUpdatePermissions(studentPermission: BackpackStudentPermission): Promise<void> {
    permissionsState[studentPermission] = !(permissionsState[studentPermission] as boolean)
    const initialDefaultPrivacy = defaultPrivacy.value
    if (disabledStudentDefaultPrivacy.value) {
      defaultPrivacy.value = mostSecureEnabledStudentPrivacyOption.value
    }
    void queue.enqueue(
      'updatePermissions',
      async () =>
        await updatePermissions(
          studentPermission,
          permissionsState[studentPermission] as boolean,
          initialDefaultPrivacy,
        ),
    )
  }

  async function queueUpdateDefaultPrivacy(wallPrivacy: WallPrivacy): Promise<void> {
    const initialDefaultPrivacy = defaultPrivacy.value
    defaultPrivacy.value = wallPrivacy
    if (wallPrivacy === WallPrivacy.Private) {
      permissionsState.studentDefaultWallPermission = WallPermission.Read
    }
    void queue.enqueue(
      'updateDefaultPrivacy',
      async () => await updateDefaultPrivacy(wallPrivacy, initialDefaultPrivacy),
    )
  }

  async function updateStudentDefaultWallPermission(
    permission: WallPermission,
    initialPermission: WallPermission,
  ): Promise<void> {
    const tenantId = tenant.value?.id as number
    try {
      const response = await TenantPermissionsApi.update(tenantId, {
        settings: {
          permissions: { studentDefaultWallPermission: permission },
        },
      })
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings?.permissions
      if (permissions?.studentDefaultWallPermission !== undefined) {
        permissionsState.studentDefaultWallPermission = permissions.studentDefaultWallPermission
      }
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating permission.'),
        notificationType: SnackbarNotificationType.error,
      })
      queue.clear()
      permissionsState.studentDefaultWallPermission = initialPermission
      captureFetchException(e, { source: 'SettingsBackpackPermissionsStudentsUpdateStudentDefaultWallPermission' })
    }
  }

  async function queueUpdateWallDefaultPermission(wallPermission: WallPermission): Promise<void> {
    const initialDefaultWallPermission = permissionsState.studentDefaultWallPermission as WallPermission
    permissionsState.studentDefaultWallPermission = wallPermission
    void queue.enqueue(
      'updateStudentDefaultWallPermission',
      async () => await updateStudentDefaultWallPermission(wallPermission, initialDefaultWallPermission),
    )
  }

  return {
    // State
    permissionsState,
    tenant,
    defaultPrivacy,
    // Getters
    isTenantPermissionsStudentsLoading,
    disabledStudentDefaultPrivacy,
    mostSecureEnabledStudentPrivacyOption,
    isMakeAPadletForStudentsDisabled,
    areAllStudentPermissionsDisabled,

    // Actions
    initialize,
    queueUpdatePermissions,
    queueUpdateDefaultPrivacy,
    queueUpdateWallDefaultPermission,
  }
})
