// @file Pinia store for Library Info in Dashboard settings
import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { MAX_LIBRARY_BIO_LENGTH, MAX_NAMESPACE_LENGTH, MAX_NAME_LENGTH } from '@@/bits/numbers'
import { LibraryInfo as LibraryInfoApi, LibraryLti as LibraryLtiApi } from '@@/dashboard/padlet_api'
import { ApiErrorCode, HttpCode } from '@@/enums'
import { useDashboardSettingsStore } from '@@/pinia/dashboard_settings'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSettingsNavigationStore } from '@@/pinia/settings_navigation'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { Library } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum LibraryInfoStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

export enum GenerateLinkStatus {
  Initial,
  Loading,
  Completed,
}

export enum UpdateUserGroupSyncStatus {
  Loading = 'Loading',
  Completed = 'Completed',
}

export const useSettingsLibraryInfoStore = defineStore('settingsLibraryInfo', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const dashboardSettingsStore = useDashboardSettingsStore()
  const settingsNavigationStore = useSettingsNavigationStore()
  const windowSizeStore = useWindowSizeStore()
  const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()

  // State
  const initialAvatar = ref<string>('')
  const initialName = ref<string>('')
  const initialSlug = ref<string>('')
  const initialBio = ref<string>('')
  const avatar = ref<string>('')
  const name = ref<string>('')
  const slug = ref<string>('')
  const bio = ref<string>('')
  const nameValidationMessage = ref<string>('')
  const slugValidationMessage = ref<string>('')
  const bioValidationMessage = ref<string>('')
  const isNameSaved = ref<boolean>(false)
  const isSlugSaved = ref<boolean>(false)
  const isBioSaved = ref<boolean>(false)
  const defaultAvatar = ref<string>('')
  const isConfirmingSlugChange = ref<boolean>(false)
  const isAwaitingUpdateLibraryNameResponse = ref<boolean>(false)
  const isAwaitingUpdateLibrarySlugResponse = ref<boolean>(false)
  const isAwaitingUpdateLibraryBioResponse = ref<boolean>(false)
  const libraryInfoStatus = ref<LibraryInfoStatus>(LibraryInfoStatus.Loading)
  const ltiDynamicRegistrationLink = ref<string>('')
  const ltiDynamicRegistrationLinkStatus = ref<GenerateLinkStatus>(GenerateLinkStatus.Initial)
  const ltiLinkDeploymentToken = ref<string>('')
  const ltiLinkDeploymentTokenStatus = ref<GenerateLinkStatus>(GenerateLinkStatus.Initial)
  const ltiEnabled = ref<boolean>(false)
  const lmsUserGroupSyncConfigEnabled = ref<boolean>(false) // whether toggeld on on lms
  const ltiUserGroupSyncEnabled = ref<boolean>(false)
  const ltiUserGroupSyncEnabledStatus = ref<UpdateUserGroupSyncStatus>(UpdateUserGroupSyncStatus.Completed)

  // Getters
  const libraryId = computed<number | null>(() => settingsNavigationStore.currentLibraryId)
  const isSmallerThanTabletPortrait = computed<boolean>(() => windowSizeStore.isSmallerThanTabletPortrait)
  const hasNameChanged = computed<boolean>(() => name.value !== initialName.value)
  const hasSlugChanged = computed<boolean>(() => slug.value !== initialSlug.value)
  const hasBioChanged = computed<boolean>(() => bio.value !== initialBio.value)
  const isLoadingLibraryInfo = computed<boolean>(() => libraryInfoStatus.value === LibraryInfoStatus.Loading)

  // Actions
  async function fetchLibraryInfo(): Promise<void> {
    if (libraryId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      libraryInfoStatus.value = LibraryInfoStatus.Loading

      const response = await LibraryInfoApi.fetch({ libraryId: libraryId.value })
      const data = (response.data as JsonAPIResource<Library>).attributes

      initialAvatar.value = data.avatar ?? ''
      initialName.value = data.name
      initialSlug.value = data.slug
      initialBio.value = data.bio ?? ''
      avatar.value = data.avatar ?? ''
      name.value = data.name
      slug.value = data.slug
      bio.value = data.bio ?? ''
      ltiEnabled.value = data.ltiEnabled ?? false
      ltiUserGroupSyncEnabled.value = data.ltiUserGroupSyncEnabled ?? false
      lmsUserGroupSyncConfigEnabled.value = data.lmsUserGroupSyncConfigEnabled ?? false
      nameValidationMessage.value = ''
      slugValidationMessage.value = ''
      bioValidationMessage.value = ''
      defaultAvatar.value = data.defaultAvatar ?? ''

      libraryInfoStatus.value = LibraryInfoStatus.Completed
    } catch (e) {
      captureFetchException(e, { source: 'SettingsLibraryInfoFetchLibraryInfo' })
      libraryInfoStatus.value = LibraryInfoStatus.Errored
    }
  }

  async function updateLibraryAvatar({ avatarUrl }: { avatarUrl: string }): Promise<void> {
    if (libraryId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    if (avatarUrl === '') return

    try {
      await LibraryInfoApi.update({
        libraryId: libraryId.value,
        updateAttributes: { avatar: avatarUrl },
      })
      avatar.value = avatarUrl
      initialAvatar.value = avatarUrl
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: __('Error changing team avatar'),
        notificationType: SnackbarNotificationType.error,
      })

      if (
        ![HttpCode.Unauthorized, HttpCode.NotFound, HttpCode.UnprocessableEntity, HttpCode.ServerError].includes(
          e.status,
        )
      ) {
        captureFetchException(e, { source: 'SettingsLibraryInfoUpdateLibraryAvatar' })
      }
    }
  }

  async function updateLibraryName(): Promise<void> {
    if (libraryId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    if (name.value === '') {
      nameValidationMessage.value = __('Name can not be blank')
      return
    } else if (name.value.length > MAX_NAME_LENGTH) {
      nameValidationMessage.value = __('Maximum %{maxLength} characters', { maxLength: MAX_NAME_LENGTH })
      return
    }

    if (!hasNameChanged.value) {
      isNameSaved.value = true
      return
    }

    try {
      const trimmedName = name.value.trim()

      isAwaitingUpdateLibraryNameResponse.value = true

      await LibraryInfoApi.update({
        libraryId: libraryId.value,
        updateAttributes: { name: trimmedName },
      })

      isNameSaved.value = true
      name.value = trimmedName
      initialName.value = trimmedName
      dashboardSettingsStore.fetchViewableLibraries()
    } catch (e) {
      if (e.status === HttpCode.UnprocessableEntity) {
        const parsedErrorMessage = JSON.parse(e.message)
        const errorCode = parsedErrorMessage?.errors?.[0]?.code

        if (errorCode === ApiErrorCode.RECORD_INVALID) {
          nameValidationMessage.value = __('Invalid team name')
        } else {
          globalSnackbarStore.setSnackbar({
            message: __('Error changing team name'),
            notificationType: SnackbarNotificationType.error,
          })
        }
      } else {
        globalSnackbarStore.setSnackbar({
          message: __('Error changing team name'),
          notificationType: SnackbarNotificationType.error,
        })

        if (![HttpCode.Unauthorized, HttpCode.NotFound, HttpCode.ServerError].includes(e.status)) {
          captureFetchException(e, { source: 'SettingsLibraryInfoUpdateLibraryName' })
        }
      }
    } finally {
      isAwaitingUpdateLibraryNameResponse.value = false
    }
  }

  async function generateLtiDynamicRegistrationLink(): Promise<void> {
    if (libraryId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      ltiDynamicRegistrationLinkStatus.value = GenerateLinkStatus.Loading

      const response = await LibraryLtiApi.generateLtiDynamicRegistrationUrl(libraryId.value)
      ltiDynamicRegistrationLink.value = response.url
      ltiDynamicRegistrationLinkStatus.value = GenerateLinkStatus.Completed

      globalSnackbarStore.setSnackbar({
        message: __('LTI link generated'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      ltiDynamicRegistrationLinkStatus.value = GenerateLinkStatus.Initial

      globalSnackbarStore.setSnackbar({
        message: __('Error generating LTI link'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoGenerateLtiAdvantageDynamicRegistrationLink' })
    }
  }

  async function generateLtiLinkDeploymentToken(): Promise<void> {
    if (libraryId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      ltiLinkDeploymentTokenStatus.value = GenerateLinkStatus.Loading

      const response = await LibraryLtiApi.generateLtiLinkDeploymentToken(libraryId.value)
      ltiLinkDeploymentToken.value = response.token
      ltiLinkDeploymentTokenStatus.value = GenerateLinkStatus.Completed

      globalSnackbarStore.setSnackbar({
        message: __('LTI token generated'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      ltiLinkDeploymentTokenStatus.value = GenerateLinkStatus.Initial

      globalSnackbarStore.setSnackbar({
        message: __('Error generating LTI link token'),
        notificationType: SnackbarNotificationType.error,
      })
      captureFetchException(e, { source: 'LibraryInfoGenerateLtiLinkDeploymentToken' })
    }
  }

  async function setLtiUserGroupSyncEnabled(value: boolean): Promise<void> {
    if (libraryId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      ltiUserGroupSyncEnabledStatus.value = UpdateUserGroupSyncStatus.Loading

      await LibraryLtiApi.updateLtiUserGroupSyncEnabled(libraryId.value, value)
      ltiUserGroupSyncEnabled.value = value

      globalSnackbarStore.setSnackbar({
        message: value ? __('User Group Sync enabled') : __('User Group Sync disabled'),
        notificationType: SnackbarNotificationType.success,
      })
    } catch (e) {
      globalSnackbarStore.genericFetchError()
      captureFetchException(e, { source: 'LibraryInfoUserGroupSync' })
    } finally {
      ltiUserGroupSyncEnabledStatus.value = UpdateUserGroupSyncStatus.Completed
    }
  }

  function validateLibrarySlug(onErrorCallback: () => void): void {
    // Refer to VALID_FORMAT in app/models/namespace.rb
    const teamSlugRegex = new RegExp(`^(?!.*[_]{2}.*)[a-zA-Z0-9]{1}[a-zA-Z0-9_]{0,${MAX_NAMESPACE_LENGTH - 1}}$`)

    if (slug.value === '') {
      slugValidationMessage.value = __('URL can not be blank')
      return
    } else if (slug.value.length > MAX_NAMESPACE_LENGTH) {
      slugValidationMessage.value = __('Maximum %{maxLength} characters', { maxLength: MAX_NAMESPACE_LENGTH })
      return
    } else if (teamSlugRegex.exec(slug.value) == null) {
      if (isSmallerThanTabletPortrait.value) {
        slugValidationMessage.value = __('Special characters not allowed')
        return
      } else {
        slugValidationMessage.value = __('Only letters, numbers, and underscores are allowed')
        return
      }
    }

    if (!hasSlugChanged.value) {
      isSlugSaved.value = true
      return
    }

    isConfirmingSlugChange.value = true

    globalConfirmationDialogStore.openConfirmationDialog({
      ...ALERT_ICON,
      title: __('Change team URL?'),
      body: __(
        'All previous links shared for this team’s padlets will no longer work. Another team will be allowed to use your old URL once changed.',
      ),
      confirmButtonText: __('Change'),
      cancelButtonText: __('Cancel'),
      afterConfirmActions: [async () => await updateLibrarySlug(onErrorCallback)],
      afterCancelActions: [
        () => {
          isConfirmingSlugChange.value = false
        },
      ],
      forceFullWidthButtons: true,
      xShadow: true,
    })
  }

  async function updateLibrarySlug(onErrorCallback: () => void): Promise<void> {
    if (libraryId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    try {
      isConfirmingSlugChange.value = false
      const trimmedSlug = slug.value.trim()

      isAwaitingUpdateLibrarySlugResponse.value = true

      await LibraryInfoApi.update({
        libraryId: libraryId.value,
        updateAttributes: { slug: trimmedSlug },
      })

      isSlugSaved.value = true
      slug.value = trimmedSlug
      initialSlug.value = trimmedSlug
    } catch (e) {
      if (e.status === HttpCode.UnprocessableEntity) {
        const parsedErrorMessage = JSON.parse(e.message)
        const errorCode = parsedErrorMessage?.errors?.[0]?.code

        if (errorCode === ApiErrorCode.RECORD_INVALID) {
          slugValidationMessage.value = __('Invalid team URL')
          onErrorCallback()
        } else if (errorCode === ApiErrorCode.NOT_UNIQUE) {
          slugValidationMessage.value = __('URL is already taken')
          onErrorCallback()
        } else {
          globalSnackbarStore.setSnackbar({
            message: __('Error changing team URL'),
            notificationType: SnackbarNotificationType.error,
          })
        }
      } else {
        globalSnackbarStore.setSnackbar({
          message: __('Error changing team URL'),
          notificationType: SnackbarNotificationType.error,
        })

        if (![HttpCode.Unauthorized, HttpCode.NotFound, HttpCode.ServerError].includes(e.status)) {
          captureFetchException(e, { source: 'SettingsLibraryInfoUpdateLibrarySlug' })
        }
      }
    } finally {
      isAwaitingUpdateLibrarySlugResponse.value = false
    }
  }

  async function updateLibraryBio(): Promise<void> {
    if (libraryId.value === null) {
      globalSnackbarStore.genericFetchError()
      return
    }

    if (bio.value.length > MAX_LIBRARY_BIO_LENGTH) {
      bioValidationMessage.value = __('Maximum %{maxLength} characters', {
        maxLength: MAX_LIBRARY_BIO_LENGTH,
      })
      return
    }

    if (!hasBioChanged.value) {
      isBioSaved.value = true
      return
    }

    try {
      const trimmedBio = bio.value.trim()
      isAwaitingUpdateLibraryBioResponse.value = true

      await LibraryInfoApi.update({
        libraryId: libraryId.value,
        updateAttributes: { bio: trimmedBio },
      })

      isBioSaved.value = true
      bio.value = trimmedBio
      initialBio.value = trimmedBio
    } catch (e) {
      globalSnackbarStore.setSnackbar({
        message: bioValidationMessage.value,
        notificationType: SnackbarNotificationType.error,
      })

      if (
        ![HttpCode.Unauthorized, HttpCode.NotFound, HttpCode.UnprocessableEntity, HttpCode.ServerError].includes(
          e.status,
        )
      ) {
        captureFetchException(e, { source: 'SettingsLibraryInfoUpdateLibraryBio' })
      }
    } finally {
      isAwaitingUpdateLibraryBioResponse.value = false
    }
  }

  return {
    // State
    initialAvatar,
    initialName,
    initialSlug,
    initialBio,
    avatar,
    name,
    slug,
    bio,
    nameValidationMessage,
    slugValidationMessage,
    bioValidationMessage,
    isNameSaved,
    isSlugSaved,
    isBioSaved,
    defaultAvatar,
    isConfirmingSlugChange,
    isAwaitingUpdateLibraryNameResponse,
    isAwaitingUpdateLibrarySlugResponse,
    isAwaitingUpdateLibraryBioResponse,
    ltiDynamicRegistrationLink,
    ltiDynamicRegistrationLinkStatus,
    ltiLinkDeploymentToken,
    ltiLinkDeploymentTokenStatus,
    lmsUserGroupSyncConfigEnabled,
    ltiUserGroupSyncEnabled,
    ltiUserGroupSyncEnabledStatus,
    ltiEnabled,

    // Getters
    hasBioChanged,
    isLoadingLibraryInfo,

    // Actions
    fetchLibraryInfo,
    updateLibraryAvatar,
    updateLibraryName,
    validateLibrarySlug,
    updateLibraryBio,
    generateLtiLinkDeploymentToken,
    generateLtiDynamicRegistrationLink,
    setLtiUserGroupSyncEnabled,
  }
})
