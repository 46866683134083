// @file Library analytics settings store
import { captureFetchException } from '@@/bits/error_tracker'
import window from '@@/bits/global'
import { parseAnalytics } from '@@/bits/metabase_helper'
import { LibraryAnalytics as LibraryAnalyticsApi } from '@@/dashboard/padlet_api'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { Library, LibraryAnalytics, LibraryId } from '@@/types'
import type { JsonAPIResource, JsonAPIResponse } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum LibraryAnalyticsSettingsTab {
  General = 'general',
  Padlets = 'padlets',
  Users = 'users',
}

enum LibraryAnalyticsStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

const libraryAnalyticsSettingsTabToHash = {
  [LibraryAnalyticsSettingsTab.General]: '#general',
  [LibraryAnalyticsSettingsTab.Padlets]: '#padlets',
  [LibraryAnalyticsSettingsTab.Users]: '#users',
}
const hashToLibraryAnalyticsSettingsTab = Object.fromEntries(
  Object.entries(libraryAnalyticsSettingsTabToHash).map(([k, v]) => [v, k as LibraryAnalyticsSettingsTab]),
)

const getLibraryAnalyticsSettingsTabFromHash = (): LibraryAnalyticsSettingsTab => {
  return hashToLibraryAnalyticsSettingsTab[window.location.hash] ?? LibraryAnalyticsSettingsTab.General
}

export const useSettingsLibraryAnalyticsStore = defineStore('settingsLibraryAnalytics', () => {
  const darkModeStore = useDarkModeStore()
  const globalSnackbarStore = useGlobalSnackbarStore()

  // State
  const currentTab = ref<LibraryAnalyticsSettingsTab>(getLibraryAnalyticsSettingsTabFromHash())
  const analyticsData = ref<LibraryAnalytics>({
    libraryAnalyticsEmbedLink: '',
    monthlyAnalyticsEmbedLink: '',
    libraryUserAnalyticsEmbedLink: '',
  })
  const analyticsStatus = ref<LibraryAnalyticsStatus>(LibraryAnalyticsStatus.Loading)
  const libraryId = ref<LibraryId>()

  // Getters
  const isErroredLibraryAnalytics = computed((): boolean => analyticsStatus.value === LibraryAnalyticsStatus.Errored)
  const isLoadingAnalyticsInformation = computed(
    (): boolean => analyticsStatus.value === LibraryAnalyticsStatus.Loading,
  )

  // Actions
  async function initialize(library?: Library): Promise<void> {
    if (library === undefined) {
      void globalSnackbarStore.genericFetchError()
      return
    }
    libraryId.value = library.id
    await fetchLibraryAnalytics()
  }

  async function fetchLibraryAnalytics(): Promise<void> {
    analyticsStatus.value = LibraryAnalyticsStatus.Loading
    try {
      const response: JsonAPIResponse<LibraryAnalytics> = await LibraryAnalyticsApi.fetch(libraryId.value as number)
      const data: LibraryAnalytics = (response?.data as JsonAPIResource<LibraryAnalytics>).attributes
      analyticsData.value = parseAnalytics(data, darkModeStore.isDarkMode) as LibraryAnalytics
      analyticsStatus.value = LibraryAnalyticsStatus.Completed
    } catch (e) {
      captureFetchException(e, { source: 'LibraryAnalyticsFetch' })
      analyticsStatus.value = LibraryAnalyticsStatus.Errored
    }
  }

  function setLibraryAnalyticsSettingsTabFromHash(): void {
    currentTab.value = getLibraryAnalyticsSettingsTabFromHash()
  }

  return {
    // State
    analyticsData,
    currentTab,

    // Getters
    isErroredLibraryAnalytics,
    isLoadingAnalyticsInformation,

    // Actions
    fetchLibraryAnalytics,
    initialize,
    setLibraryAnalyticsSettingsTabFromHash,
  }
})

export { LibraryAnalyticsSettingsTab, libraryAnalyticsSettingsTabToHash }
