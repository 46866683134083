// @file Backpack permissions teachers settings store
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import PromiseQueue from '@@/bits/promise_queue'
import { TenantPermissions as TenantPermissionsApi } from '@@/dashboard/padlet_api'
import type { BackpackTeacherPermission } from '@@/enums'
import { SnackbarNotificationType, WallPermission, WallPrivacy } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { Tenant } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref, shallowReactive } from 'vue'

enum BackpackPermissionsTeachersStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

type TeacherPermissionsObject = Record<BackpackTeacherPermission, boolean | WallPrivacy | WallPermission>

export const useSettingsBackpackPermissionsTeachersStore = defineStore('settingsBackpackPermissionsTeachers', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const queue = new PromiseQueue()

  // State
  const permissionsState = shallowReactive<TeacherPermissionsObject>({
    teacherDefaultWallPrivacy: WallPrivacy.Private,
    teacherDefaultWallPermission: WallPermission.Read,
    canTeachersMakeWalls: false,
    canTeachersMakePrivateWalls: false,
    canTeachersMakeOrgWideHiddenWalls: false,
    canTeachersMakeOrgWideDashboardWalls: false,
    canTeachersMakePasswordWalls: false,
    canTeachersMakeSecretWalls: false,
    canTeachersAddNewUsers: false,
    canTeachersEditUsers: false,
  })

  const backpackPermissionsTeachersStatus = ref(BackpackPermissionsTeachersStatus.Loading)
  const tenant = ref<Tenant>()
  const defaultPrivacy = ref<WallPrivacy>(WallPrivacy.Private)

  // Getters
  const isTenantPermissionsTeachersLoading = computed(
    () => backpackPermissionsTeachersStatus.value === BackpackPermissionsTeachersStatus.Loading,
  )
  const disabledTeacherDefaultPrivacy = computed(() => {
    return (
      (permissionsState.canTeachersMakePrivateWalls === false && defaultPrivacy.value === WallPrivacy.Private) ||
      (permissionsState.canTeachersMakeOrgWideHiddenWalls === false &&
        defaultPrivacy.value === WallPrivacy.OrgWideUnlisted) ||
      (permissionsState.canTeachersMakeOrgWideDashboardWalls === false &&
        defaultPrivacy.value === WallPrivacy.OrgWideListed) ||
      (permissionsState.canTeachersMakePasswordWalls === false &&
        defaultPrivacy.value === WallPrivacy.PasswordProtected) ||
      (permissionsState.canTeachersMakeSecretWalls === false && defaultPrivacy.value === WallPrivacy.Secret)
    )
  })
  const mostSecureEnabledTeacherPrivacyOption = computed(() => {
    if (permissionsState.canTeachersMakePrivateWalls === true) return WallPrivacy.Private
    if (permissionsState.canTeachersMakeOrgWideHiddenWalls === true) return WallPrivacy.OrgWideUnlisted
    if (permissionsState.canTeachersMakeOrgWideDashboardWalls === true) return WallPrivacy.OrgWideListed
    if (permissionsState.canTeachersMakePasswordWalls === true) return WallPrivacy.PasswordProtected
    if (permissionsState.canTeachersMakeSecretWalls === true) return WallPrivacy.Secret
    return WallPrivacy.Private
  })
  const isMakeAPadletForTeachersDisabled = computed(() => permissionsState.canTeachersMakeWalls === false)
  const areAllTeacherPermissionsDisabled = computed(
    () =>
      permissionsState.canTeachersMakePrivateWalls === false &&
      permissionsState.canTeachersMakeSecretWalls === false &&
      permissionsState.canTeachersMakePasswordWalls === false &&
      permissionsState.canTeachersMakeOrgWideHiddenWalls === false &&
      permissionsState.canTeachersMakeOrgWideDashboardWalls === false,
  )

  // Actions
  function setPermissionsAndDefaultPrivacyState(permissions: TeacherPermissionsObject): void {
    defaultPrivacy.value = (permissions?.teacherDefaultWallPrivacy as WallPrivacy) ?? WallPrivacy.Private
    for (const permission in permissionsState) {
      permissionsState[permission] = permissions[permission] ?? false
    }
  }
  async function initialize(tenantObject?: Tenant): Promise<void> {
    if (tenantObject === undefined) {
      void globalSnackbarStore.genericFetchError()
      return
    }
    tenant.value = tenantObject
    await fetchTenantPermissionsTeachers(tenantObject.id)
  }

  async function fetchTenantPermissionsTeachers(tenantId: number): Promise<void> {
    try {
      backpackPermissionsTeachersStatus.value = BackpackPermissionsTeachersStatus.Loading
      const response = await TenantPermissionsApi.fetch(tenantId)
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings
        ?.permissions as TeacherPermissionsObject
      if (permissions != null) setPermissionsAndDefaultPrivacyState(permissions)
      backpackPermissionsTeachersStatus.value = BackpackPermissionsTeachersStatus.Completed
    } catch (e) {
      void globalSnackbarStore.genericFetchError()
      backpackPermissionsTeachersStatus.value = BackpackPermissionsTeachersStatus.Errored
      captureFetchException(e, { source: 'SettingsBackpackPermissionsFetchTenantPermissions' })
    }
  }

  async function updatePermissions(
    teacherPermission: BackpackTeacherPermission,
    permissionValue: boolean,
    initialDefaultPrivacy: WallPrivacy,
  ): Promise<void> {
    const tenantId = tenant.value?.id as number

    try {
      const response = await TenantPermissionsApi.update(tenantId, {
        settings: { permissions: { [teacherPermission]: permissionValue } },
      })
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings
        ?.permissions as TeacherPermissionsObject
      if (permissions != null) setPermissionsAndDefaultPrivacyState(permissions)
    } catch (e) {
      queue.clear()
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating permissions.'),
        notificationType: SnackbarNotificationType.error,
      })
      permissionsState[teacherPermission] = !permissionValue
      defaultPrivacy.value = initialDefaultPrivacy
      captureFetchException(e, { source: 'SettingsBackpackPermissionsTeachersUpdatePermissions' })
    }
  }

  async function updateDefaultPrivacy(wallPrivacy: WallPrivacy, initialDefaultPrivacy: WallPrivacy): Promise<void> {
    const tenantId = tenant.value?.id as number
    try {
      const response = await TenantPermissionsApi.update(tenantId, {
        settings: { permissions: { teacherDefaultWallPrivacy: wallPrivacy } },
      })
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings
        ?.permissions as TeacherPermissionsObject
      if (permissions != null) setPermissionsAndDefaultPrivacyState(permissions)
    } catch (e) {
      queue.clear()
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating default privacy.'),
        notificationType: SnackbarNotificationType.error,
      })
      defaultPrivacy.value = initialDefaultPrivacy
      captureFetchException(e, { source: 'SettingsBackpackPermissionsTeachersUpdateDefaultPrivacy' })
    }
  }

  async function queueUpdatePermissions(teacherPermission: BackpackTeacherPermission): Promise<void> {
    permissionsState[teacherPermission] = !(permissionsState[teacherPermission] as boolean)
    const initialDefaultPrivacy = defaultPrivacy.value
    if (disabledTeacherDefaultPrivacy.value) {
      defaultPrivacy.value = mostSecureEnabledTeacherPrivacyOption.value
    }
    void queue.enqueue(
      'updatePermissions',
      async () =>
        await updatePermissions(
          teacherPermission,
          permissionsState[teacherPermission] as boolean,
          initialDefaultPrivacy,
        ),
    )
  }

  async function queueUpdateDefaultPrivacy(wallPrivacy: WallPrivacy): Promise<void> {
    const initialDefaultPrivacy = defaultPrivacy.value
    if (wallPrivacy === WallPrivacy.Private) {
      permissionsState.teacherDefaultWallPermission = WallPermission.Read
    }
    defaultPrivacy.value = wallPrivacy
    void queue.enqueue(
      'updateDefaultPrivacy',
      async () => await updateDefaultPrivacy(wallPrivacy, initialDefaultPrivacy),
    )
  }

  async function updateTeacherDefaultWallPermission(
    permission: WallPermission,
    initialPermission: WallPermission,
  ): Promise<void> {
    const tenantId = tenant.value?.id as number
    try {
      const response = await TenantPermissionsApi.update(tenantId, {
        settings: {
          permissions: { teacherDefaultWallPermission: permission },
        },
      })
      const permissions = (response.data as JsonAPIResource<Tenant>)?.attributes.settings?.permissions
      if (permissions?.teacherDefaultWallPermission !== undefined) {
        permissionsState.teacherDefaultWallPermission = permissions.teacherDefaultWallPermission
      }
    } catch (e) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating permission.'),
        notificationType: SnackbarNotificationType.error,
      })
      queue.clear()
      permissionsState.teacherDefaultWallPermission = initialPermission
      captureFetchException(e, { source: 'SettingsBackpackPermissionsTeachersUpdateTeacherDefaultWallPermission' })
    }
  }

  async function queueUpdateWallDefaultPermission(wallPermission: WallPermission): Promise<void> {
    const initialDefaultWallPermission = permissionsState.teacherDefaultWallPermission as WallPermission
    permissionsState.teacherDefaultWallPermission = wallPermission
    void queue.enqueue(
      'updateTeacherDefaultWallPermission',
      async () => await updateTeacherDefaultWallPermission(wallPermission, initialDefaultWallPermission),
    )
  }

  return {
    // State
    permissionsState,
    tenant,
    defaultPrivacy,
    // Getters
    isTenantPermissionsTeachersLoading,
    disabledTeacherDefaultPrivacy,
    mostSecureEnabledTeacherPrivacyOption,
    isMakeAPadletForTeachersDisabled,
    areAllTeacherPermissionsDisabled,

    // Actions
    initialize,
    queueUpdatePermissions,
    queueUpdateDefaultPrivacy,
    queueUpdateWallDefaultPermission,
  }
})
