// @file Dashboard (dashboard setting settings) store
import { captureFetchException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import PromiseQueue from '@@/bits/promise_queue'
import { clearCachedQueries } from '@@/bits/query_client'
import { User as UserApi } from '@@/dashboard/padlet_api'
import { SnackbarNotificationType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { UserLibrarySetting, UserLibrarySettingsApiResponse } from '@@/types'
import type { JsonAPIResource } from '@padlet/arvo'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

enum DashboardSettingsStatus {
  Loading = 'Loading',
  Completed = 'Completed',
  Errored = 'Errored',
}

export const useSettingsUserDashboardStore = defineStore('settingsUserDashboard', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const queue = new PromiseQueue()

  // State
  const defaultLibrary = ref<UserLibrarySetting>()
  const librarySettings = ref<UserLibrarySetting[]>([])
  const userDashboardStatus = ref(DashboardSettingsStatus.Loading)

  // Getters
  const isLoadingUserDashboard = computed(() => userDashboardStatus.value === DashboardSettingsStatus.Loading)
  const visibleLibrarySettings = computed(() =>
    librarySettings.value.filter((librarySetting) => librarySetting.visible),
  )

  // Actions

  async function initialize(): Promise<void> {
    await fetchUserLibrarySettings()
  }

  async function fetchUserLibrarySettings(): Promise<void> {
    userDashboardStatus.value = DashboardSettingsStatus.Loading
    try {
      const response = await UserApi.fetchUserLibrarySettings()
      const userLibrarySettings = (response?.data as JsonAPIResource<UserLibrarySettingsApiResponse>).attributes

      defaultLibrary.value = userLibrarySettings.defaultLibrary
      librarySettings.value = userLibrarySettings.librarySettings
      userDashboardStatus.value = DashboardSettingsStatus.Completed
    } catch (error) {
      void globalSnackbarStore.genericFetchError()
      userDashboardStatus.value = DashboardSettingsStatus.Errored
      captureFetchException(error, { source: 'DashboardSettingsDashboardFetchUserLibrarySettings' })
    }
  }

  async function updateUserLibrarySetting(
    librarySetting: UserLibrarySetting,
    initialDefaultLibrary: UserLibrarySetting,
    initialLibrarySettings: UserLibrarySetting[],
  ): Promise<void> {
    try {
      const response = await UserApi.updateUserLibrarySetting({
        libraryId: librarySetting.libraryId,
        visible: !librarySetting.visible,
      })
      const userLibrarySettings = (response?.data as JsonAPIResource<UserLibrarySettingsApiResponse>).attributes

      defaultLibrary.value = userLibrarySettings.defaultLibrary
      librarySettings.value = userLibrarySettings.librarySettings
    } catch (error) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating library setting.'),
        notificationType: SnackbarNotificationType.error,
      })
      defaultLibrary.value = initialDefaultLibrary
      librarySettings.value = initialLibrarySettings
      captureFetchException(error, { source: 'DashboardSettingsDashboardUpdateUserLibrarySetting' })
    }
  }

  async function queueUpdateLibrarySetting(librarySetting: UserLibrarySetting): Promise<void> {
    const initialDefaultLibrary = defaultLibrary.value
    const initialLibrarySettings = librarySettings.value
    if (initialDefaultLibrary === undefined || initialLibrarySettings.length === 0) return

    // Update library setting
    librarySettings.value = librarySettings.value.map((setting) => {
      if (setting.libraryId === librarySetting.libraryId) {
        return { ...setting, visible: !librarySetting.visible }
      }
      return setting
    })

    const isDisablingDefaultLibrary = librarySetting.libraryId === initialDefaultLibrary.libraryId
    // Update default library
    if (isDisablingDefaultLibrary) {
      const PERSONAL_LIBRARY_ID = ''
      const visiblePersonalLibrarySetting = visibleLibrarySettings.value.find(
        (setting) => setting.libraryId === PERSONAL_LIBRARY_ID,
      )
      // Set default library to the personal library setting if it's visible
      if (visiblePersonalLibrarySetting !== undefined) {
        defaultLibrary.value = visiblePersonalLibrarySetting
      } else {
        // Set default library to the next viewable library setting
        defaultLibrary.value = visibleLibrarySettings.value[0]
      }
    }
    clearCachedQueries()
    void queue.enqueue(
      'updateUserLibrarySetting',
      async () => await updateUserLibrarySetting(librarySetting, initialDefaultLibrary, initialLibrarySettings),
    )
  }

  async function updateUserDefaultLibrary(
    librarySetting: UserLibrarySetting,
    initialDefaultLibrary: UserLibrarySetting,
  ): Promise<void> {
    try {
      const response = await UserApi.updateUserDefaultLibrary(librarySetting.libraryId)
      const userLibrarySettings = (response?.data as JsonAPIResource<UserLibrarySettingsApiResponse>).attributes

      defaultLibrary.value = userLibrarySettings.defaultLibrary
      librarySettings.value = userLibrarySettings.librarySettings
    } catch (error) {
      void globalSnackbarStore.setSnackbar({
        message: __('Error updating default library.'),
        notificationType: SnackbarNotificationType.error,
      })
      defaultLibrary.value = initialDefaultLibrary
      captureFetchException(error, { source: 'DashboardSettingsDashboardUpdateUserDefaultLibrary' })
    }
  }

  async function queueUpdateUserDefaultLibrary(librarySetting: UserLibrarySetting): Promise<void> {
    if (defaultLibrary.value === undefined) return
    const initialDefaultLibrary = defaultLibrary.value

    defaultLibrary.value = librarySetting

    void queue.enqueue(
      'updateUserDefaultLibrary',
      async () => await updateUserDefaultLibrary(librarySetting, initialDefaultLibrary),
    )
  }

  return {
    // State
    defaultLibrary,
    librarySettings,

    // Getters
    isLoadingUserDashboard,
    visibleLibrarySettings,

    // Actions
    initialize,
    updateUserLibrarySetting,
    queueUpdateLibrarySetting,
    updateUserDefaultLibrary,
    queueUpdateUserDefaultLibrary,
  }
})
